
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {



	.pockets__wrapper {

	    padding-top: 40px;
	    padding-bottom: 50px;
	    position: relative;

	}


	.pockets__wrapper .block-title{

		text-align: left;

	}




	.pockets__header{

		flex-direction: column;

	}



	.pockets__header-title{

		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 130%;

	}


	.pockets__header .left-side,
	.pockets__header .right-side{

		width: 100%;

	}


	.pockets__includes {

	    width: 100%;
	    list-style-type: disc;
	    padding: 0;
	    padding-left: 20px;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-column-gap: 0;
	    grid-row-gap: 0;
	    margin-top: 20px;

	}


	.pockets__includes li{

		font-size: 16px;

	}




	.pockets__header .right-side{

		font-style: normal;
		font-weight: 400;
		font-size: 21px;
		line-height: 130%;
		margin-top: 50px;
		text-align: center;

	}


	.pockets__list {

	    width: 100%;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 10px;
	    list-style-type: none;
	    padding: 0;
	    margin-top: 40px;

	}


	.pockets__list li {

	    width: 100%;
	    height: 260px;
	    border-radius: 26px;
	    padding-top: 30px;
	    padding-bottom: 0;

	}


	.pocket__title{

		font-weight: 400;
		font-size: 21px;
		line-height: 130%;

	}


	.pocket__subtitle{

		font-size: 16px;

	}


	.pocket__body {

	    margin-top: 40px;

	}



	.pocket__footer {

	    width: 100%;
	    height: 77px;
		font-size: 16px;

	}


	.what-is-it__content-wrapper {

	    width: 100%;

	}

	.what-is-it__content, .what-is-it__content *, .what-is-it__content p {

		font-size: 16px !important;
		line-height: 140% !important;

	}



	.what-is-it__content ul li{

		text-align: left !important;

	}










































































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






