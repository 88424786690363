

.faq{

	position: relative;
	background-size: cover !important;

}



.faq__wrapper{

	height: 100%;
	padding-top: 80px;
	padding-bottom: 130px;
	position: relative;

}



.faq__top-title{

	font-style: normal;
	font-weight: 300;
	font-size: 40px;
	line-height: 200%;
	color: var(--dark-color);

}


.faq__list{

	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 90px;
	position: relative;
	margin-top: 55px;

}


.faq__item{

	width: 100%;
	position: relative;
	margin-top: 30px;

}


.faq__item:first-child{

	margin-top: 0;

}



.faq__content-wrapper{

	width: 100%;
	max-height: 0;
	overflow: hidden;
	transition: .3s;

}



.faq__item.active .faq__content-wrapper{

	max-height: 600px;

}






.faq__content{

	width: 100%;
	padding-top: 20px;

}



.faq__title{

	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	border-bottom: 1px solid #E3E3E3;
	padding-bottom: 30px;

}



.faq__title-name{

	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 160%;
	color: var(--dark-color);

}


.faq__item:hover .faq__title-name,
.faq__item.active .faq__title-name{

	color: var(--light-color);

}

.faq__item:hover .faq__title svg *,
.faq__item.active .faq__title svg *{

	fill: var(--light-color);

}


.faq__content *,
.faq__content p{


	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}


.faq__content * strong,
.faq__content strong,
.faq__content p strong{

	font-weight: 500 !important;

}



.faq__item .faq__arrow{

	transition: .3s;

}



.faq__item:hover .faq__arrow,
.faq__item.active .faq__arrow{

	transform: rotate(90deg);

}





































































