


.portfolio__wrapper{

	padding-top: 80px;
	padding-bottom: 80px;
	position: relative;

}


.tags{

	margin-top: 40px;
	display: grid;
	grid-auto-columns: max-content;
	grid-auto-flow: column;
	grid-gap: 10px;

}



.tag{

	position: relative;
	transition: .3s;
	padding: 10px 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border: 2px solid var(--dark-color);
	border-radius: 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	text-align: center;
	color: var(--dark-color);

}


.tag:hover,
.tag.active{

	background: var(--light-color);
	color: #fff;
	border-color: var(--light-color);

}



.portfolio__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	position: relative;
	margin-top: 80px;

}


.portoflio__item{

	width: 100%;
	height: 520px;
	padding: 40px;
	padding-bottom: 60px;
	padding-right: 80px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	transition: .3s;
	background: #F7F7F7;
	border-radius: 50px;	
	margin-bottom: 20px;
	border-radius: 50px;
	border: 2px solid #F7F7F7;
	box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.08);

}



.portoflio__item:last-child{

	margin-bottom: 0;

}



.portoflio__item:hover{

	border-color: var(--light-color);

}


.portfolio__left-side{

	width: 43%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;

}



.portfolio__right-side{

	width: 51%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

}

.portfolio__right-side img{

	width: 100%;
	height: 100%;
	object-fit: contain;

}


.portfolio__item-title *,
.portfolio__item-title p,
.portfolio__item-title{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 36px !important;
	line-height: 130% !important;
	color: var(--dark-color) !important;

}



.portfolio__item-description{

	width: 100%;
	max-height: 116px;
	transition: .3s;
	overflow: hidden;
	margin-top: 20px;

}


.portfolio__item-description *,
.portfolio__item-description p,
.portfolio__item-description{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}


.portoflio__item .def-btn{

	display: flex;
	border-width: 2px;
	align-items: center;
	justify-content: center;
	width: 303px;
	margin-top: auto;	

}


























































