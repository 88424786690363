
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {


	.wwg__wrapper {

	    padding-top: 40px;
	    padding-bottom: 60px;
	    position: relative;

	}


	.wwg__list {

	    width: 100%;
	    margin-left: auto;
	    margin-right: auto;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 10px;
	    margin-top: 40px;

	}


	.wwg__list li{

		width: 91.05%;
		height: 365px;
		padding-bottom: 35px;
		padding-right: 20px;
		padding-left: 25px;
		padding-top: 25px;
		margin-left: auto;
		margin-right: auto;

	}



	.wwg__title{

		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;

	}


	.wwg__description, .wwg__description *, .wwg__description p{

		font-size: 16px !important;

	}



	.seo-page .wwfx__top-title{

		width: 280px !important;

	}

















































































































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






