
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {



	.weu__wrapper{

		padding-top: 0;
		padding-bottom: 270px;

	}



	.weu__img {
		
	    position: absolute;
	    top: auto;
	    right: 0;
	    bottom: 0;

	}


	.weu__img2{

		position: absolute;
		bottom: 20px;
		left: 0;

	}


	.weu__wrapper .block-title{

		text-align: left;

	}


	.weu__description{

		width: 100%;
		margin-top: 20px;

	}



	.weu__description, .weu__description *, .weu__description p{

		font-size: 16px !important;
		line-height: 130% !important;

	}



	.weu{

		min-height: 100px;
		margin-bottom: 60px;

	}














































































































































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






