



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.p404__wrapper {

	    padding-top: 40px;
	    padding-bottom: 60px;
	    position: relative;
	    display: flex;
	    flex-direction: column;
	    align-items: flex-start;

	}



	.p404__title{

		font-size: 24px;
		order: 0;

	}


	.p404__subtitle{

		font-size: 17px;
		margin-top: 20px;
		order:1;

	}


	.p404btn{

		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		line-height: 140%;
		width: 300px;
		height: 68px;
		order: 3;
		margin-left: auto;
		margin-right: auto;

	}


	.p404-bg {

		width: 247px;
		height: 171px;
		object-fit: contain;
	    position: relative;
	    right: 0;
	    bottom: 0;
	    margin-top: 35px;
	    order: 2;

	}





















































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






