



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.header{

		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));		

	}


	.header__menu{

		display: none;

	}


	.quick_contacts button{

		display: none;

	}



	.hamburger{

		margin-left: 20px;

	}



	.quick_contacts a{

		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;

	}
















}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






