



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.faq__wrapper{

		padding-top: 0;
		padding-bottom: 10px;

	}



	.faq__top-title{

		font-style: normal;
		font-weight: 300;
		font-size: 24px;
		line-height: 200%;
		text-align: left;

	}


	.faq__list{

		margin-top: 30px;
	    grid-template-columns: repeat(1,1fr);
	    grid-column-gap: 0;

	}


	.faq__title {

	    padding-bottom: 20px;

	}


	.divider{

		display: none;

	}

	.faq__item .faq__arrow{

		width: 18px;
		height: 10px;

	}


	.faq__title-name{

		font-size: 16px;
		margin-left: 0;

	}



	.faq__item{

		margin-top: 20px;
		padding-bottom: 0;

	}


	.faq__content{

		padding-top: 10px;

	}


	.faq__content *, .faq__content p{

		font-size: 14px !important;

	}



	.faq__column{

		margin-top: 20px;

	}


	.faq__column:first-child{

		margin-top: 0;

	}

	.faq__wrapper .def-btn{

		width: 100%;
		margin-top: 40px;

	}


	.faq__wrapper .def-btn::after {

	    right: auto;
	    top: 0;
	    left: 0;
	    
	}







































































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






