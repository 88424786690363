


.invoice_of_payment__wrapper{

	min-height: calc(100vh - 748px);
	padding-bottom: 60px;

}



.invoice_of_payment__title{

	text-align: center;
	margin-top: 40px;
	font-weight: 700;
	font-size: 70px;

}



.invoice-form{

	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

}




.half-way{

	width: 49.3% !important;
	max-width: 49.3% !important;

}


.w100{

	width: 100% !important;
	max-width: 100% !important;

}


.invoice-form .def-input{

	margin-bottom: 20px;
	border-radius: 4px;

}



.invoice-form .def-input::placeholder{

	font-size: 18px;

}




.invoice-table__wrapper{

	width: 100%;
	margin-bottom: 20px;
	border: 1px solid var(--dark-color);
	padding: 20px;
	border-radius: 4px;

}


.invoice-table__title{

	font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    color: var(--dark-color);
    -webkit-transition: .3s;
    transition: .3s;
    margin-bottom: 20px;

}


table.invoice-table {

	width: 100% !important;
	background: transparent;
	border-collapse: collapse;
	border-width: 1px;
	border-color: #595959;
	border-style: solid;
	color: #000000;

}

table.invoice-table td, table.invoice-table th {

	font-size: 18px;
	border-width: 1px;
	border-color: #595959;
	border-style: solid;
	padding: 15px;
    color: var(--dark-color);
    transition: .3s;

}


table.invoice-table tr{

	border: 1px solid var(--dark-color);
    transition: .3s;
	cursor: pointer;
	position: relative;

}



.invoice-table__controls{

	width: 100%;
	margin-top: 20px;
	display: flex;
	align-items: center;
	border-radius: 4px;

}


.invoice-table__controls .def-btn{

	padding: 15px;
	margin-right: 20px;
	padding-left: 25px;
	padding-right: 25px;
	border-radius: 4px;

}


.invoice-table tr.active,
.invoice-table tr:hover{

	background: var(--light-color);
	color: #fff !important;

}



table tr.active *,
.invoice-table tr:hover *{

	color: #fff !important;

}



.table-input{

	width: 100%;
	height: 100%;
	max-width: 100%;
	min-width: 100%;
	border: none;
	background: none;
	border-radius: 0;
	position: relative;

}

.invoice-table tr td{

	position: relative;

}

.invoice-table tr td:nth-child(1){

	width: 5%;
	text-align: center;
}

.invoice-table tr td:nth-child(3){

	width: 8%;
	text-align: center;
}
.invoice-table tr td:nth-child(4){

	width: 10%;
	text-align: center;
}
.invoice-table tr td:nth-child(5){

	width: 15%;
	text-align: center;
}

.invoice-table tr td:nth-child(6){

	width: 20%;
	text-align: center;
}

.invoice-table tr td:nth-child(2){

	width: 42%;

}



textarea.table-input{

	resize: vertical;

}


.deleteThisService{

	width: 100%;
	height: 100%;
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: red;
	position: absolute;
	left: -60px;
	top: 50%;
	font-size: 20px;
	color: #fff;
	cursor: pointer;
	transition: opacity .3s;
	opacity: 0;
	z-index: 2;

}

.invoice-table tr:hover .deleteThisService{

	display: flex;
	transform: translateY(-50%);
	opacity: 1;

}


.invoice-controls{

	width: 100%;
	display: flex;
	align-items: center;

}

.invoice-controls .def-btn{

	border-radius: 4px;
	margin-right: 20px;
	padding: 15px;
	padding-left: 25px;
	padding-right: 25px;
	height: 55px;

}


.ready-invoice{

	opacity: 0;
	transition: .3s;

}


.ready-invoice.active{

	opacity: 1;

}



#invoice-modal__window {
	
	width: 660px;
    height: auto;
    border: 2px solid #ce9967;
    border-radius: 30px;

}


#invoice-modal__window .new-modal__form{

    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none;
    padding-top: 50px!important;
    padding-bottom: 50px!important;
    padding-left: 108px!important;
    padding-right: 108px!important;

}


#invoice-modal__window .new-modal__form .def-btn{

	padding: 15px;
	height: 55px;

}





#invoice-modal__window .new-modal__input-wrapper{

	grid-template-columns: repeat(1,1fr);

}






.invoice-table.invoice-table__list tr.active, .invoice-table.invoice-table__list tr:hover{

	background: none !important;
	color: var(--dark-color) !important;

}



.invoice-table.invoice-table__list tr td:nth-child(1) {


    width: 25%;
    text-align: center;

}
.invoice-table.invoice-table__list tr td:nth-child(2) {


    width: 15%;
    text-align: center;

}

.invoice-table.invoice-table__list tr td:nth-child(3) {


    width: 5%;
    text-align: center;

}




.invoice-table.invoice-table__list .def-btn{

	padding: 7px 35px;
    height: 55px;
    border-radius: 4px;
    margin-left: 15px;

}



.invoice-table.invoice-table__list tr{

	cursor: default;

}

.invoice-table.invoice-table__list tr:hover *{

	color: var(--dark-color) !important;

}




.invoice-table.invoice-table__list tr .def-btn:hover{

	color: #fff !important;

}




























































