



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.contacts__wrapper {

		padding-top: 40px!important;
		padding-bottom: 60px!important;

	}


	.contacts__wrapper .block-title{

		text-align: left;

	}


	.contacts__splitter{

		flex-direction: column;
		align-items: flex-start;

	}



	.contacts__splitter .left-side {

	    width: 100%;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-row-gap: 30px;
	    grid-column-gap: 0;

	}



	.contacts__splitter .right-side{

		width: 100%;
		height: 348px;
		border-radius: 26px;
		margin-top: 40px;

	}



	.column__title{

		font-size: 17px;

	}



	.column__content a, .column__content a span, .column__content span{

		font-size: 17px;

	}

	.column__content a svg{

		width: 23px;
		height: 23px;

	}







































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






