
.whatwedo{

	position: relative;

}


.particles{

	position: absolute;

}





.whatwedo__wrapper{

	position: relative;
	padding-top: 80px;
	padding-bottom: 155px;

}




.whatwedo__title,
.whatwedo__title p,
.whatwedo__title *{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 40px !important;
	line-height: 130% !important;
	text-align: center !important;
	color: var(--dark-color) !important;

}


.whatwedo__title strong,
.whatwedo__title p strong,
.whatwedo__title * strong{

	font-weight: 500 !important;

}



.whatwedo__subtitle{

	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: var(--light-color);
	margin-top: 40px;

}



.whatwedo__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	margin-top: 80px;

}



.whatwedo__list li{

	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #22273033;
	padding-bottom: 65px;
	margin-top: 60px;

}


.whatwedo__list li:first-child{

	margin-top: 0;

}


.whatwedo__right-side{

	margin-left: 200px;

}




.whatwedo__left-side,
.whatwedo__left-side p,
.whatwedo__left-side *{


	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 800 !important;
	font-size: 24px !important;
	line-height: 140% !important;
	text-align: center !important;
	color: var(--dark-color) !important;

}



.whatwedo__left-side strong,
.whatwedo__left-side p strong,
.whatwedo__left-side * strong{

	color: var(--light-color) !important;

}



.whatwedo__right-side,
.whatwedo__right-side p,
.whatwedo__right-side *{


	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 140% !important;
	color: var(--dark-color) !important;
	text-last: left !important;

}



.whatwedo__right-side strong,
.whatwedo__right-side p strong,
.whatwedo__right-side * strong{

	font-weight: 800 !important;

}








.whatwedo__left-side{

	width: 425px;

}



.whatwedo__right-side{

	width: 690px;

}


.whatwedo__right-side{

	display: flex;
	align-items: center;

}


.whatwedo__right-side::before{

	width: 6px;
	height: 6px;
	display: block;
	content: '';
	background: var(--light-color);
	border-radius: 50%;
	margin-right: 16px;

}



.particle__1{

	top: 190px;
	left: 0;

}


.particle__2{

	bottom: 10px;
	right: 40px;

}

















































































































