



.p404__wrapper{

	padding-top: 180px;
	padding-bottom: 410px;
	position: relative;

}



.p404__title{

	font-style: normal;
	font-weight: 400;
	font-size: 50px;
	line-height: 130%;
	color: var(--dark-color);

}


.p404__subtitle{

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 160%;
	color: var(--dark-color);
	margin-top: 40px;

}



.p404-bg{

	position: absolute;
	right: -40px;
	bottom: 120px;

}



.p404btn{

	width: 340px;
	height: 77px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 60px;

}

















































































































































