



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.work-steps__wrapper {

	    padding-top: 30px;
	    padding-bottom: 50px;

	}


	.context-page .work-steps__wrapper .block-title {

	    text-align: left;

	}


	.work-steps__list {

	    margin-top: 40px;

	}



	.work-steps__list>li{

		flex-wrap: wrap;
		padding-bottom: 15px;
		margin-bottom: 20px;

	}




	.work-steps__list .work-step__number {

	    width: auto;
	    margin-right: 10px;
		font-style: normal;
		font-weight: 500;
		font-size: 60px;
		line-height: 100%;

	}


	.work-step__title{

		width: auto;
		text-align: left;
		font-style: normal;
		font-weight: 800;
		font-size: 18px;
		line-height: 140%;

	}


	.work-step__chars {

	    width: 100%;
	    margin-left: 0;
	    list-style-type: none;
	    padding: 0;

	}



	.work-step__chars li {

	    display: flex;
	    align-items: center;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 140%;
	    margin-bottom: 5px;

	}


	.work-step__chars li::before {

	    min-width: 3px;
	    max-width: 3px;
	    min-height: 3px;
	    max-height: 3px;
	    display: block;
	    content: "";
	    background: var(--dark-color);
	    border-radius: 100%;
	    margin-right: 10px;

	}


	.work-steps__list > li:last-child {

		border-bottom: 1px solid rgba(34,39,48,.2);

	}




	.work-steps__wrapper .def-btn{

		width: 270px;
		height: 68px;
		margin-top: 30px;

	}


	.work-steps__wrapper .def-btn span{

		font-style: normal;
		font-weight: 800;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		text-transform: uppercase;

	}




























































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






