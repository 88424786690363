



.steps__wrapper{

	padding-top: 120px;
	padding-bottom: 100px;
	position: relative;

}



.steps__header{

	width: 100%;
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

}



.steps__top-title *,
.steps__top-title p,
.steps__top-title{

	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 40px !important;
	line-height: 130% !important;
	color: var(--dark-color) !important;

}


.steps__top-title span{

	font-weight: 500 !important;
	color: var(--light-color) !important;


}




.steps__top-subtitle *,
.steps__top-subtitlep,
.steps__top-subtitle{

	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}



.steps__right-side{

	display: flex;
	align-items: flex-start;
	position: relative;

}



.steps__count{

	margin-right: 60px;

}


.steps__count:last-child{

	margin-right: 0;

}


.steps__count-number{

	font-style: normal;
	font-weight: 300;
	font-size: 40px;
	line-height: 48px;
	color: var(--dark-color);

}


.steps__count-number span{

	font-style: normal;
	font-weight: 300;
	font-size: 80px;
	line-height: 96px;
	color: var(--dark-color);

}



.steps__count:last-child .steps__count-number,
.steps__count:last-child .steps__count-number span{

	color: var(--light-color);

}



.steps__count-title{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	color: #939393;
	text-align: right;

}



.steps__pagination{

	width: 100%;
	height: 130px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	margin-top: 60px;

}


.steps__pagination::after{

	width: 100%;
	height: 2px;
	display: block;
	content: '';
	background: var(--light-color);
	position: absolute;
	top: 49px;
	left: 0;

}



.step__pagination-number{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	text-align: center;
	color: #939393;
	transition: .3s;

}

.steps__pagination-bullet{

	position: relative;
	cursor: pointer;
	z-index: 9;

}

.steps__pagination-bullet.active .step__pagination-number{

	font-weight: 500;
	color: var(--dark-color);

}



.step__pagination-title{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	text-align: center;
	color: #939393;
	margin-top: 42px;
	transition: .3s;

}



.steps__pagination-bullet.active .step__pagination-title{

	font-weight: 800;
	color: var(--dark-color);

}


/* ############################################################## */


.steps__pagination-bullet::after{

	width: 30px;
	height: 30px;
	opacity: 0;
	display: block;
	content: '';
	position: absolute;
	top: 33px;
	left: 50%;
	border: 2px solid transparent;
	border-radius: 50%;	
	transform: translateX(-50%);
	transition: opacity .3s;
	transition: .3s;

}


.steps__pagination-bullet::before{

	width: 16px;
	height: 16px;
	opacity: 0;
	display: block;
	content: '';
	position: absolute;
	top: 42px;
	left: 50%;
	transition: opacity .3s;
	transform: translateX(-50%);
	border-radius: 50%;
	transition: .3s;

}

.steps__pagination-bullet.active::after{

	border-color: var(--light-color);
	opacity: 1;

}


.steps__pagination-bullet.active::before{

	background: var(--light-color);
	opacity: 1;

}


.steps__pagination-bullet:hover::before{

	background: var(--light-color);
	opacity: 1;

}



/* ############################################################## */


.steps__pagination-bullet:hover .step__pagination-title,
.steps__pagination-bullet:hover .step__pagination-number{

	color: var(--light-color);

}




.steps__slider{

	width: 100%;
	min-height: 372px;
	position: relative;
	overflow: hidden;
	margin-top: 80px;
	border: 2px solid #CE9967;
	box-shadow: 0px 164px 66px rgba(222, 222, 222, 0.01), 0px 92px 55px rgba(222, 222, 222, 0.05), 0px 41px 41px rgba(222, 222, 222, 0.09), 0px 10px 23px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1);
	border-radius: 30px;

}



.steps__slider .swiper-slide{

	width: 100%;
	height: 90%;
	position: relative;
	padding: 40px 110px;
	border-radius: 30px;

}




.step__slide-title{

	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 160%;
	color: var(--dark-color);

}


.step__slide-description{

	width: 100%;
	margin-top: 10px;

}


.step__slide-description *,
.step__slide-description p{

	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}

.step__slide-description * strong,
.step__slide-description p strong{

	font-weight: 500 !important;

}


.steps__navigation{

	display: flex;
	align-items: flex-end;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 9;

}


.swiper-button-next:after, .swiper-button-prev:after{

	display: none;

}

.steps__slider .swiper-button-next, .steps__slider .swiper-button-prev {

    position: relative;
    top: 0;
    margin-top: 0;
    left: 0;
    right: 0;

}


.steps__slider .swiper-button-prev{

	box-sizing: border-box;
	width: 100px;
	height: 70px;
	background: #F7F7F7;
	border: 2px solid #CE9967;
	border-radius: 0px 0px 30px 0px;
	transform: rotate(-180deg);
	border-top: 0;
	transition: .3s;

}



.steps__slider .swiper-button-next{

	width: 100px;
	height: 70px;
	border-radius: 0px 0px 30px 0px;
	border-top: 0;
	border: 2px solid #CE9967;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
	transition: .3s;

}






.steps__slider .swiper-button-prev svg *,
.steps__slider .swiper-button-next svg *{

	fill: var(--light-color);

}



.steps__slider .swiper-button-prev svg *,
.steps__slider .swiper-button-next svg *{

	transition: .3s;

}



.steps__slider .swiper-button-prev:hover svg *,
.steps__slider .swiper-button-next:hover svg *{

	fill: #fff;

}

.steps__slider .swiper-button-prev:hover,
.steps__slider .swiper-button-next:hover{

	background: var(--light-color);

}



.steps__slider .swiper-button-prev svg{

	transform: scale(-1);

}



.steps__outro{

	width: 100%;
	position: relative;
	margin-top: 120px;

}



.steps__outro-title *,
.steps__outro-title p{

	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 40px !important;
	line-height: 130% !important;
	text-align: center !important;
	color: var(--dark-color) !important;

}


.steps__outro-title * strong,
.steps__outro-title p strong{

	font-weight: 500 !important;
	color: var(--light-color) !important;

}


.steps__outro-subtitle{

	width: 100%;
	margin-top: 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	text-align: center !important;
	color: var(--dark-color);

}



.figa{

	width: 442px;
	height: 446px;
	position: absolute;
	bottom: 10px;
	right: -270px;

}










































