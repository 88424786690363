
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {

	.single-news__banner .container{

		padding-left: 0;
		padding-right: 0;

	}



	.single-news__banner {

	    width: 100%;
	    height: 478px;
	    padding-bottom: 40px;
	    z-index: 3;

	}


	.single-news__title, .single-news__title *, .single-news__title p{

		font-size: 28px !important;

	}


	.single-news__banner-img__wrapper {

	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 1;

	}

	.single-news__banner-img__wrapper::after{

		background: linear-gradient(90deg, #373634 0%, rgba(55, 54, 52, 0) 289.33%);

	}


	.single-news__info{

		flex-direction: column-reverse;
		align-items: flex-start;
		margin-top: 20px;

	}


	.read-time{

		margin-left: 0;

	}


	.read-time span{

		font-size: 17px;

	}



	.read-time svg{

		width: 14px;
		height: 11px;

	}



	.single-news__info .tags{

		margin-top: 20px;

	}


	.single-news__info .tags .tag {

	    border-color: #fff;
	    color: #fff;

	}


	.news__container {

	    width: 100%;
	    flex-direction: column;
	    align-items: flex-start;
	    padding-left: 20px;
	    padding-right: 20px;
	    padding-bottom: 80px;
	    padding-top: 0;

	}




	.news-content__sidebar{

		width: 100%;

	}


	.article__content{

		background: #F7F7F7;
		border-radius: 44px;
		padding-bottom: 50px;

	}


	.article__title {

	    font-size: 23px;

	}


	.article__content-list{

		margin-top: 18px;

	}



	.article__content-list li {

	    font-size: 14px;

	}



	.news-content__description {

	    width: 100%;
	    margin-top: 20px;
	}


	.sidebar__form{

		padding-left: 30px;
		padding-top: 45px;
		padding-bottom: 50px;
		padding-right: 30px;
		background: #F7F7F7;
		border: 1px solid var(--light-color);
		border-radius: 26px;

	}


	.sidebar__form-title{

		font-size: 24px;
		line-height: 130%;

	}



	.sidebar__form .def-input{

		width: 100%;
		height: 69px;
		border-radius: 17px;

	}


	.news-block-content, .news-block-content *, .news-block-content p{

		font-size: 16px !important;

	}

	.news-block-content .news-block__title,
	.news-block-content .news-block__title *,
	.news-block-content .news-block__title p{

		font-family: var(--font);
		font-style: normal !important;
		font-weight: 300 !important;
		font-size: 24px !important;
		line-height: 200% !important;
		color: var(--dark-color) !important;

	}



	.news-block-content * ul li::before, .news-block-content ul li::before {

	    min-width: 9px;
	    max-width: 9px;
	    min-height: 9px;
	    max-height: 9px;

	}


	.article-rating__title{

		font-size: 24px;
		line-height: 200%;

	}


	.blog__wrapper {

	    position: relative;
	    padding-top: 0;
	    padding-bottom: 50px;

	}



	.blog__list-slider .blog__item{

		min-width: 335px;

	}
















































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






