


.connect{

	position: relative;

}


.connect__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.connect__wrapper .block-title{

	text-align: center;

}




.connect__list{

	width: 100%;
	position: relative;
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 30px;
	grid-column-gap: 85px;
	margin-top: 60px;

}


.connect__title{

	display: flex;
	align-items: center;

}


.connect__title .text{

	font-style: normal;
	font-weight: 800;
	font-size: 20px;
	line-height: 28px;
	color: var(--dark-color);
	margin-left: 5px;

}


.connect__title .number{

	font-weight: 800;
	font-size: 40px;
	line-height: 56px;
	color: var(--light-color);	

}


.connect__content{

	width: 100%;
	list-style-type: none;
	padding: 0;

}


.connect__content li{

	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
	color: var(--dark-color);
	margin-bottom: 10px;

}


.connect__content li::before{

	min-width: 6px;
	max-width: 6px;
	min-height: 6px;
	max-height: 6px;
	display: block;
	content: '';
	background: var(--light-color);
	border-radius: 100%;
	margin-right: 10px;

}


.connect__content li:last-child{

	margin-bottom: 0;

}



.connect__wrapper::after{

	width: 376px;
	height: 208px;
	display: block;
	content: '';
	position: absolute;
	bottom: 113px;
    right: -120px;
	background: url('../img/site-images/connect/lad.png')no-repeat;

}




.connect::after{

	width: 385px;
    height: 586px;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -111px;
    background: url(../img/site-images/connect/desk.png) no-repeat;
    transform: scaleX(-1);

}



.connect__title svg{

	display: none;
	transition: .3s;

}


.connect__content-wrapper{

	width: 100%;

}


.connect__content-itself{

	width: 100%;

}




























































































































































































































