



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.hero.contexthero .hero__wrapper{

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 50px;

	}

	.hero.contexthero .hero__img{

		width: 100%;
		height: 275px;
	    position: relative !important;
	    top: 0 !important;
	    right: 0 !important;
	    z-index: 1;

	}


	.hero.contexthero .hero__title{

		order: 0;

	}



	.hero.contexthero .hero__img{

		order: 1;

	}


	.hero.contexthero .hero__form{

		order: 2;
		padding-top: 35px;

	}






















































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






