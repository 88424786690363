



.simple-form{

	position: relative;
	margin-top: 60px;
	padding-bottom: 60px;

}



.simple-form__wrapper{

	box-sizing: border-box;
	height: auto;
	border: 2px solid var(--light-color);
	border-radius: 30px;
	position: relative;
	padding: 50px 108px;
	padding-bottom: 70px;

}



.simple-form__form{

	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;

}


.simple-form__form input{

	max-width: 340px;

}






































































































































































