



.qualy__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.qualy__wrapper .block-title{

	text-align: center;

}



.qualy__list{

	width: 100%;
	max-height: 490px;
	transition: .3s;
	overflow: hidden;
	list-style-type: none;
	padding: 0;
	margin-top: 80px;

}



.qualy__list li{

	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;

}



.qualy__list li:not(.active).hiddable .qualy__text{

	background: linear-gradient(180deg, #595959 0%, rgba(89, 89, 89, 0) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

}




.qualy__list li:last-child{

	margin-bottom: 0;

}




.qualy__list li .left-side{

	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

}

.qualy__list li .right-side{

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;

}



.qualy__number{

	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 100%;
	background: linear-gradient(180deg, #CE9967 0%, rgba(255, 189, 128, 0.9) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

}


.qualy__text{

	width: 100%;
	margin-top: 10px;
	text-align: center !important;

}


.qualy__text *,
.qualy__text,
.qualy__text p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 24px !important;
	line-height: 140% !important;
	color: var(--dark-color);

}


.qualy__text * strong,
.qualy__text strong,
.qualy__text p strong{

	font-weight: 500 !important;

}


.view-img{

	display: flex;
	align-items: center;
	position: relative;

}


.view-img span{

	position: relative;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	color: var(--light-color);

}


.view-img span::after{

	width: 100%;
	height: 1px;
	display: block;
	content: '';
	background: var(--light-color);
	position: absolute;
	bottom: 0px;
	left: 0;

}



.view-img svg{

	margin-left: 13px;
	transition: .3s;

}


.view-img:hover svg{

	transform: rotate(90deg);

}
























































































