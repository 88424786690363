



.site-types__wrapper{

	position: relative;
	padding-top: 80px;
	padding-bottom: 120px;

}



.site-types__top-title{

	font-style: normal;
	font-weight: 300;
	font-size: 40px;
	line-height: 130%;
	text-align: center;
	color: var(--dark-color);
	position: relative;

}


.site-types__top-title span{

	position: relative;

}


.site-types__top-title span::after{

	width: 452px;
	height: 2px;
	display: block;
	content: '';
	position: absolute;
	left: 0;
	bottom: -10px;
	background: var(--light-color);

}




.site-types__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	margin-top: 105px;

}



.big-container{

	width: 1720px;
	margin-left: auto;
	margin-right: auto;

}



.site-type__item{

	width: 100%;
	height: auto;
	border: 2px solid var(--light-color);
	border-radius: 50px;
	position: relative;
	transition: .3s;

}

.site-type__img{

	width: 100%;
	height: 343px;
	border-radius: 50px 50px 0px 0px;    
	transition: .3s;

}



.site-type__img img{

	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50px;
	filter: grayscale(100);
	border-radius: 50px 50px 0px 0px;    

}


.site-type__item:hover img{

	filter: grayscale(0);

}


.site-type__content{

	width: 100%;
	height: calc(100% - 343px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 40px 30px;
	padding-bottom: 50px;
	position: relative;

}



.site-type__title{

	font-style: normal;
	font-weight: 800;
	font-size: 30px;
	line-height: 160%;
	color: var(--dark-color);

}



.site-type__subtitle{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	color: var(--dark-color);

}



.site-type__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	margin-top: 20px;

}

.site-type__list li{

	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 160%;
	color: var(--dark-color);
	margin-bottom: 10px;

}


.site-type__list li:last-child{

	margin-bottom: 0;

}



.site-type__list li::before{

	display: block;
	content: '';
	width: 11px;
	height: 9px;
	background: url('../img/icons/mark.svg')no-repeat;
	margin-right: 10px;

}


.site-type__item .def-btn{

	margin-top: auto;
	margin-left: auto;
	margin-right: auto;

}



.site-type__header svg{

	display: none;

}



.site-type__content-wrapper{

	width: 100%;
	min-height: 100%;
	max-height: 100%;
	transition: .3s;

}



.site-type-content-self{

	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: 50px;

}






















































































































































































