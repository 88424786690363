



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.portfolio__wrapper{

		padding-top: 40px;
		padding-bottom: 60px;

	}



	.portfolio__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    position: relative;
	    margin-top: 60px;

	}


	.portoflio__item {

	    width: 100%;
	    height: auto;
	    flex-direction: column;
	    padding-top: 35px;
	    padding-left: 30px;
	    padding-bottom: 40px;
	    padding-right: 19px;
		filter: none;
		transition: .3s;
		border: 1px solid var(--light-color);
		border-radius: 20px;
		transform: translateZ(0);
		box-shadow: none;

	}


	.portoflio__item:hover{

		box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.08);
		border: 1px solid var(--light-color) !important;

	}


	.portfolio__item-title, .portfolio__item-title *, .portfolio__item-title p{

		font-size: 20px !important;
		line-height: 130% !important;

	}


	.portfolio__left-side{

		width: 100%;

	}

	.portfolio__item-description{

		max-height: 185px;
		margin-top: 30px;
		margin-bottom: 40px;

	}

	.portfolio__item-description, .portfolio__item-description *, .portfolio__item-description p{

		font-size: 16px !important;

	}


	.portimg{

		width: 100%;
		max-height: 180px;
		object-fit: contain;
		margin-top: 30px;

	}


	.portoflio__item .def-btn{

		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		height: 68px;

	}







































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






