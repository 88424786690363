



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.start-story {

	    margin-bottom: 30px;

	}


	.start-story__text *, .start-story__text p{

		font-size: 21px !important;
		text-align: left !important;

	}


	.story-divider {

	    margin-top: 20px;
	    align-self: flex-start;

	}


	.started__wrapper{

		padding-bottom: 60px;

	}





















































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






