
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {



	.qualy__wrapper {

	    padding-top: 70px;
	    padding-bottom: 50px;
	    position: relative;

	}



	.qualy__list {

	    width: 100%;
	    max-height: 150px;
	    -webkit-transition: .3s;
	    transition: .3s;
	    overflow: hidden;
	    list-style-type: none;
	    padding: 0;
	    margin-top: 40px;

	}


	.qualy__number{

		font-size: 35px;

	}


	.qualy__text, .qualy__text *, .qualy__text p{

		font-size: 18px !important;

	}






















































































































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






