.main-page .hero{

	min-height: 691px;

}


.main-page .advantages__list{

	margin-top: 80px;

}


.main-page .hero__title{

	max-width: 725px;

}

.main-page .hero__title span{

	color: var(--light-color) !important

}

.main-page .hero__title strong span{

	color: var(--dark-color) !important

}







.context-page .m-portfolio__slider-wrapper,
.main-page .m-portfolio__slider-wrapper {

    height: auto;

}




.context-page .m-portfolio__slider,
.main-page .m-portfolio__slider {

	overflow: hidden;
    height: auto;
    padding-bottom: 140px;

}



.m-p__description strong{

	font-weight: 800 !important;

}


.context-page .m-portfolio__slider .swiper-slide{

	overflow: hidden;

}



.context-page .m-portfolio__slider .swiper-slide .left-side{

	width: 620px;

}















































































































