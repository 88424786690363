@media (max-width: 1680px) {


	.main{

		overflow: hidden;

	}









}




@media (max-width:1368px){





}



@media (max-width:1290px){


}

@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.main{

		overflow: hidden;

	}


	.mbvs{

		display: block !important;

	}


	.mbvsflex{

		display: flex !important;

	}


	.container{

		width: 100%;
		padding-left: 20px;
		padding-right: 20px;

	}




	.mhidden{

		display: none;

	}



	.crumbs ul li, .crumbs ul li a{

		font-size: 14px;

	}



	.crumbs{

		padding-top: 20px;

	}


	.block-title{

		font-style: normal;
		font-weight: 300;
		font-size: 24px;
		line-height: 130%;
		text-align: center;

	}



	.simple-form{

		margin-top: 0;

	}



	.simple-form__wrapper {

		width: calc(100% - 40px) !important;
		margin-left: auto;
		margin-right: auto;
	    box-sizing: border-box;
	    min-height: 280px;
	    height: auto;
	    border: 2px solid var(--light-color);
	    border-radius: 30px;
	    position: relative;
	    padding: 50px 30px;
	    padding-top: 45px;
	    padding-bottom: 50px;

	}



	.simple-form__form{

		flex-direction: column;

	}



	.simple-form__form input{

		width: 100%;
		max-width: 100%;
		height: 69px;
		margin-bottom: 10px;
		padding-left: 30px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;

	}



	.simple-form__form .def-btn{

		width: 100%;
		height: 69px;
		margin-top: 20px;
		padding: 0;
		font-size: 16px;

	}


	.hidden-menu{

		top: 71px;
		overflow-y: scroll;

	}


	.hidden-menu__wrapper{

		flex-direction: column;
		padding-top: 30px;
		margin-bottom: 125px;

	}




	.aside__column{

		width: 100% !important;

	}


	.aside__menu {

	    column-count: 1;

	}


	.new-modal__window{

		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: none !important;
		width: calc(100% - 40px);
		height: auto;
		max-height: 430px;
		overflow-y: scroll;
		border-radius: 30px;
		padding: 0 !important;

	}

	.new-modal__window-wrapper{

		border: 2px solid #CE9967;
		padding-top: 40px !important;
		padding-bottom: 50px !important;
		padding-left: 30px !important;
		padding-right: 30px !important;
		position: relative;

	}


	.new-modal__form{

		width: 100%;
		padding: 0;
		border: none;
		padding: 0 !important;

	}



	.new-modal__input-wrapper{

		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;

	}



	.new-modal__input-wrapper .def-btn{

		margin-top: 20px;

	}



	.new-modal__window .close-btn{

		width: 10px;
		height: 10px;
		top: 20px;
		right: 20px;

	}





	.new-modal__window .close-btn svg{

		width: 100%;
		height: 100%;

	}


	.new-modal__input-wrapper input{

		width: 100%;
		padding-left: 30px;
		height: 70px;

	}



	.new-modal__input-wrapper .def-btn{

		width: 100%;
		padding: 0;
		height: 70px;

	}



	.pockets__wrapper .right-side{

		text-align: center;
		margin-top: 50px;

	}


	.what-is-it span{

		font-size: 21px;
		color: #9A9FA8;
	    text-decoration-color: #9A9FA8;

	}





	.logo-page .work-steps__wrapper{

		padding-bottom: 0;

	}



	.review__description:not(.active)::after{

	    display: block;
	    content: "...";
	    width: 30px;
	    background: #F5F5F5;
	    z-index: 9;
	    font-size: 16px!important;
	    position: absolute;
	    bottom: 3px;
	    padding-left: 5px;
	    right: 0;

	}


	.story-divider {

	    margin-top: 30px;
	    align-self: center;
	    
	}


	.main {

		margin-top: 71px;

	}

























































































}

@media (max-width: 480px) {






}





@media (max-width: 330px) {











}