


.whywe__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.whywe__wrapper .block-title{

	text-align: center;

}



.whywe__wrapper .whywe__top-title{

	position: relative;
	margin-top: 30px;
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: var(--dark-color);

}



.whywe__block{

	width: 100%;
	position: relative;
	border-radius: 20px; 

}



.whywe__block-title{

	max-width: 100%;
	min-width: 740px;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: var(--dark-color);
	z-index: 9;
	background: #F7F7F7;
	margin-top: 50px;

}



.whywe__block-title strong{

	font-weight: 500;
	color: var(--light-color);

}



.whywe__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	columns: 2;
	column-gap: 200px;
	margin-top: 60px;

}


.whywe__list li{

	display: flex;
	align-items: center;
	margin-bottom: 35px;

}

.whywe__list li .text,
.whywe__list li  .text *,
.whywe__list li  .text p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 24px !important;
	line-height: 130% !important;
	color: var(--dark-color);

}


.whywe__list li  .text * strong,
.whywe__list li  .text strong,
.whywe__list li  .text p strong{

	font-weight: 500 !important;

}



.whywe__list li .number{

	display: block;
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 100%;
	background: linear-gradient(180deg, #CE9967 0%, rgba(255, 189, 128, 0.9) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	margin-right: 30px;

}

























































