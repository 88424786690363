



@media (max-width: 1680px) {

    
	.wwfx__chess-small {

	    top: 101px;
	    left: -130px;

	}



	.wwfx__chess-desk {

	    top: 0;
	    right: -155px;

	}


	.wwfx__chess {

	    left: -147px;
	    bottom: 0;

	}



	.context-page .site-type__img {

	    width: 100%;
	    height: 250px;

	}

	.site-type__content {

	    width: 100%;
	    height: calc(100% - 250px);

	}



	.site-type__img{

		height: 250px;

	}


	.site-types__list{

		grid-template-columns: repeat(3,1fr);

	}


	.connect::after{

		left: -258px;

	}



	.seo-page .wwfx__chess-small {

	    top: 75px;
	    left: -136px;

	}

	.seo-page .wwfx__chess-desk {

	    top: 0;
	    right: -84px;
	    
	}









    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.wwfx__wrapper {

	    padding-top: 20px;
	    padding-bottom: 50px;
	    position: relative;

	}


	.positioned-abs{

		display: none;

	}


	.wwfx__body {

	    width: 100%;
	    margin-top: 30px;
	    position: relative;
	    border: 1px solid var(--light-color);
	    border-radius: 17px;
	    padding: 30px;
	    padding-right: 25px;
	}




	.wwfx__top-title {

	    font-size: 18px;
	    line-height: 130%;
	    position: absolute;
	    top: -17px;
	    left: 50%;
	    padding-left: 10px;
	    padding-right: 10px;
		width: 280px !important;
	    text-align: center;	    

	}


	.wwfx__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-row-gap: 20px;
	    grid-column-gap: 0;
	    margin-top: 20px;

	}


	.wwfx__list li{

		padding-left: 20px;

	}


	.wwfx__list li, .wwfx__list li *, .wwfx__list li p {

	    font-size: 16px!important;
	    line-height: 160%!important;

	}



	.wwfx__list li::before{

		left: 0;

	}




	.seo-page .seowwfx .wwfx__list {

	    grid-column-gap: 0;
	    padding-left: 0;

	}



	.seo-page .faq__wrapper{

		padding-bottom: 60px;

	}



























































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






