



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.steps__wrapper{

		padding-top: 40px;
		padding-bottom: 40px;

	}



	.steps__pagination{

		display: none;

	}



	.steps__header{

		flex-direction: column;
		align-items: flex-start;

	}


	.steps__left-side{

		width: 100%;

	}


	.steps__top-title, .steps__top-title *, .steps__top-title p{

		font-size: 24px !important;
		line-height: 130% !important;

	}


	.steps__top-subtitle{

		margin-top: 10px;

	}



	.steps__top-subtitle, .steps__top-subtitle *, .steps__top-subtitlep{

		font-size: 16px !important;
		line-height: 160% !important;

	}


	.steps__right-side{

		width: 100%;
		margin-top: 25px;

	}



	.steps__count-number{

		font-size: 18px;
		font-weight: 300;
		line-height: 22px;
		text-align: left;		

	}

	.steps__count-number span{

		font-style: normal;
		font-weight: 300;
		font-size: 30px;
		line-height: 120%;		

	}


	.steps__count-title{

		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;

	}



	.steps__count{

		margin-right: 80px;

	}


	.steps__slider{

		min-height: 0;
		padding: 0;
		margin-top: 30px;

	}


	.steps__slider .swiper-slide {

	    width: 100%;
	    height: 100%;
	    position: relative;
	    padding: 30px;
	    border-radius: 30px;
		box-shadow: 0px 145.806px 58.6781px rgba(222, 222, 222, 0.01), 0px 81.7938px 48.8984px rgba(222, 222, 222, 0.05), 0px 36.4516px 36.4516px rgba(222, 222, 222, 0.09), 0px 8.89062px 20.4484px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1);
		border-radius: 26px;
		padding-bottom: 120px;

	}


	.step__slide-title,
	.step__slide-title p,
	.step__slide-title *{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 800 !important;
		font-size: 18px !important;
		line-height: 140% !important;

	}



	.step__slide-description *, .step__slide-description p{

		font-size: 16px !important;
		line-height: 160% !important;

	}



	.steps__slider .swiper-button-prev,
	.steps__slider .swiper-button-next{

		height: 60px;

	}


	.steps__outro{

		margin-top: 50px;

	}



	.steps__outro-title *, .steps__outro-title p{

		font-size: 24px !important;
		line-height: 130% !important;
		color: #CE9967 !important;

	}


	.steps__outro-title * strong, .steps__outro-title p strong{

		font-weight: 500 !important;
		font-size: 24px !important;
		line-height: 130% !important;
		color: #595959 !important;

	}


	.steps__outro-subtitle{

		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 130%;

	}




	.figa{

		width: 121px;
		height: 143px;
		bottom: -80px;
	    right: -20px;		
		object-fit: contain;

	}





































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






