

.seoblock__wrapper{

	position: relative;
	padding-top: 80px;
	padding-bottom: 160px;

}



.seoblock__text{

	width: 100%;
	max-height: 165px;
	overflow: hidden;
	transition: .3s;

}


.seoblock__text *,
.seoblock__text p{

	font-family: var(--font) !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	letter-spacing: 0.02em !important;
	color: var(--dark-color) !important;
	text-align: justify !important;


}
.text-page .seoblock__text *,
.text-page .seoblock__text p{

	text-align: left !important;


}





.seoblock__text strong,
.seoblock__text * strong{

	font-weight: 500 !important;

}

.news-content .news-block-content h1,
.seoblock__text h1{

	font-weight: 700 !important;
	font-size: 32px !important;
	line-height: 35px !important;
	text-align: center !important;

}

.news-content .news-block-content h2,
.seoblock__text h2{

	font-weight: 700 !important;
	font-size: 26px !important;
	line-height: 35px !important;
	text-align: center !important;

}

.news-content .news-block-content h3,
.seoblock__text h3{

	font-weight: 700 !important;
	font-size: 22px !important;
	line-height: 35px !important;
	text-align: center !important;

}

.news-content .news-block-content * strong,
.news-content .news-block-content strong *,
.news-content .news-block-content strong{

	font-weight: 700 !important;

}

.seo-btn{

	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	transition: .3s;

}

.seo-btn span{

	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 160%;
	text-align: center;
	letter-spacing: 0.02em;
	color: var(--light-color);

}

.seo-btn svg{

	margin-left: 20px;

}


.seo-btn svg,
.seo-btn svg *{

	transition: .3s;

}


.seo-btn.active svg,
.seo-btn:hover svg{

	transform: rotate(90deg);

}
























































































































