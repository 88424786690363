




@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.footer__wrapper{

		padding-top: 30px;
		padding-bottom: 60px;
		flex-direction: column;

	}

	.footer-column{

		width: 100%;

	}


	.footer-logo{

		display: flex;
		align-items: center;
		justify-content: center;

	}



	.columns__connector{

		width: 100%;
		margin-top: 40px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

	}


	.columns__connector .footer-column{

		margin-left: 0;

	}

	.columns__connector .footer-column.third-column{

		width: 55%;
		padding-top: 0;

	}

	.footer-column.second-column{

		width: 95px;

	}

	.footer-column__title{

		font-size: 16px;

	}


	.footer__list li, .footer__list li a{

		font-weight: 400;
		font-size: 16px;
		line-height: 180%;

	}



	.footer-column.full-last{

		padding-top: 30px;

	}



	.footer-column.full-last .footer-phones{

		margin-top: 0;

	}



	.footer-emails a .link-content, .footer-phones a .link-content{

		font-size: 17px;

	}


	.footer-emails a .footer-contacts__icon, .footer-phones a .footer-contacts__icon{

		width: 22px;

	}


	.footer-emails a .footer-contacts__icon{

		width: 31px;

	}

	.footer-emails a .link-content{

		margin-left: 13px;

	}


	.footer-emails{

		margin-top: 30px;

	}



	.copyrights{

		font-size: 12px;
		line-height: 160%;

	}


	.privacy-policy{

		font-size: 12px;
		line-height: 160%;

	}



































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






