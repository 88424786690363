



.advantages__wrapper{

	padding-top: 30px;
	position: relative;
	padding-bottom: 120px;

}


.advantages__wrapper .block-title{

	text-align: center;

}


.advantages__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 60px;

}


.advantage{

	width: auto;
	max-width: 480px;
	display: flex;
	align-items: center;

}

.advantage__icon{

	width: 80px;
	height: 78px;

}

.advantage__icon svg{

	max-width: 80px;
	max-height: 80px;

}



.advantage__content{

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	margin-left: 20px;
	align-self: center;

}


.advantage__title{

	font-style: normal;
	font-weight: 300;
	font-size: 40px;
	line-height: 100%;
	color: var(--dark-color);

}


.advantage__title + .advantage__description{

	margin-top: 10px;

}

.advantage__description *,
.advantage__description p{

	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}




.advantage__description * span,
.advantage__description p span,
.advantage__description * strong,
.advantage__description p strong{

	font-weight: 500 !important;

}



.main-page .advantages__list .advantage__content{

	align-self: flex-start;

}




























































