



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.about-page .whywe__wrapper{

		padding-top: 40px;
		padding-bottom: 50px;

	}




	.about-page .whywe__top-title{

		font-style: normal;
		font-weight: 300;
		font-size: 21px;
		line-height: 130%;
		text-align: left;

	}


	.whywe__block-title {

	    max-width: 100%;
	    min-width: 100%;
		font-size: 21px;
		text-align: left;
		line-height: 130%;
	    margin-top: 30px;

	}


	.whywe__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    columns: 1;
	    column-gap: 0;
	    margin-top: 30px;

	}



	.whywe__list li{

		margin-bottom: 30px;

	}


	.whywe__list li .number{

		font-size: 35px;
		margin-right: 10px;

	}


	.whywe__list li .text, .whywe__list li .text *, .whywe__list li .text p{

		font-size: 21px !important;

	}










































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






