



@media (max-width: 1680px) {

    
    
	.hero.contexthero .hero__img {

	    position: absolute;
	    top: 0px;
	    right: 0%;
	    z-index: 1;
	    
	}






    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.about-page .hero__title{

		order:0;

	}



	.about-page .hero__wrapper, .about-page .hero{

		padding-bottom: 0 !important;

	}


	.about-page .hero.contexthero .hero__img{

		order:1;

	}

	.about-page .hero__subtitle{

		order:2;

	}


	.about-page .hero.contexthero .hero__img {

	    width: 100%;
	    height: auto;
	    margin-top: 20px;
	    position: relative!important;
	    top: 0!important;
	    right: 0!important;
	    z-index: 1;

	}

	.about-page .hero__subtitle, .about-page .hero__subtitle *, .about-page .hero__subtitle p{

		font-size: 16px !important;
		line-height: 160% !important;

	}

























































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






