*{
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
}
:root{

	--font: 'Mplus1p';
	--dark-color: #595959;
	--light-color: #CE9967;
	--ff: #fff;

}


::selection {

	color: #fff;
	background: #ffac00;
	
}

.tdu{

	text-decoration: underline;

}

address{font-style: normal !important;}


a{text-decoration: none;}

button{

	margin: 0;
	padding: 0;
	cursor: pointer;
	border:none;
	outline: none !important;
	background: none;


}


img{

	object-fit: cover;
	object-position: center;

}


.hoverable{

	position: relative;

}

.hoverable::after {

	content: '';
	display: block;
	width: 0;
	height: 1px;
	position: absolute;
	bottom: 0px;
	left: 0;
	background: var(--light-color);
	transition: width .3s;

}


.hoverable:hover::after {

	width: 100%;

}

.db{display: block;}

.disabled{

	cursor: auto;
	pointer-events: visible;

}



.container {

	width: 1280px;
	margin: auto;

}

.swiper-slide {background: none;}


.main {

	width: 100%;
	max-width: 1920px;
	margin: auto;
	height: auto;
	margin-top: 105px;

}


.hidden {

	display: none;

}


.good{

	border-color: var(--light-color) !important;

}

.fancybox-active {

	height: 100% !important;

}


.fancybox-button.fancybox-close-small{

	display: none;

}


.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
	
	opacity: 1 !important;
	cursor: auto !important;
	pointer-events: visible !important;
	
}




section{

	width: 100%;
	max-width: 1920px;
	margin:auto;

}



.hidden-content{

	display: none;

}



.ds-hidden{

	display: none !important;

}





.hidden-content{

	display: none;

}



.def-btn{

	padding: 26px 110px;
	border-radius: 20px;
	border: 1px solid var(--light-color);
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	color: var(--light-color) !important;
	transition: .3s;

}


.def-btn:hover{

	background: var(--light-color);
	color: #fff !important;

}

.def-btn.active{

	background: var(--light-color);
	color: #fff !important;

}

.def-text-btn{

	font-family: var(--font);
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 160%;
	text-decoration-line: underline;
	text-decoration-color: var(--light-color);
	text-decoration-thickness: 1px;
	color: var(--light-color);

}


.hidden-menu {
    display: none;
}


.hidden-menu {

    position: fixed;
    display: block;
    top: 105px;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 0;
    -webkit-transition: height .3s;
    transition: height .3s;
	background: linear-gradient(180deg, #EAEAEB -162.5%, #F8F8F8 100%);
	box-shadow: -3px 10px 23px rgba(0, 0, 0, 0.03);
    overflow: hidden;

}


.hidden-menu.active {

    height: 691px;

}


.hamburger{

	width: 26px;
	height: 19px;
	display: block;
	transition: .3s;

}



.hamburger:hover,
.hamburger:hover svg *{

	transition: .3s;

}


.hamburger svg{

	transition: .3s;

}


.hamburger:hover svg *,
.hamburger.active svg *{

	fill: var(--light-color) !important;
	

}

.hamburger.active svg{

	transform: rotate(90deg);

}


.block-title{

	font-style: normal;
	font-weight: 300;
	font-size: 40px;
	line-height: 130%;
	color: var(--dark-color);

}





.block-title span{

	font-weight: 500;

}

.block-title strong{

	font-weight: 500;

}

.positioned-abs{

	position: absolute;

}



.custom-pagination{

	width: 100%;
	margin-top: 75px;

}

.custom-pagination .pagination {

    display: flex;
    list-style-type: none;
    padding: 0;
    align-items: center;
    justify-content: center;

}


.custom-pagination .pagination .page-item:first-child {

    margin-left: 0;

}

.custom-pagination .pagination .page-item {

    width: 10px;
    height: 20px;
    margin-right: 20px;

}




.custom-pagination .pagination .page-item .page-link {

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: #30333A;
    transition: .3s;
    position: relative;

}


.custom-pagination .pagination .page-item .page-link::before{

	width: 30px;
	height: 30px;
	display: block;
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

}




.custom-pagination .pagination .page-item.active .page-link {

    font-weight: 500;
	font-size: 14px;
	line-height: 140%;    
    color: var(--light-color);

}




.custom-pagination .pagination .page-item:hover .page-link {

    color: var(--light-color);

}

.custom-pagination .pagination .page-item .page-link svg *{

    transition: .3s;

}


.custom-pagination .pagination .page-item:hover .page-link svg *{

    fill: var(--light-color);

}





.hidden-menu__wrapper{

	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;

}


.aside__column{

	width: 23%;

}


.aside__column:first-child{

	width: 63%;

}

.aside__column-title{

	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	color: #595959;

}


.aside__column-content{

	margin-top: 30px;

}

.aside__menu{

	width: 100%;
	list-style-type: none;
	padding: 0;
    column-count: 2;
    column-gap: 140px;

}

.aside__menu > li{

	margin-bottom: 20px;

}


.aside__menu > li a{

	width: 100%;
	height: 100%;

}
.aside__menu > li.active{

	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

}


.aside__link{

	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 24px;
	color: #595959;
	transition: .3s;

}


.aside__description{

	margin-top: 10px;

}



.aside__description,
.aside__description *,
.aside__description p{

	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: #595959 !important;

}






.aside__menu > li:hover a .aside__link{

	color: #CE9967;

}




.aside__phones{

	display: flex;
	flex-direction: column;
	align-items: flex-start;

}


.aside__phones a{

	display: flex;
	align-items: center;
	margin-bottom: 10px;

}

.aside__phones a:last-child{

	margin-bottom: 0;

}


.aside__phones a span{

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #595959;
	margin-left: 30px;

}




.aside__emails{

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 20px;

}


.aside__emails a{

	display: flex;
	align-items: center;
	margin-bottom: 10px;

}

.aside__emails a:last-child{

	margin-bottom: 0;

}


.aside__emails a span{

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #595959;
	margin-left: 20px;

}




.aside-socials{

	width: 100%;
	margin-top: 40px;

}



.aside-socials ul{

	width: 100%;
	list-style-type: none;
	padding: 0;
	display: flex;
	align-items: center;

}


.aside-socials li{

	margin-right: 30px;

}


.aside-socials li:last-child{

	margin-right: 0;

}

.social-title{

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #595959;
	margin-bottom: 10px;

}



.aside-socials li svg *{

	transition: .3s;

}



.aside-socials li:hover a svg path{

	fill: var(--light-color);

}


.aside-socials li:hover a svg circle{

	stroke: var(--light-color);

}




.aside__column-content a span,
.aside__column-content a{

	transition: .3s;

}






.aside__column-content a:hover span,
.aside__column-content a:hover{

	color: var(--light-color);

}



.aside__phones a:hover span,
.aside__emails a:hover span{

	color: var(--light-color);

}



.oh{

	overflow: hidden !important;

}



/* PORT */



.levitate-portfolio{

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	grid-gap:20px;
	width: 64px;
	height: 132px;
	position: fixed;
	right: 1%;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 4px;
	border: 1px solid #BE956A;
	cursor: pointer;
	z-index: 99;

}


.levitate-portfolio::before{

	width: 17px;
	height: 100%;
	display: block;
	content: '';
	position: absolute;
	top: 0;
	right: 100%;


}


.levitate-portfolio__content-wrapper{

	width: 0;
	overflow: hidden;
	position: absolute;
	right: calc(100% + 17px);
	transition: .3s;

}



.levitate-portfolio:hover .levitate-portfolio__content-wrapper{

	width: 225px;

}


.levitate-portfolio__content{

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
	border: 1px solid #BE956A;	

}


.levitate-portfolio__item{

	width: 100%;
	height: 53px;
	display: flex;
	align-items: center;
	padding-left: 18px;
	padding-right: 18px;
	transition: .3s;

}


.levitate-portfolio__item span{

	margin-left: 12px;

}



.levitate-portfolio__item span{

	color: #BE956A;
	font-family: "M PLUS 1p";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
	transition: .3s;

}

.levitate-portfolio__item:hover{

	background: #BE956A;

}


.levitate-portfolio__item:hover span{

	color: #fff;

}

.levitate-portfolio__item svg *{

	transition: .3s;

}
.levitate-portfolio__item:hover svg *{

	fill: #fff;

}



@media (max-width: 980px) {

	.levitate-portfolio{

		background: #fff;

	}

	.levitate-portfolio__content{

		background: #fff;

	}














}

/* PORT */

.cities{

	display: flex;
	grid-gap: 20px;

}

.cities .def-btn{

	margin-top: 20px;
	padding: 15px 60px;
	font-weight: 400;
	font-size: 15px;

}

































































































































































































