

.suggest{

	position: relative;

}


.suggest__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}

.suggest__decal-1{

	position: absolute;
	top: 58px;
	left: 0;

}



.suggest__wrapper .block-title{

	text-align: center;

}




.what-we-suggest{

	width: 1030px;
	margin-left: auto;
	margin-right: auto;
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 50px;
	grid-column-gap: 40px;
	position: relative;
	margin-top: 80px;

}



.ww-suggest__title{

	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 130%;
	color: var(--dark-color);
	transition: .3s;

}



.ww-suggest__description{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
	color: var(--dark-color);
	margin-top: 10px;
	margin-bottom: 10px;

}



.what-we-suggest .read-more{

	position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
	color: var(--light-color);
	margin-top: 10px;
	text-transform: none;

}



.what-we-suggest .read-more::after{

	display: block;
	content: '';
	width: 100%;
	height: 1px;
	background: var(--light-color);
	position: absolute;
	bottom: -2px;
	left: 0;

}


.what-we-suggest li:hover .ww-suggest__title{

	color: var(--light-color);

}



























































































