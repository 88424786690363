



.work-steps__wrapper{

	padding-top: 80px;
	padding-bottom: 100px;
	position: relative;

}



.work-steps__wrapper .block-title{

	text-align: center;

}



.work-steps__list{

	width: 100%;
	margin-top: 60px;
	list-style-type: none;
	padding: 0;

}


.work-steps__list > li{

	width: 100%;
	display: flex;
	align-items: flex-start;
	margin-bottom: 50px;
	border-bottom: 1px solid #22273033;
	padding-bottom: 50px;

}

.work-steps__list li:last-child{

	margin-bottom: 0;
	border-bottom: 0;

}

.work-step__number{

	font-family: 'Spectral';
	font-style: normal;
	font-weight: 500;
	font-size: 85px;
	line-height: 100%;
	background: linear-gradient(180deg, #CE9967 0%, rgba(255, 189, 128, 0.9) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

}



.work-step__title{

	width: 215px;
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 140%;
	text-align: center;
	color: var(--dark-color);

}




.work-steps__list .work-step__number{

	width: 101px;
	margin-right: 150px;

}



.work-step__chars{

	width: calc(calc(calc(calc(100% - 215px) - 101px) - 150px) - 75px);
	margin-left: 75px;
	list-style-type: none;
	padding: 0;

}


.work-step__chars li{

	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 140%;
	color: var(--dark-color);
	margin-bottom: 5px;

}

.work-step__chars li:last-child{

	margin-bottom: 5px;

}


.work-step__chars li::before{

	width: 3px;
	height: 3px;
	display: block;
	content: '';
	background: var(--dark-color);
	border-radius: 100%;
	margin-right: 10px;

}



.work-steps__wrapper .def-btn{

	width: 303px;
	height: 77px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	padding-top: 26px;
	padding-bottom: 26px;
	margin-top: 80px;
	margin-left: auto;
	margin-right: auto;

}


.work-steps__wrapper .def-btn span{

	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	text-transform: uppercase;
	color: var(--light-color);

}



.work-steps__wrapper .def-btn span{

	transition: .3s;

}

.work-steps__wrapper .def-btn:hover span{

	color: #fff;

}

.work-steps__wrapper .def-btn svg{

	margin-left: 7px;

}

.work-steps__wrapper .def-btn svg *{

	transition: .3s;

}

.work-steps__wrapper .def-btn:hover svg *{

	fill: #fff;

}










































































