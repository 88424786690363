



.rq-checker__wrapper{

	display: flex;
	align-items: flex-start;
	padding-top: 80px;
	padding-bottom: 100px;
	position: relative;

}



.rq-checker__poster{

	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.10);
	border-radius: 10px;	

}


.rq-checker__poster img{

	border-radius: 10px;	

}


.rq-checker__content{

	position: relative;
	padding-top: 25px;
	margin-left: 115px;

}


.rq-checker__title *,
.rq-checker__title p{

	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 40px !important;
	line-height: 130% !important;
	color: var(--dark-color) !important;

}


.rq-checker__title * span,
.rq-checker__title p span{

	font-style: normal !important;
	font-weight: 500 !important;
	color: var(--light-color) !important;

}



.rq-checker__subtitle{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	color: var(--dark-color);
	margin-top: 20px;

}



.checker-btn{

	box-sizing: border-box;
	width: 303px;
	height: 77px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #CE9967;
	border-radius: 20px;
	margin-top: 60px;
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	text-transform: uppercase;
	color: var(--light-color);
	transition: .3s;

}



.checker-btn:hover{

	background: var(--light-color);
	color: #fff;

}



















































































































































