



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {




	.context-page .site-types__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 20px;
	    margin-top: 40px;

	}


	.context-page .site-type__item {

	    padding-bottom: 20px;
	    border-bottom: 1px solid #c7c7c7;
	    border-radius: 0;	
	    height: auto;

	}


	.context-page .site-type__title {

	    font-weight: 400;
	    font-size: 16px;
	    line-height: 160%;
	    text-transform: uppercase;

	}


	.context-page .site-types__wrapper{

		padding-bottom: 0;
		padding-top: 0;

	}


	.context-page .site-type__img{

		width: 100%;
		height: 185px;
		border: 1px solid #CE9967;
		border-radius: 20px;		
		margin-bottom: 20px;

	}




	.context-page .m-p__description{

		margin-top: 0;

	}



	.context-page .site-type__img{

		width: 100%;
		height: 187px;
		display: block;
		margin-top: 20px;
		margin-bottom: 20px;
		border-radius: 20px !important;

	}


	.context-page .site-type__img img{

		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 20px !important;

	}


	.plan:hover, .site-type__item:hover {

	    margin-top: 0 !important;

	}





	.context-page .seowwfx .wwfx__list {

	    grid-column-gap: 0;
	    padding-left: 0;

	}

	.context-page .site-type__item{

		border-bottom: none;
		border-radius: 0;
		position: relative;

	}

	.context-page .site-type__item::after{

		display: block;
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		background: #C7C7C7;
		bottom: 3px;
		left: 0;

	}






































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






