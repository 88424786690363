



.seo-advantages .advantage {

    align-items: flex-start;

}



.seo-advantages .advantages__list{

    grid-template-columns: 38% auto auto;
    
}












.seo-page .advantages__list .advantage__content{

    align-self: flex-start;

}














































































































































