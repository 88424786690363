



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.logo-page .hero__subtitle{

		padding-top: 0;
		margin-top: 0;
		order: 0;
		display: none;

	}


	.logo-page .herologo .hero__img {

		width: 280px;
		height: 240px;
		object-fit: contain;
		margin-top: 0;
	    position: relative;
	    top: 0;
	    right: 0;
	    z-index: 1;
	    order: 2;

	}



	.logo-page .hero__wrapper{

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 50px;

	}

	.logo-page .hero__title{

		order: 1;

	}


	.logo-page .hero__form{

		order: 3;

	}













































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






