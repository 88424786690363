/*normolize defaults styles for correct displaying*/
@import url(partials/fonts.css);
* {
  margin: 0;
  box-sizing: border-box; }

body, html {
  scroll-behavior: smooth;
  -webkit-text-stroke: 0.4px rgba(0, 0, 0, 0.1);
  height: 100%;
  margin: 0;
  font-family: var(--font);
  font-weight: 400;
  background: #F7F7F7;
  font-style: normal !important;
  font-size: 14px;
  background-color: #F7F7F7; }

a {
  text-decoration: none; }

a, button:focus, input {
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

main {
  display: block; }

h1 {
  font-size: 2em; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace,monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b, strong {
  font-weight: bolder; }

code, kbd, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none; }

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: .35em .75em .625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

/*normolize defaults styles for correct displaying*/
* {
  font-style: normal;
  letter-spacing: normal;
  line-height: normal; }

:root {
  --font: 'Mplus1p';
  --dark-color: #595959;
  --light-color: #CE9967;
  --ff: #fff; }

::selection {
  color: #fff;
  background: #ffac00; }

.tdu {
  text-decoration: underline; }

address {
  font-style: normal !important; }

a {
  text-decoration: none; }

button {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none !important;
  background: none; }

img {
  object-fit: cover;
  object-position: center; }

.hoverable {
  position: relative; }

.hoverable::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  position: absolute;
  bottom: 0px;
  left: 0;
  background: var(--light-color);
  transition: width .3s; }

.hoverable:hover::after {
  width: 100%; }

.db {
  display: block; }

.disabled {
  cursor: auto;
  pointer-events: visible; }

.container {
  width: 1280px;
  margin: auto; }

.swiper-slide {
  background: none; }

.main {
  width: 100%;
  max-width: 1920px;
  margin: auto;
  height: auto;
  margin-top: 105px; }

.hidden {
  display: none; }

.good {
  border-color: var(--light-color) !important; }

.fancybox-active {
  height: 100% !important; }

.fancybox-button.fancybox-close-small {
  display: none; }

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important;
  cursor: auto !important;
  pointer-events: visible !important; }

section {
  width: 100%;
  max-width: 1920px;
  margin: auto; }

.hidden-content {
  display: none; }

.ds-hidden {
  display: none !important; }

.hidden-content {
  display: none; }

.def-btn {
  padding: 26px 110px;
  border-radius: 20px;
  border: 1px solid var(--light-color);
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: var(--light-color) !important;
  transition: .3s; }

.def-btn:hover {
  background: var(--light-color);
  color: #fff !important; }

.def-btn.active {
  background: var(--light-color);
  color: #fff !important; }

.def-text-btn {
  font-family: var(--font);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  text-decoration-line: underline;
  text-decoration-color: var(--light-color);
  text-decoration-thickness: 1px;
  color: var(--light-color); }

.hidden-menu {
  display: none; }

.hidden-menu {
  position: fixed;
  display: block;
  top: 105px;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 0;
  -webkit-transition: height .3s;
  transition: height .3s;
  background: linear-gradient(180deg, #EAEAEB -162.5%, #F8F8F8 100%);
  box-shadow: -3px 10px 23px rgba(0, 0, 0, 0.03);
  overflow: hidden; }

.hidden-menu.active {
  height: 691px; }

.hamburger {
  width: 26px;
  height: 19px;
  display: block;
  transition: .3s; }

.hamburger:hover,
.hamburger:hover svg * {
  transition: .3s; }

.hamburger svg {
  transition: .3s; }

.hamburger:hover svg *,
.hamburger.active svg * {
  fill: var(--light-color) !important; }

.hamburger.active svg {
  transform: rotate(90deg); }

.block-title {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  color: var(--dark-color); }

.block-title span {
  font-weight: 500; }

.block-title strong {
  font-weight: 500; }

.positioned-abs {
  position: absolute; }

.custom-pagination {
  width: 100%;
  margin-top: 75px; }

.custom-pagination .pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  align-items: center;
  justify-content: center; }

.custom-pagination .pagination .page-item:first-child {
  margin-left: 0; }

.custom-pagination .pagination .page-item {
  width: 10px;
  height: 20px;
  margin-right: 20px; }

.custom-pagination .pagination .page-item .page-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #30333A;
  transition: .3s;
  position: relative; }

.custom-pagination .pagination .page-item .page-link::before {
  width: 30px;
  height: 30px;
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.custom-pagination .pagination .page-item.active .page-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--light-color); }

.custom-pagination .pagination .page-item:hover .page-link {
  color: var(--light-color); }

.custom-pagination .pagination .page-item .page-link svg * {
  transition: .3s; }

.custom-pagination .pagination .page-item:hover .page-link svg * {
  fill: var(--light-color); }

.hidden-menu__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px; }

.aside__column {
  width: 23%; }

.aside__column:first-child {
  width: 63%; }

.aside__column-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #595959; }

.aside__column-content {
  margin-top: 30px; }

.aside__menu {
  width: 100%;
  list-style-type: none;
  padding: 0;
  column-count: 2;
  column-gap: 140px; }

.aside__menu > li {
  margin-bottom: 20px; }

.aside__menu > li a {
  width: 100%;
  height: 100%; }

.aside__menu > li.active {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.aside__link {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  transition: .3s; }

.aside__description {
  margin-top: 10px; }

.aside__description,
.aside__description *,
.aside__description p {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #595959 !important; }

.aside__menu > li:hover a .aside__link {
  color: #CE9967; }

.aside__phones {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.aside__phones a {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.aside__phones a:last-child {
  margin-bottom: 0; }

.aside__phones a span {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #595959;
  margin-left: 30px; }

.aside__emails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px; }

.aside__emails a {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }

.aside__emails a:last-child {
  margin-bottom: 0; }

.aside__emails a span {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #595959;
  margin-left: 20px; }

.aside-socials {
  width: 100%;
  margin-top: 40px; }

.aside-socials ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center; }

.aside-socials li {
  margin-right: 30px; }

.aside-socials li:last-child {
  margin-right: 0; }

.social-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #595959;
  margin-bottom: 10px; }

.aside-socials li svg * {
  transition: .3s; }

.aside-socials li:hover a svg path {
  fill: var(--light-color); }

.aside-socials li:hover a svg circle {
  stroke: var(--light-color); }

.aside__column-content a span,
.aside__column-content a {
  transition: .3s; }

.aside__column-content a:hover span,
.aside__column-content a:hover {
  color: var(--light-color); }

.aside__phones a:hover span,
.aside__emails a:hover span {
  color: var(--light-color); }

.oh {
  overflow: hidden !important; }

/* PORT */
.levitate-portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  width: 64px;
  height: 132px;
  position: fixed;
  right: 1%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  border: 1px solid #BE956A;
  cursor: pointer;
  z-index: 99; }

.levitate-portfolio::before {
  width: 17px;
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 100%; }

.levitate-portfolio__content-wrapper {
  width: 0;
  overflow: hidden;
  position: absolute;
  right: calc(100% + 17px);
  transition: .3s; }

.levitate-portfolio:hover .levitate-portfolio__content-wrapper {
  width: 225px; }

.levitate-portfolio__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #BE956A; }

.levitate-portfolio__item {
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  transition: .3s; }

.levitate-portfolio__item span {
  margin-left: 12px; }

.levitate-portfolio__item span {
  color: #BE956A;
  font-family: "M PLUS 1p";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  transition: .3s; }

.levitate-portfolio__item:hover {
  background: #BE956A; }

.levitate-portfolio__item:hover span {
  color: #fff; }

.levitate-portfolio__item svg * {
  transition: .3s; }

.levitate-portfolio__item:hover svg * {
  fill: #fff; }

@media (max-width: 980px) {
  .levitate-portfolio {
    background: #fff; }
  .levitate-portfolio__content {
    background: #fff; } }

/* PORT */
.cities {
  display: flex;
  grid-gap: 20px; }

.cities .def-btn {
  margin-top: 20px;
  padding: 15px 60px;
  font-weight: 400;
  font-size: 15px; }

.header {
  background: linear-gradient(180deg, #EAEAEB -162.5%, #F8F8F8 100%);
  box-shadow: -3px 10px 23px rgba(0, 0, 0, 0.03);
  z-index: 3;
  width: 100%;
  max-width: 1920px;
  z-index: 100;
  position: relative; }

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0; }

.header__menu {
  position: relative; }

.header__menu ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0; }

.header__menu ul li {
  margin-right: 55px; }

.header__menu ul li:last-child {
  margin-right: 0; }

.header__menu ul li,
.header__menu ul li a {
  display: block;
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-color);
  transition: .3s; }

.header__menu ul li:hover a,
.header__menu ul li.active {
  color: var(--light-color); }

.header__contacts {
  display: flex;
  align-items: center; }

.quick_contacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.quick_contacts a {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--dark-color); }

.quick_contacts button {
  text-align: right; }

.socials {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 25px; }

.socials li {
  margin-right: 15px; }

.socials li:last-child {
  margin-right: 0; }

.socials li a svg * {
  transition: .3s; }

.socials li:hover a svg path,
.socials li:hover a svg * {
  fill: var(--light-color) !important; }

@media (max-width: 980px) {
  .header {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); }
  .header__menu {
    display: none; }
  .quick_contacts button {
    display: none; }
  .hamburger {
    margin-left: 20px; }
  .quick_contacts a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px; } }

.crumbs {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 99;
  padding-top: 40px;
  position: relative; }

.crumbs ul {
  display: flex;
  align-items: center;
  list-style: none;
  z-index: 99;
  flex-wrap: wrap;
  padding: 0; }

.crumbs ul li {
  width: auto;
  height: auto;
  display: flex;
  align-items: center; }

.crumbs ul li::after {
  display: block;
  content: "";
  width: 21px;
  height: 8px;
  margin: 0 20px;
  background: url("../img/icons/crumb-arrow.svg") no-repeat; }

.crumbs ul li:last-child::after {
  display: none; }

.crumbs ul li.active::after {
  display: none; }

.crumbs ul li, .crumbs ul li a {
  font-family: var(--font);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  color: var(--dark-color); }

.crumbs ul li.active, .crumbs ul li.active a {
  font-style: normal;
  font-weight: 500; }

.advantages__wrapper {
  padding-top: 30px;
  position: relative;
  padding-bottom: 120px; }

.advantages__wrapper .block-title {
  text-align: center; }

.advantages__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px; }

.advantage {
  width: auto;
  max-width: 480px;
  display: flex;
  align-items: center; }

.advantage__icon {
  width: 80px;
  height: 78px; }

.advantage__icon svg {
  max-width: 80px;
  max-height: 80px; }

.advantage__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-left: 20px;
  align-self: center; }

.advantage__title {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 100%;
  color: var(--dark-color); }

.advantage__title + .advantage__description {
  margin-top: 10px; }

.advantage__description *,
.advantage__description p {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.advantage__description * span,
.advantage__description p span,
.advantage__description * strong,
.advantage__description p strong {
  font-weight: 500 !important; }

.main-page .advantages__list .advantage__content {
  align-self: flex-start; }

.simple-form {
  position: relative;
  margin-top: 60px;
  padding-bottom: 60px; }

.simple-form__wrapper {
  box-sizing: border-box;
  height: auto;
  border: 2px solid var(--light-color);
  border-radius: 30px;
  position: relative;
  padding: 50px 108px;
  padding-bottom: 70px; }

.simple-form__form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px; }

.simple-form__form input {
  max-width: 340px; }

.seoblock__wrapper {
  position: relative;
  padding-top: 80px;
  padding-bottom: 160px; }

.seoblock__text {
  width: 100%;
  max-height: 165px;
  overflow: hidden;
  transition: .3s; }

.seoblock__text *,
.seoblock__text p {
  font-family: var(--font) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0.02em !important;
  color: var(--dark-color) !important;
  text-align: justify !important; }

.text-page .seoblock__text *,
.text-page .seoblock__text p {
  text-align: left !important; }

.seoblock__text strong,
.seoblock__text * strong {
  font-weight: 500 !important; }

.news-content .news-block-content h1,
.seoblock__text h1 {
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 35px !important;
  text-align: center !important; }

.news-content .news-block-content h2,
.seoblock__text h2 {
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 35px !important;
  text-align: center !important; }

.news-content .news-block-content h3,
.seoblock__text h3 {
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 35px !important;
  text-align: center !important; }

.news-content .news-block-content * strong,
.news-content .news-block-content strong *,
.news-content .news-block-content strong {
  font-weight: 700 !important; }

.seo-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  transition: .3s; }

.seo-btn span {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--light-color); }

.seo-btn svg {
  margin-left: 20px; }

.seo-btn svg,
.seo-btn svg * {
  transition: .3s; }

.seo-btn.active svg,
.seo-btn:hover svg {
  transform: rotate(90deg); }

.simple-form.complex-form-block .simple-form__wrapper {
  height: auto; }

.simple-form__form.complex-form {
  width: 100%;
  overflow: hidden;
  height: auto;
  margin-top: 0; }

.simple-form__form.complex-form .swiper-slide.last-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px; }

.simple-form__form.complex-form .swiper-slide {
  width: 100%;
  height: 100%;
  position: relative;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out; }

.simple-form__form.complex-form .swiper-slide .block-title {
  text-align: center; }

.simple-form__form.complex-form .swiper-slide .block-subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  color: #222730;
  margin-top: 10px; }

.complex-input__wrapper {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.complex-input__wrapper input,
.complex-input__wrapper button {
  width: 340px;
  height: 76px; }

.swiper-pagination.complex-form-pagintaion {
  bottom: 40px !important;
  left: 50% !important;
  transform: translateX(-50%) !important; }

.swiper-pagination.complex-form-pagintaion .swiper-pagination-bullet {
  width: 50px;
  height: 2px;
  border-radius: 0;
  background-color: #D9D9D9;
  opacity: 1; }

.swiper-pagination.complex-form-pagintaion .swiper-pagination-bullet-active {
  background-color: var(--light-color);
  height: 4px;
  opacity: 1; }

@media (max-width: 980px) {
  .advantages__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 40px; }
  .advantage__description *, .advantage__description p {
    font-size: 16px !important; }
  .advantage__icon svg {
    width: 70px;
    height: 70px; }
  .advantages__wrapper {
    padding-bottom: 60px; }
  .advantage__title {
    font-size: 24px;
    line-height: 100%; }
  .advantage__icon {
    width: 70px;
    height: 70px; } }

@media (max-width: 980px) {
  .seoblock__wrapper {
    padding-top: 0;
    padding-bottom: 50px; }
  .news-content .news-block-content h1,
  .seoblock__text h1 {
    font-size: 24px !important; }
  .news-content .news-block-content h2,
  .seoblock__text h2 {
    font-size: 22px !important; }
  .news-content .news-block-content h3,
  .seoblock__text h3 {
    font-size: 20px !important; }
  .seoblock__text *, .seoblock__text p {
    font-size: 16px !important; }
  .seoblock__text {
    max-height: 164px; }
  .seo-btn {
    margin-top: 20px; }
  .seo-btn svg {
    width: 14px;
    height: 14px; }
  .seo-btn span {
    font-size: 16px; } }

.hero {
  height: auto; }

.dev-page .hero {
  height: calc(100vh - 105px); }

.hero__wrapper {
  padding-top: 50px;
  padding-bottom: 120px;
  position: relative; }

.hero__title {
  max-width: 700px;
  max-height: 360px;
  padding-top: 100px; }

.hero__title *,
.hero__title p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 130% !important;
  color: var(--dark-color) !important; }

.hero__title * span,
.hero__title p span {
  font-weight: 800 !important; }

.hero__form {
  max-width: 900px;
  display: flex;
  align-items: center;
  padding-top: 80px;
  position: relative;
  z-index: 2; }

.hero__form input {
  max-width: 303px;
  margin-right: 10px; }

.hero__form button {
  width: 260px;
  height: 77px;
  background: var(--dark-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  transition: .3s; }

.hero__form button:hover {
  background: var(--light-color); }

.hero__img {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 1; }

.dev-page .hero__img {
  right: -14%; }

.dev-page .advantages__list .advantage:first-child {
  width: 445px; }

.site-types__wrapper {
  position: relative;
  padding-top: 80px;
  padding-bottom: 120px; }

.site-types__top-title {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  text-align: center;
  color: var(--dark-color);
  position: relative; }

.site-types__top-title span {
  position: relative; }

.site-types__top-title span::after {
  width: 452px;
  height: 2px;
  display: block;
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background: var(--light-color); }

.site-types__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 105px; }

.big-container {
  width: 1720px;
  margin-left: auto;
  margin-right: auto; }

.site-type__item {
  width: 100%;
  height: auto;
  border: 2px solid var(--light-color);
  border-radius: 50px;
  position: relative;
  transition: .3s; }

.site-type__img {
  width: 100%;
  height: 343px;
  border-radius: 50px 50px 0px 0px;
  transition: .3s; }

.site-type__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
  filter: grayscale(100);
  border-radius: 50px 50px 0px 0px; }

.site-type__item:hover img {
  filter: grayscale(0); }

.site-type__content {
  width: 100%;
  height: calc(100% - 343px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 30px;
  padding-bottom: 50px;
  position: relative; }

.site-type__title {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 160%;
  color: var(--dark-color); }

.site-type__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--dark-color); }

.site-type__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-top: 20px; }

.site-type__list li {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--dark-color);
  margin-bottom: 10px; }

.site-type__list li:last-child {
  margin-bottom: 0; }

.site-type__list li::before {
  display: block;
  content: '';
  width: 11px;
  height: 9px;
  background: url("../img/icons/mark.svg") no-repeat;
  margin-right: 10px; }

.site-type__item .def-btn {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto; }

.site-type__header svg {
  display: none; }

.site-type__content-wrapper {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  transition: .3s; }

.site-type-content-self {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 50px; }

.connect {
  position: relative; }

.connect__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.connect__wrapper .block-title {
  text-align: center; }

.connect__list {
  width: 100%;
  position: relative;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 30px;
  grid-column-gap: 85px;
  margin-top: 60px; }

.connect__title {
  display: flex;
  align-items: center; }

.connect__title .text {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: var(--dark-color);
  margin-left: 5px; }

.connect__title .number {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  color: var(--light-color); }

.connect__content {
  width: 100%;
  list-style-type: none;
  padding: 0; }

.connect__content li {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark-color);
  margin-bottom: 10px; }

.connect__content li::before {
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  display: block;
  content: '';
  background: var(--light-color);
  border-radius: 100%;
  margin-right: 10px; }

.connect__content li:last-child {
  margin-bottom: 0; }

.connect__wrapper::after {
  width: 376px;
  height: 208px;
  display: block;
  content: '';
  position: absolute;
  bottom: 113px;
  right: -120px;
  background: url("../img/site-images/connect/lad.png") no-repeat; }

.connect::after {
  width: 385px;
  height: 586px;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: -111px;
  background: url(../img/site-images/connect/desk.png) no-repeat;
  transform: scaleX(-1); }

.connect__title svg {
  display: none;
  transition: .3s; }

.connect__content-wrapper {
  width: 100%; }

.connect__content-itself {
  width: 100%; }

.rq-checker__wrapper {
  display: flex;
  align-items: flex-start;
  padding-top: 80px;
  padding-bottom: 100px;
  position: relative; }

.rq-checker__poster {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px; }

.rq-checker__poster img {
  border-radius: 10px; }

.rq-checker__content {
  position: relative;
  padding-top: 25px;
  margin-left: 115px; }

.rq-checker__title *,
.rq-checker__title p {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 130% !important;
  color: var(--dark-color) !important; }

.rq-checker__title * span,
.rq-checker__title p span {
  font-style: normal !important;
  font-weight: 500 !important;
  color: var(--light-color) !important; }

.rq-checker__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: var(--dark-color);
  margin-top: 20px; }

.checker-btn {
  box-sizing: border-box;
  width: 303px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #CE9967;
  border-radius: 20px;
  margin-top: 60px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: var(--light-color);
  transition: .3s; }

.checker-btn:hover {
  background: var(--light-color);
  color: #fff; }

.m-portfolio__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden; }

.m-portfolio__slider-wrapper {
  width: 100%;
  height: auto;
  position: relative; }

.m-portfolio__slider {
  width: 100%;
  height: auto;
  position: relative; }

.m-portfolio__slider .swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.m-p__top-title {
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--dark-color); }

.m-p__title {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 130%;
  color: var(--dark-color);
  margin-top: 10px; }

.m-p__description {
  width: 100%;
  margin-top: 20px; }

.m-p__description *,
.m-p__description p {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.m-portfolio__slider-wrapper .def-btn {
  width: 303px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px; }

.swiper-pagination.m-portfolio-pagintaion {
  display: flex;
  align-items: flex-end;
  bottom: 50px !important;
  left: 0 !important;
  max-width: 50% !important;
  transform: translateX(0) !important; }

.swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet {
  width: 100px;
  height: 2px;
  border-radius: 0;
  background-color: #D9D9D9;
  opacity: 1; }

.swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet-active {
  background-color: var(--light-color);
  height: 4px;
  opacity: 1; }

.go-to-portfolio {
  width: 310px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0; }

.go-to-portfolio span {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  text-decoration-line: underline;
  text-decoration-color: #616161;
  text-decoration-thickness: 1px;
  color: #616161;
  transition: .3s; }

.go-to-portfolio svg {
  margin-left: 33px; }

.go-to-portfolio:hover span {
  color: var(--light-color);
  text-decoration-color: var(--light-color); }

.go-to-portfolio svg * {
  transition: .3s; }

.go-to-portfolio:hover svg * {
  fill: var(--light-color); }

.portfolio__actions {
  width: 100%;
  margin-top: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.portfolio__actions .swiper-pagination.m-portfolio-pagintaion {
  bottom: 0 !important;
  position: relative !important; }

.context-page .m-portfolio__slider, .main-page .m-portfolio__slider {
  padding-bottom: 0 !important; }

.m-portfolio__wrapper .left-side {
  min-width: 620px !important; }

.m-portfolio__wrapper .right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.dev-page .m-portfolio__wrapper .right-side {
  padding-top: 0; }

.dev-page .m-portfolio__wrapper .left-side {
  padding-top: 20px; }

.steps__wrapper {
  padding-top: 120px;
  padding-bottom: 100px;
  position: relative; }

.steps__header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.steps__top-title *,
.steps__top-title p,
.steps__top-title {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 40px !important;
  line-height: 130% !important;
  color: var(--dark-color) !important; }

.steps__top-title span {
  font-weight: 500 !important;
  color: var(--light-color) !important; }

.steps__top-subtitle *,
.steps__top-subtitlep,
.steps__top-subtitle {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.steps__right-side {
  display: flex;
  align-items: flex-start;
  position: relative; }

.steps__count {
  margin-right: 60px; }

.steps__count:last-child {
  margin-right: 0; }

.steps__count-number {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: var(--dark-color); }

.steps__count-number span {
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 96px;
  color: var(--dark-color); }

.steps__count:last-child .steps__count-number,
.steps__count:last-child .steps__count-number span {
  color: var(--light-color); }

.steps__count-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #939393;
  text-align: right; }

.steps__pagination {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  margin-top: 60px; }

.steps__pagination::after {
  width: 100%;
  height: 2px;
  display: block;
  content: '';
  background: var(--light-color);
  position: absolute;
  top: 49px;
  left: 0; }

.step__pagination-number {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #939393;
  transition: .3s; }

.steps__pagination-bullet {
  position: relative;
  cursor: pointer;
  z-index: 9; }

.steps__pagination-bullet.active .step__pagination-number {
  font-weight: 500;
  color: var(--dark-color); }

.step__pagination-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #939393;
  margin-top: 42px;
  transition: .3s; }

.steps__pagination-bullet.active .step__pagination-title {
  font-weight: 800;
  color: var(--dark-color); }

/* ############################################################## */
.steps__pagination-bullet::after {
  width: 30px;
  height: 30px;
  opacity: 0;
  display: block;
  content: '';
  position: absolute;
  top: 33px;
  left: 50%;
  border: 2px solid transparent;
  border-radius: 50%;
  transform: translateX(-50%);
  transition: opacity .3s;
  transition: .3s; }

.steps__pagination-bullet::before {
  width: 16px;
  height: 16px;
  opacity: 0;
  display: block;
  content: '';
  position: absolute;
  top: 42px;
  left: 50%;
  transition: opacity .3s;
  transform: translateX(-50%);
  border-radius: 50%;
  transition: .3s; }

.steps__pagination-bullet.active::after {
  border-color: var(--light-color);
  opacity: 1; }

.steps__pagination-bullet.active::before {
  background: var(--light-color);
  opacity: 1; }

.steps__pagination-bullet:hover::before {
  background: var(--light-color);
  opacity: 1; }

/* ############################################################## */
.steps__pagination-bullet:hover .step__pagination-title,
.steps__pagination-bullet:hover .step__pagination-number {
  color: var(--light-color); }

.steps__slider {
  width: 100%;
  min-height: 372px;
  position: relative;
  overflow: hidden;
  margin-top: 80px;
  border: 2px solid #CE9967;
  box-shadow: 0px 164px 66px rgba(222, 222, 222, 0.01), 0px 92px 55px rgba(222, 222, 222, 0.05), 0px 41px 41px rgba(222, 222, 222, 0.09), 0px 10px 23px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1);
  border-radius: 30px; }

.steps__slider .swiper-slide {
  width: 100%;
  height: 90%;
  position: relative;
  padding: 40px 110px;
  border-radius: 30px; }

.step__slide-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 160%;
  color: var(--dark-color); }

.step__slide-description {
  width: 100%;
  margin-top: 10px; }

.step__slide-description *,
.step__slide-description p {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.step__slide-description * strong,
.step__slide-description p strong {
  font-weight: 500 !important; }

.steps__navigation {
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9; }

.swiper-button-next:after, .swiper-button-prev:after {
  display: none; }

.steps__slider .swiper-button-next, .steps__slider .swiper-button-prev {
  position: relative;
  top: 0;
  margin-top: 0;
  left: 0;
  right: 0; }

.steps__slider .swiper-button-prev {
  box-sizing: border-box;
  width: 100px;
  height: 70px;
  background: #F7F7F7;
  border: 2px solid #CE9967;
  border-radius: 0px 0px 30px 0px;
  transform: rotate(-180deg);
  border-top: 0;
  transition: .3s; }

.steps__slider .swiper-button-next {
  width: 100px;
  height: 70px;
  border-radius: 0px 0px 30px 0px;
  border-top: 0;
  border: 2px solid #CE9967;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  transition: .3s; }

.steps__slider .swiper-button-prev svg *,
.steps__slider .swiper-button-next svg * {
  fill: var(--light-color); }

.steps__slider .swiper-button-prev svg *,
.steps__slider .swiper-button-next svg * {
  transition: .3s; }

.steps__slider .swiper-button-prev:hover svg *,
.steps__slider .swiper-button-next:hover svg * {
  fill: #fff; }

.steps__slider .swiper-button-prev:hover,
.steps__slider .swiper-button-next:hover {
  background: var(--light-color); }

.steps__slider .swiper-button-prev svg {
  transform: scale(-1); }

.steps__outro {
  width: 100%;
  position: relative;
  margin-top: 120px; }

.steps__outro-title *,
.steps__outro-title p {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: var(--dark-color) !important; }

.steps__outro-title * strong,
.steps__outro-title p strong {
  font-weight: 500 !important;
  color: var(--light-color) !important; }

.steps__outro-subtitle {
  width: 100%;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center !important;
  color: var(--dark-color); }

.figa {
  width: 442px;
  height: 446px;
  position: absolute;
  bottom: 10px;
  right: -270px; }

@media (max-width: 980px) {
  .hero__title {
    padding-top: 0; }
  .hero__img2 {
    position: absolute;
    right: -110px;
    bottom: 0; }
  .hero__form button {
    width: 217px;
    height: 69px;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px; }
  .dev-page .hero__img {
    right: -29%;
    top: 45%; }
  .dev-page .advantages__list .advantage:first-child {
    width: 100%; } }

@media (max-width: 1680px) {
  .big-container {
    width: 1280px; }
  .context-page .m-portfolio__slider .swiper-slide .left-side {
    padding-left: 30px; } }

@media (max-width: 980px) {
  .site-types__wrapper {
    width: 100%; }
  .site-types__top-title {
    font-size: 24px;
    line-height: 130%; }
  .site-types__top-title span::after {
    width: 100%; }
  .site-types__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 40px; }
  .site-type__header svg {
    display: block; }
  .site-type__item {
    height: auto;
    transition: .3s;
    border: none; }
  .site-types__wrapper {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto; }
  .site-type__img {
    display: none; }
  .site-type__content {
    height: auto;
    transition: .3s;
    padding: 0; }
  .site-type__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .site-type__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-transform: uppercase; }
  .site-type__item {
    padding-bottom: 20px;
    border-bottom: none;
    border-radius: 0;
    position: relative; }
  .site-type__item::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: #C7C7C7;
    bottom: 0;
    left: 0; }
  .site-type__content-wrapper {
    overflow: hidden;
    max-height: 0; }
  .site-type-content-self {
    padding-top: 20px; }
  .site-type__header svg {
    transition: .3s; }
  .site-type__header.active svg {
    transform: rotate(90deg); }
  .site-type__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%; }
  .site-type__list li {
    font-size: 16px;
    line-height: 160%; }
  .site-type__item .def-btn {
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 40px; } }

@media (max-width: 980px) {
  .connect::after {
    display: none; }
  .connect__wrapper {
    padding-top: 30px; }
  .connect__list {
    width: 100%;
    position: relative;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0;
    margin-top: 20px; }
  .connect__title svg {
    display: block;
    transition: .3s; }
  .connect__title {
    width: 100%;
    display: flex;
    align-items: center; }
  .connect__list > li {
    padding-bottom: 20px;
    border-bottom: 1px solid #C7C7C7; }
  .connect__title .number {
    font-size: 30px;
    line-height: 140%; }
  .connect__title .text {
    font-size: 18px;
    line-height: 25px;
    margin-left: 10px; }
  .connect__title svg {
    margin-left: auto; }
  .connect__content-wrapper {
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: .3s; }
  .connect__content-itself {
    width: 100%;
    padding-top: 15px; }
  .connect__content li {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%; }
  .connect__title.active svg {
    transform: rotate(90deg); }
  .connect__wrapper {
    padding-bottom: 0; }
  .connect__wrapper::after {
    display: none; } }

@media (max-width: 980px) {
  .rq-checker__wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-top: 140px;
    padding-bottom: 60px; }
  .rq-checker__content {
    width: 100%;
    margin-left: 0; }
  .rq-checker__title *, .rq-checker__title p {
    font-size: 24px !important; }
  .rq-checker__subtitle,
  .rq-checker__subtitle *,
  .rq-checker__subtitle p {
    font-family: var(--font) !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 160% !important; }
  .rq-checker__poster {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 145.806px 58.6781px rgba(0, 0, 0, 0.01)) drop-shadow(0px 81.7938px 48.8984px rgba(0, 0, 0, 0.05)) drop-shadow(0px 36.4516px 36.4516px rgba(0, 0, 0, 0.09)) drop-shadow(0px 8.89062px 20.4484px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    margin-top: 40px; }
  .rq-checker__poster img {
    width: 80%;
    height: 318px;
    object-fit: contain; }
  .checker-btn {
    width: 270px;
    height: 68px;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 40px; } }

@media (max-width: 980px) {
  .mp-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px; }
  .m-p__top-title {
    font-size: 18px; }
  .m-p__title,
  .m-p__title *,
  .m-p__title p {
    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 130% !important;
    color: var(--dark-color) !important; }
  .m-portfolio__slider .swiper-slide img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    margin-top: 30px;
    margin-bottom: 40px; }
  .m-p__description *, .m-p__description p {
    font-size: 16px !important; }
  .m-portfolio__slider-wrapper .def-btn {
    width: 220px;
    height: 68px;
    font-size: 16px;
    margin-top: 40px; }
  .context-page .m-portfolio__slider, .main-page .m-portfolio__slider {
    padding-bottom: 0 !important; }
  .go-to-portfolio {
    width: auto;
    margin-left: 0;
    margin-top: 85px; }
  .go-to-portfolio span {
    font-size: 17px; }
  .go-to-portfolio svg {
    margin-left: 105px;
    width: 40px; }
  .swiper-pagination.m-portfolio-pagintaion {
    max-width: calc(100% - 20%) !important;
    width: 100% !important;
    bottom: 115px !important;
    left: 6%;
    transform: translateX(6%) !important; }
  .swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet-active {
    width: 60px; }
  .swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet {
    width: 60px;
    height: 4px; }
  .main-page .simple-form.complex-form-block {
    margin-top: 50px;
    margin-bottom: 50px; }
  .simple-form.complex-form-block .simple-form__wrapper {
    height: auto;
    padding-bottom: 90px; }
  .simple-form__form.complex-form {
    height: auto; }
  .simple-form__form.complex-form .swiper-slide {
    height: auto; }
  .simple-form__form.complex-form .swiper-slide .block-subtitle {
    font-size: 17px;
    text-align: left; }
  .complex-input__wrapper {
    margin-top: 40px;
    flex-direction: column; }
  .swiper-pagination.complex-form-pagintaion {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    bottom: 10% !important; }
  .swiper-pagination.complex-form-pagintaion .swiper-pagination-bullet {
    width: 44px; }
  .portfolio__actions {
    margin-top: 40px;
    flex-direction: column;
    align-items: flex-start; }
  .swiper-pagination.m-portfolio-pagintaion {
    transform: translateX(0%) !important; }
  .go-to-portfolio {
    margin-top: 40px; }
  .m-portfolio__wrapper .left-side {
    min-width: 100% !important; }
  .portfolio__actions {
    width: 100%; } }

@media (max-width: 980px) {
  .steps__wrapper {
    padding-top: 40px;
    padding-bottom: 40px; }
  .steps__pagination {
    display: none; }
  .steps__header {
    flex-direction: column;
    align-items: flex-start; }
  .steps__left-side {
    width: 100%; }
  .steps__top-title, .steps__top-title *, .steps__top-title p {
    font-size: 24px !important;
    line-height: 130% !important; }
  .steps__top-subtitle {
    margin-top: 10px; }
  .steps__top-subtitle, .steps__top-subtitle *, .steps__top-subtitlep {
    font-size: 16px !important;
    line-height: 160% !important; }
  .steps__right-side {
    width: 100%;
    margin-top: 25px; }
  .steps__count-number {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    text-align: left; }
  .steps__count-number span {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 120%; }
  .steps__count-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%; }
  .steps__count {
    margin-right: 80px; }
  .steps__slider {
    min-height: 0;
    padding: 0;
    margin-top: 30px; }
  .steps__slider .swiper-slide {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0px 145.806px 58.6781px rgba(222, 222, 222, 0.01), 0px 81.7938px 48.8984px rgba(222, 222, 222, 0.05), 0px 36.4516px 36.4516px rgba(222, 222, 222, 0.09), 0px 8.89062px 20.4484px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1);
    border-radius: 26px;
    padding-bottom: 120px; }
  .step__slide-title,
  .step__slide-title p,
  .step__slide-title * {
    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 18px !important;
    line-height: 140% !important; }
  .step__slide-description *, .step__slide-description p {
    font-size: 16px !important;
    line-height: 160% !important; }
  .steps__slider .swiper-button-prev,
  .steps__slider .swiper-button-next {
    height: 60px; }
  .steps__outro {
    margin-top: 50px; }
  .steps__outro-title *, .steps__outro-title p {
    font-size: 24px !important;
    line-height: 130% !important;
    color: #CE9967 !important; }
  .steps__outro-title * strong, .steps__outro-title p strong {
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 130% !important;
    color: #595959 !important; }
  .steps__outro-subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%; }
  .figa {
    width: 121px;
    height: 143px;
    bottom: -80px;
    right: -20px;
    object-fit: contain; } }

.main-page .hero {
  min-height: 691px; }

.main-page .advantages__list {
  margin-top: 80px; }

.main-page .hero__title {
  max-width: 725px; }

.main-page .hero__title span {
  color: var(--light-color) !important; }

.main-page .hero__title strong span {
  color: var(--dark-color) !important; }

.context-page .m-portfolio__slider-wrapper,
.main-page .m-portfolio__slider-wrapper {
  height: auto; }

.context-page .m-portfolio__slider,
.main-page .m-portfolio__slider {
  overflow: hidden;
  height: auto;
  padding-bottom: 140px; }

.m-p__description strong {
  font-weight: 800 !important; }

.context-page .m-portfolio__slider .swiper-slide {
  overflow: hidden; }

.context-page .m-portfolio__slider .swiper-slide .left-side {
  width: 620px; }

.weu {
  position: relative;
  min-height: 784px; }

.weu__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.weu__description {
  position: relative;
  margin-top: 45px; }

.weu__description,
.weu__description *,
.weu__description p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  color: var(--dark-color); }

.weu__description strong,
.weu__description * strong,
.weu__description p strong {
  font-weight: 500 !important; }

.weu__img {
  position: absolute;
  top: 80px;
  right: 0; }

.whatwedo {
  position: relative; }

.particles {
  position: absolute; }

.whatwedo__wrapper {
  position: relative;
  padding-top: 80px;
  padding-bottom: 155px; }

.whatwedo__title,
.whatwedo__title p,
.whatwedo__title * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: var(--dark-color) !important; }

.whatwedo__title strong,
.whatwedo__title p strong,
.whatwedo__title * strong {
  font-weight: 500 !important; }

.whatwedo__subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: var(--light-color);
  margin-top: 40px; }

.whatwedo__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-top: 80px; }

.whatwedo__list li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #22273033;
  padding-bottom: 65px;
  margin-top: 60px; }

.whatwedo__list li:first-child {
  margin-top: 0; }

.whatwedo__right-side {
  margin-left: 200px; }

.whatwedo__left-side,
.whatwedo__left-side p,
.whatwedo__left-side * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 24px !important;
  line-height: 140% !important;
  text-align: center !important;
  color: var(--dark-color) !important; }

.whatwedo__left-side strong,
.whatwedo__left-side p strong,
.whatwedo__left-side * strong {
  color: var(--light-color) !important; }

.whatwedo__right-side,
.whatwedo__right-side p,
.whatwedo__right-side * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  color: var(--dark-color) !important;
  text-last: left !important; }

.whatwedo__right-side strong,
.whatwedo__right-side p strong,
.whatwedo__right-side * strong {
  font-weight: 800 !important; }

.whatwedo__left-side {
  width: 425px; }

.whatwedo__right-side {
  width: 690px; }

.whatwedo__right-side {
  display: flex;
  align-items: center; }

.whatwedo__right-side::before {
  width: 6px;
  height: 6px;
  display: block;
  content: '';
  background: var(--light-color);
  border-radius: 50%;
  margin-right: 16px; }

.particle__1 {
  top: 190px;
  left: 0; }

.particle__2 {
  bottom: 10px;
  right: 40px; }

.home-suggest__wrapper {
  position: relative;
  padding-top: 80px;
  padding-bottom: 120px; }

.home-suggest__wrapper .block-title {
  text-align: center; }

.home-suggest__list {
  width: 1085px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 65px; }

.home-suggest__list > li {
  width: 100%;
  display: flex;
  align-items: flex-start;
  box-shadow: -41px 162px 67px rgba(0, 0, 0, 0.01), -23px 91px 56px rgba(0, 0, 0, 0.05), -10px 40px 42px rgba(0, 0, 0, 0.09), -3px 10px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding-left: 70px;
  padding-bottom: 70px;
  padding-right: 25px;
  padding-top: 50px; }

.home-suggest__list > li .left-side {
  max-width: 105px; }

.home-suggest__list > li .left-side svg {
  max-width: 105px; }

.home-suggest__list > li .right-side {
  margin-left: 20px; }

.home-suggest__title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: var(--dark-color); }

.home-suggest__list > li .right-side ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px; }

.home-suggest__list > li .right-side ul li {
  display: flex;
  align-items: center;
  padding-left: 5px;
  position: relative; }

.home-suggest__list > li .right-side ul li::before {
  min-width: 3px;
  max-width: 3px;
  min-height: 3px;
  max-height: 3px;
  display: block;
  content: '';
  border-radius: 50%;
  background: var(--dark-color);
  margin-right: 10px;
  transition: .3s; }

.home-suggest__list > li .right-side ul li a {
  width: 100%;
  display: block;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark-color);
  transition: .3s; }

.home-suggest__list > li .right-side ul li a span {
  position: relative; }

.home-suggest__list > li .right-side ul li a span::before {
  width: 100%;
  height: 1px;
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0px;
  background: var(--dark-color); }

.home-suggest__list > li .right-side ul li:hover a {
  color: var(--light-color); }

.home-suggest__list > li .right-side ul li:hover::before {
  background: var(--light-color); }

@media (max-width: 980px) {
  .hero {
    height: auto !important;
    min-height: auto !important; }
  .hero__wrapper {
    padding-top: 40px;
    padding-bottom: 160px; }
  .main-page .hero__title {
    max-width: 100%;
    padding-top: 0; }
  .hero__title *, .hero__title p {
    font-size: 24px !important; }
  .hero__form {
    padding-top: 60px; }
  .hero__form input {
    display: none; }
  .hero-form-btn {
    width: 218px;
    height: 68px;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    line-height: 140%; }
  .main-page .hero__img {
    width: 170px;
    height: 290px;
    top: 35%;
    right: -9%;
    object-fit: contain;
    bottom: 0; } }

@media (max-width: 980px) {
  .weu__wrapper {
    padding-top: 0;
    padding-bottom: 270px; }
  .weu__img {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0; }
  .weu__img2 {
    position: absolute;
    bottom: 20px;
    left: 0; }
  .weu__wrapper .block-title {
    text-align: left; }
  .weu__description {
    width: 100%;
    margin-top: 20px; }
  .weu__description, .weu__description *, .weu__description p {
    font-size: 16px !important;
    line-height: 130% !important; }
  .weu {
    min-height: 100px;
    margin-bottom: 60px; } }

@media (max-width: 1680px) {
  .particle__1 {
    top: 190px;
    left: -172px; }
  .particle__2 {
    bottom: 10px;
    right: -105px; }
  .weu__img {
    position: absolute;
    top: 80px;
    right: -130px; } }

@media (max-width: 980px) {
  .whatwedo__wrapper {
    position: relative;
    padding-top: 20px;
    padding-bottom: 40px; }
  .whatwedo .particle__1 {
    display: none; }
  .whatwedo__title, .whatwedo__title *, .whatwedo__title p {
    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 130% !important;
    text-align: center !important; }
  .whatwedo__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: var(--light-color);
    margin-top: 10px; }
  .whatwedo__list {
    margin-top: 40px; }
  .whatwedo__list li {
    padding-bottom: 25px; }
  .whatwedo__right-side,
  .whatwedo__left-side {
    width: auto; }
  .whatwedo__right-side, .whatwedo__right-side *, .whatwedo__right-side p,
  .whatwedo__left-side, .whatwedo__left-side *, .whatwedo__left-side p {
    font-size: 16px !important; }
  .whatwedo__right-side {
    margin-left: 44px; }
  .whatwedo__right-side::before {
    min-width: 6px;
    max-width: 6px;
    min-height: 6px;
    max-height: 6px;
    margin-right: 9px; }
  .whatwedo .particle__2 {
    width: 126px;
    height: 148px;
    object-fit: contain;
    left: -40px;
    bottom: 0; }
  .whatwedo__wrapper {
    padding-bottom: 100px; }
  .whatwedo {
    margin-bottom: 20px; }
  .home-suggest__wrapper {
    position: relative;
    padding-top: 30px;
    padding-bottom: 50px; }
  .home-suggest__list {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin-top: 40px;
    padding: 0; }
  .home-suggest__list > li {
    background: #F2F2F2;
    box-shadow: -36.4516px 144.028px 59.5672px rgba(0, 0, 0, 0.01), -20.4484px 80.9047px 49.7875px rgba(0, 0, 0, 0.05), -8.89062px 35.5625px 37.3406px rgba(0, 0, 0, 0.09), -2.66719px 8.89062px 20.4484px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    padding-top: 30px;
    padding-right: 22px;
    padding-bottom: 40px;
    padding-left: 30px;
    align-items: center; }
  .home-suggest__list > li:nth-child(1) .left-side {
    width: 44px;
    height: 38px; }
  .home-suggest__list > li:nth-child(2) .left-side {
    width: 36px;
    height: 38px; }
  .home-suggest__list > li svg {
    width: 100%;
    height: 100%; }
  .home-suggest__title {
    font-size: 19px; }
  .home-suggest__list > li .right-side ul li a {
    font-size: 16px; } }

.logo-page .hero {
  min-height: 788px; }

.hero__subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 130%;
  color: var(--dark-color); }

.herologo .hero__title {
  margin-top: 10px;
  padding-top: 0;
  overflow: visible; }

.herologo .hero__subtitle {
  padding-top: 90px; }

.hero__title strong {
  font-style: normal;
  position: relative;
  font-weight: 800;
  font-size: 40px;
  line-height: 130%; }

.hero__title strong span {
  position: relative; }

.hero__title strong span::after {
  width: 100%;
  height: 2px;
  display: block;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0px;
  background: var(--light-color); }

.herologo .hero__img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; }

.work-steps__wrapper {
  padding-top: 80px;
  padding-bottom: 100px;
  position: relative; }

.work-steps__wrapper .block-title {
  text-align: center; }

.work-steps__list {
  width: 100%;
  margin-top: 60px;
  list-style-type: none;
  padding: 0; }

.work-steps__list > li {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  border-bottom: 1px solid #22273033;
  padding-bottom: 50px; }

.work-steps__list li:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.work-step__number {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 500;
  font-size: 85px;
  line-height: 100%;
  background: linear-gradient(180deg, #CE9967 0%, rgba(255, 189, 128, 0.9) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.work-step__title {
  width: 215px;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: var(--dark-color); }

.work-steps__list .work-step__number {
  width: 101px;
  margin-right: 150px; }

.work-step__chars {
  width: calc(calc(calc(calc(100% - 215px) - 101px) - 150px) - 75px);
  margin-left: 75px;
  list-style-type: none;
  padding: 0; }

.work-step__chars li {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark-color);
  margin-bottom: 5px; }

.work-step__chars li:last-child {
  margin-bottom: 5px; }

.work-step__chars li::before {
  width: 3px;
  height: 3px;
  display: block;
  content: '';
  background: var(--dark-color);
  border-radius: 100%;
  margin-right: 10px; }

.work-steps__wrapper .def-btn {
  width: 303px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding-top: 26px;
  padding-bottom: 26px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto; }

.work-steps__wrapper .def-btn span {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: var(--light-color); }

.work-steps__wrapper .def-btn span {
  transition: .3s; }

.work-steps__wrapper .def-btn:hover span {
  color: #fff; }

.work-steps__wrapper .def-btn svg {
  margin-left: 7px; }

.work-steps__wrapper .def-btn svg * {
  transition: .3s; }

.work-steps__wrapper .def-btn:hover svg * {
  fill: #fff; }

@media (max-width: 980px) {
  .logo-page .hero__subtitle {
    padding-top: 0;
    margin-top: 0;
    order: 0;
    display: none; }
  .logo-page .herologo .hero__img {
    width: 280px;
    height: 240px;
    object-fit: contain;
    margin-top: 0;
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
    order: 2; }
  .logo-page .hero__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 50px; }
  .logo-page .hero__title {
    order: 1; }
  .logo-page .hero__form {
    order: 3; } }

@media (max-width: 980px) {
  .work-steps__wrapper {
    padding-top: 30px;
    padding-bottom: 50px; }
  .context-page .work-steps__wrapper .block-title {
    text-align: left; }
  .work-steps__list {
    margin-top: 40px; }
  .work-steps__list > li {
    flex-wrap: wrap;
    padding-bottom: 15px;
    margin-bottom: 20px; }
  .work-steps__list .work-step__number {
    width: auto;
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 100%; }
  .work-step__title {
    width: auto;
    text-align: left;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 140%; }
  .work-step__chars {
    width: 100%;
    margin-left: 0;
    list-style-type: none;
    padding: 0; }
  .work-step__chars li {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 5px; }
  .work-step__chars li::before {
    min-width: 3px;
    max-width: 3px;
    min-height: 3px;
    max-height: 3px;
    display: block;
    content: "";
    background: var(--dark-color);
    border-radius: 100%;
    margin-right: 10px; }
  .work-steps__list > li:last-child {
    border-bottom: 1px solid rgba(34, 39, 48, 0.2); }
  .work-steps__wrapper .def-btn {
    width: 270px;
    height: 68px;
    margin-top: 30px; }
  .work-steps__wrapper .def-btn span {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase; } }

.hero.contexthero .hero__img {
  position: absolute;
  top: 20px;
  right: -6%;
  z-index: 1; }

.context-page .hero {
  height: 788px; }

.context-page .advantages .advantages__list {
  grid-gap: 60px; }

.context-page .advantages .advantages__list {
  grid-gap: 0;
  width: 1170px;
  margin-left: auto;
  margin-right: auto; }

.context-page .advantages .advantages__list .advantage:nth-child(3n+2) {
  margin-left: 60px; }

.context-page .advantages .advantages__list .advantage {
  display: flex;
  flex-direction: column;
  align-items: center; }

.context-page .advantages .advantage__content {
  align-self: center;
  text-align: center;
  margin-left: 0; }

.context-page .block-title {
  text-align: center; }

.context-page .site-types__list {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 80px; }

.context-page .site-type__img img {
  filter: grayscale(100);
  transition: .3s;
  border-radius: 50px 50px 0px 0px; }

.context-page .site-type__img {
  width: 100%;
  height: 343px;
  border-radius: 50px;
  filter: grayscale(0);
  transition: .3s;
  border-bottom: 2px solid var(--light-color);
  border-radius: 50px 50px 0px 0px; }

.context-page .site-type__item:hover img {
  filter: grayscale(0); }

.context-page .site-type__item {
  height: 703px; }

.context-page .site-types.wwd .site-types__list {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 50px; }

.context-page .site-types.wwd .site-type__img img {
  filter: grayscale(100);
  transition: .3s;
  border-radius: 50px 50px 0px 0px; }

.context-page .site-types.wwd .site-type__img {
  width: 100%;
  height: 278px;
  filter: grayscale(0);
  transition: .3s;
  border-radius: 50px 50px 0px 0px; }

.context-page .site-types.wwd .site-type__item:hover img {
  filter: grayscale(0); }

.context-page .site-types.wwd .site-type__item {
  height: 1015px; }

.context-page .site-type__title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 160%;
  color: var(--dark-color); }

.context-page .site-types.wwd .site-type__subtitle {
  margin-top: 20px; }

.read-more {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: var(--light-color);
  margin-top: 50px; }

.plans__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.plans__wrapper .block-title strong {
  color: var(--light-color); }

.plans__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 80px; }

.plan {
  width: 100%;
  height: 1095px;
  border: 2px solid #CE9967;
  border-radius: 40px;
  filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.02)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.02)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.01)); }

.plan__header {
  width: 100%;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #616161;
  border: 2px solid #616161;
  border-radius: 37px 37px 0px 0px;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #FFFFFF; }

.plan__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - 98px);
  padding-top: 30px;
  padding-left: 35px;
  padding-right: 24px;
  padding-bottom: 50px; }

.plan__content {
  width: 100%;
  max-height: 90%;
  overflow: hidden; }

.plan__content *,
.plan__content p {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  color: var(--dark-color) !important; }

.plan__content * strong,
.plan__content strong,
.plan__content p strong {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  color: #222730 !important; }

.plan__body .def-btn {
  margin-top: auto; }

.plan__content ul {
  width: 100%;
  list-style-type: none;
  padding: 0; }

.plan__content ul li {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px; }

.plan__content ul li:last-child {
  margin-bottom: 0; }

.plan__content ul li::before {
  min-width: 11px;
  max-width: 11px;
  min-height: 9px;
  max-height: 9px;
  display: block;
  content: '';
  background: url("../img/icons/mark2.svg") no-repeat;
  margin-right: 20px; }

.site-type__item {
  transition: .3s; }

.site-type__item:hover {
  margin-top: -20px; }

.tech-page .plan {
  min-height: 870px;
  max-height: 870px; }

.tech-page .plan__content ul li::before {
  min-width: 11px;
  max-width: 11px;
  min-height: 9px;
  max-height: 9px;
  display: block;
  content: '';
  background: url("../img/icons/mark4.svg") no-repeat;
  margin-right: 20px; }

.plan__header svg {
  transition: .3s; }

.plan:hover .plan__header svg,
.plan__header.active svg {
  transform: rotate(90deg); }

.wwfx {
  position: relative; }

.wwfx__wrapper {
  padding-top: 70px;
  padding-bottom: 210px;
  position: relative; }

.wwfx__body {
  width: 100%;
  margin-top: 70px;
  position: relative;
  border: 1px solid var(--light-color);
  border-radius: 20px;
  padding-top: 59px;
  padding-bottom: 120px;
  padding-left: 40px;
  padding-right: 40px; }

.wwfx__top-title {
  background: #F5F5F5;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: var(--dark-color);
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding-left: 20px;
  padding-right: 20px; }

.wwfx__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 45px;
  grid-column-gap: 105px; }

.wwfx__list li {
  height: fit-content;
  height: min-content;
  position: relative; }

.wwfx__list li,
.wwfx__list li *,
.wwfx__list li p {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.wwfx__list li * strong,
.wwfx__list li strong,
.wwfx__list li p strong {
  font-weight: 500 !important; }

.wwfx__list li::before {
  width: 6px;
  height: 6px;
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  background: var(--light-color);
  border-radius: 100%;
  margin-right: 10px; }

.wwfx__chess-desk {
  top: 0;
  right: 0; }

.wwfx__chess-small {
  top: 200px;
  left: 0; }

.wwfx__chess {
  left: -215px;
  bottom: 0; }

.faq {
  position: relative;
  background-size: cover !important; }

.faq__wrapper {
  height: 100%;
  padding-top: 80px;
  padding-bottom: 130px;
  position: relative; }

.faq__top-title {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 200%;
  color: var(--dark-color); }

.faq__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 90px;
  position: relative;
  margin-top: 55px; }

.faq__item {
  width: 100%;
  position: relative;
  margin-top: 30px; }

.faq__item:first-child {
  margin-top: 0; }

.faq__content-wrapper {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: .3s; }

.faq__item.active .faq__content-wrapper {
  max-height: 600px; }

.faq__content {
  width: 100%;
  padding-top: 20px; }

.faq__title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 30px; }

.faq__title-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--dark-color); }

.faq__item:hover .faq__title-name,
.faq__item.active .faq__title-name {
  color: var(--light-color); }

.faq__item:hover .faq__title svg *,
.faq__item.active .faq__title svg * {
  fill: var(--light-color); }

.faq__content *,
.faq__content p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.faq__content * strong,
.faq__content strong,
.faq__content p strong {
  font-weight: 500 !important; }

.faq__item .faq__arrow {
  transition: .3s; }

.faq__item:hover .faq__arrow,
.faq__item.active .faq__arrow {
  transform: rotate(90deg); }

@media (max-width: 980px) {
  .hero.contexthero .hero__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 50px; }
  .hero.contexthero .hero__img {
    width: 100%;
    height: 275px;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 1; }
  .hero.contexthero .hero__title {
    order: 0; }
  .hero.contexthero .hero__img {
    order: 1; }
  .hero.contexthero .hero__form {
    order: 2;
    padding-top: 35px; } }

@media (max-width: 980px) {
  .context-page .advantages.context-advantages .advantages__list .advantage {
    flex-direction: row; }
  .context-page .advantages.context-advantages .advantage__content {
    margin-left: 20px; }
  .context-page .advantages.context-advantages .advantage__description {
    margin-top: 0; }
  .context-page .advantages.context-advantages .advantage__description *, .context-page .advantages.context-advantages .advantage__description p {
    text-align: left !important; }
  .context-page .advantages.context-advantages .advantages__list {
    grid-gap: 20px; }
  .context-page .advantages .advantages__list {
    grid-gap: 20px;
    width: 100%; }
  .context-page .advantages .advantages__list .advantage {
    flex-direction: row;
    align-items: flex-start; }
  .context-page .advantages .advantages__list .advantage:nth-child(3n+2) {
    margin-left: 0; }
  .context-page .advantages .advantage__content {
    text-align: left;
    margin-left: 0;
    align-self: center;
    padding-left: 20px; }
  .context-page .advantages .advantages__list .advantage {
    margin-bottom: 0; }
  .context-page .advantages .advantages__list .advantage:last-child {
    margin-bottom: 0; } }

@media (max-width: 980px) {
  .context-page .site-types__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 40px; }
  .context-page .site-type__item {
    padding-bottom: 20px;
    border-bottom: 1px solid #c7c7c7;
    border-radius: 0;
    height: auto; }
  .context-page .site-type__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-transform: uppercase; }
  .context-page .site-types__wrapper {
    padding-bottom: 0;
    padding-top: 0; }
  .context-page .site-type__img {
    width: 100%;
    height: 185px;
    border: 1px solid #CE9967;
    border-radius: 20px;
    margin-bottom: 20px; }
  .context-page .m-p__description {
    margin-top: 0; }
  .context-page .site-type__img {
    width: 100%;
    height: 187px;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px !important; }
  .context-page .site-type__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px !important; }
  .plan:hover, .site-type__item:hover {
    margin-top: 0 !important; }
  .context-page .seowwfx .wwfx__list {
    grid-column-gap: 0;
    padding-left: 0; }
  .context-page .site-type__item {
    border-bottom: none;
    border-radius: 0;
    position: relative; }
  .context-page .site-type__item::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #C7C7C7;
    bottom: 3px;
    left: 0; } }

@media (max-width: 980px) {
  .plans__wrapper {
    padding-top: 0;
    padding-bottom: 40px; }
  .context-page .plans__wrapper .block-title {
    position: relative;
    padding-bottom: 10px; }
  .context-page .plans__wrapper .block-title::after {
    width: 272px;
    height: 2px;
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--light-color); }
  .plans__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 40px; }
  .plan {
    height: auto;
    border: none;
    padding: 0; }
  .plan__body {
    height: auto;
    padding: 0; }
  .plan__header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--dark-color);
    text-align: left; }
  .plan__body {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: .3s; }
  .plan__content {
    height: auto;
    overflow: visible;
    margin-top: 20px; }
  .plan {
    padding-bottom: 20px;
    border-bottom: 1px solid #C7C7C7;
    border-radius: 0; }
  .plan__content *, .plan__content p {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 140% !important; }
  .plan__content * strong, .plan__content p strong, .plan__content strong {
    font-size: 16px !important;
    line-height: 140% !important; }
  .plan__body .def-btn {
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 10px; }
  .tech-page .plan {
    min-height: auto;
    max-height: auto; }
  .plan {
    border-bottom: none;
    border-radius: 0;
    position: relative; }
  .plan::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #C7C7C7;
    bottom: 3px;
    left: 0;
    transform: translate3d(0, 0, 0); } }

@media (max-width: 1680px) {
  .wwfx__chess-small {
    top: 101px;
    left: -130px; }
  .wwfx__chess-desk {
    top: 0;
    right: -155px; }
  .wwfx__chess {
    left: -147px;
    bottom: 0; }
  .context-page .site-type__img {
    width: 100%;
    height: 250px; }
  .site-type__content {
    width: 100%;
    height: calc(100% - 250px); }
  .site-type__img {
    height: 250px; }
  .site-types__list {
    grid-template-columns: repeat(3, 1fr); }
  .connect::after {
    left: -258px; }
  .seo-page .wwfx__chess-small {
    top: 75px;
    left: -136px; }
  .seo-page .wwfx__chess-desk {
    top: 0;
    right: -84px; } }

@media (max-width: 980px) {
  .wwfx__wrapper {
    padding-top: 20px;
    padding-bottom: 50px;
    position: relative; }
  .positioned-abs {
    display: none; }
  .wwfx__body {
    width: 100%;
    margin-top: 30px;
    position: relative;
    border: 1px solid var(--light-color);
    border-radius: 17px;
    padding: 30px;
    padding-right: 25px; }
  .wwfx__top-title {
    font-size: 18px;
    line-height: 130%;
    position: absolute;
    top: -17px;
    left: 50%;
    padding-left: 10px;
    padding-right: 10px;
    width: 280px !important;
    text-align: center; }
  .wwfx__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0;
    margin-top: 20px; }
  .wwfx__list li {
    padding-left: 20px; }
  .wwfx__list li, .wwfx__list li *, .wwfx__list li p {
    font-size: 16px !important;
    line-height: 160% !important; }
  .wwfx__list li::before {
    left: 0; }
  .seo-page .seowwfx .wwfx__list {
    grid-column-gap: 0;
    padding-left: 0; }
  .seo-page .faq__wrapper {
    padding-bottom: 60px; } }

@media (max-width: 980px) {
  .faq__wrapper {
    padding-top: 0;
    padding-bottom: 10px; }
  .faq__top-title {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 200%;
    text-align: left; }
  .faq__list {
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0; }
  .faq__title {
    padding-bottom: 20px; }
  .divider {
    display: none; }
  .faq__item .faq__arrow {
    width: 18px;
    height: 10px; }
  .faq__title-name {
    font-size: 16px;
    margin-left: 0; }
  .faq__item {
    margin-top: 20px;
    padding-bottom: 0; }
  .faq__content {
    padding-top: 10px; }
  .faq__content *, .faq__content p {
    font-size: 14px !important; }
  .faq__column {
    margin-top: 20px; }
  .faq__column:first-child {
    margin-top: 0; }
  .faq__wrapper .def-btn {
    width: 100%;
    margin-top: 40px; }
  .faq__wrapper .def-btn::after {
    right: auto;
    top: 0;
    left: 0; } }

.about-page .hero {
  height: auto;
  padding-bottom: 170px; }

.hero.contexthero .hero__img {
  position: absolute;
  top: 20px;
  right: -6%;
  z-index: 1; }

.hero__subtitle {
  width: 100%;
  display: block;
  margin-top: 20px; }

.hero__subtitle,
.hero__subtitle *,
.hero__subtitle p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  color: var(--dark-color) !important; }

.hero__subtitle strong,
.hero__subtitle * strong,
.hero__subtitle p strong {
  font-weight: 500 !important; }

.started__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.started__wrapper .block-title {
  text-align: center; }

.started__stories {
  width: 100%;
  max-height: 663px;
  transition: .3s;
  overflow: hidden;
  margin-top: 60px;
  position: relative; }

.start-story {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px; }

.start-story__text {
  width: 100%;
  position: relative;
  transition: .3s; }

.start-story__text *,
.start-story__text p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: var(--dark-color) !important; }

.start-story__text * strong,
.start-story__text strong,
.start-story__text p strong {
  font-weight: 500 !important; }

.story-divider {
  margin-top: 40px; }

.started__stories:not(.active) .hiddable {
  background: linear-gradient(180deg, #595959 0%, rgba(89, 89, 89, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.whywe__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.whywe__wrapper .block-title {
  text-align: center; }

.whywe__wrapper .whywe__top-title {
  position: relative;
  margin-top: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: var(--dark-color); }

.whywe__block {
  width: 100%;
  position: relative;
  border-radius: 20px; }

.whywe__block-title {
  max-width: 100%;
  min-width: 740px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: var(--dark-color);
  z-index: 9;
  background: #F7F7F7;
  margin-top: 50px; }

.whywe__block-title strong {
  font-weight: 500;
  color: var(--light-color); }

.whywe__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  columns: 2;
  column-gap: 200px;
  margin-top: 60px; }

.whywe__list li {
  display: flex;
  align-items: center;
  margin-bottom: 35px; }

.whywe__list li .text,
.whywe__list li .text *,
.whywe__list li .text p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  color: var(--dark-color); }

.whywe__list li .text * strong,
.whywe__list li .text strong,
.whywe__list li .text p strong {
  font-weight: 500 !important; }

.whywe__list li .number {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 100%;
  background: linear-gradient(180deg, #CE9967 0%, rgba(255, 189, 128, 0.9) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-right: 30px; }

.suggest {
  position: relative; }

.suggest__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.suggest__decal-1 {
  position: absolute;
  top: 58px;
  left: 0; }

.suggest__wrapper .block-title {
  text-align: center; }

.what-we-suggest {
  width: 1030px;
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 40px;
  position: relative;
  margin-top: 80px; }

.ww-suggest__title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: var(--dark-color);
  transition: .3s; }

.ww-suggest__description {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark-color);
  margin-top: 10px;
  margin-bottom: 10px; }

.what-we-suggest .read-more {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--light-color);
  margin-top: 10px;
  text-transform: none; }

.what-we-suggest .read-more::after {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  background: var(--light-color);
  position: absolute;
  bottom: -2px;
  left: 0; }

.what-we-suggest li:hover .ww-suggest__title {
  color: var(--light-color); }

.about-reviews__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.about-reviews__wrapper .block-title {
  text-align: center; }

.abt-reviews {
  width: 100%;
  position: relative;
  min-height: 372px;
  transition: .3s;
  margin-top: 80px;
  overflow: hidden;
  border: 2px solid #CE9967;
  box-shadow: 0px 164px 66px rgba(222, 222, 222, 0.01), 0px 92px 55px rgba(222, 222, 222, 0.05), 0px 41px 41px rgba(222, 222, 222, 0.09), 0px 10px 23px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1);
  border-radius: 30px; }

.abt-reviews .swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-top: 80px;
  padding-bottom: 105px;
  padding-left: 60px;
  padding-right: 115px; }

.abt-reviews .swiper-slide .left-side {
  width: 187px;
  height: 187px;
  border-radius: 100%;
  background: none; }

.abt-reviews .swiper-slide .left-side img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: none;
  border-radius: 100%; }

.abt-reviews .swiper-slide .right-side {
  width: calc(calc(100% - 187px) - 58px);
  margin-left: 58px; }

.abt-reviews .review__title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 160%;
  color: var(--dark-color); }

.abt-reviews .review__description {
  width: 100%;
  max-height: 87px;
  overflow: hidden;
  transition: .3s;
  position: relative;
  margin-top: 10px; }

.abt-reviews .review__description,
.abt-reviews .review__description *,
.abt-reviews .review__description p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.abt-reviews .review__description strong,
.abt-reviews .review__description * strong,
.abt-reviews .review__description p strong {
  font-weight: 500 !important; }

.abt-reviews .seo-btn {
  margin-left: 0;
  margin-top: 30px; }

.abt-reviews .seo-btn span {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--light-color); }

.abt-reviews .seo-btn span::after {
  width: 100%;
  height: 1px;
  display: block;
  content: '';
  background: var(--light-color);
  position: absolute;
  bottom: -2px;
  left: 0; }

.abt-reviews .swiper-button-next, .abt-reviews .swiper-button-prev {
  position: relative;
  top: 0;
  margin-top: 0;
  left: 0;
  right: 0; }

.abt-reviews .swiper-button-prev {
  box-sizing: border-box;
  width: 100px;
  height: 70px;
  background: #F7F7F7;
  border: 2px solid #CE9967;
  border-radius: 0px 0px 30px 0px;
  transform: rotate(-180deg);
  border-top: 0;
  transition: .3s; }

.abt-reviews .swiper-button-next {
  width: 100px;
  height: 70px;
  border-radius: 0px 0px 30px 0px;
  border-top: 0;
  border: 2px solid #CE9967;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  transition: .3s; }

.abt-reviews .swiper-button-prev svg *,
.abt-reviews .swiper-button-next svg * {
  fill: var(--light-color); }

.abt-reviews .swiper-button-prev svg *,
.abt-reviews .swiper-button-next svg * {
  transition: .3s; }

.abt-reviews .swiper-button-prev:hover svg *,
.abt-reviews .swiper-button-next:hover svg * {
  fill: #fff; }

.abt-reviews .swiper-button-prev:hover,
.abt-reviews .swiper-button-next:hover {
  background: var(--light-color); }

.abt-reviews .swiper-button-prev svg {
  transform: scale(-1); }

.qualy__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.qualy__wrapper .block-title {
  text-align: center; }

.qualy__list {
  width: 100%;
  max-height: 490px;
  transition: .3s;
  overflow: hidden;
  list-style-type: none;
  padding: 0;
  margin-top: 80px; }

.qualy__list li {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px; }

.qualy__list li:not(.active).hiddable .qualy__text {
  background: linear-gradient(180deg, #595959 0%, rgba(89, 89, 89, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.qualy__list li:last-child {
  margin-bottom: 0; }

.qualy__list li .left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }

.qualy__list li .right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; }

.qualy__number {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 100%;
  background: linear-gradient(180deg, #CE9967 0%, rgba(255, 189, 128, 0.9) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.qualy__text {
  width: 100%;
  margin-top: 10px;
  text-align: center !important; }

.qualy__text *,
.qualy__text,
.qualy__text p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 140% !important;
  color: var(--dark-color); }

.qualy__text * strong,
.qualy__text strong,
.qualy__text p strong {
  font-weight: 500 !important; }

.view-img {
  display: flex;
  align-items: center;
  position: relative; }

.view-img span {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: var(--light-color); }

.view-img span::after {
  width: 100%;
  height: 1px;
  display: block;
  content: '';
  background: var(--light-color);
  position: absolute;
  bottom: 0px;
  left: 0; }

.view-img svg {
  margin-left: 13px;
  transition: .3s; }

.view-img:hover svg {
  transform: rotate(90deg); }

@media (max-width: 1680px) {
  .hero.contexthero .hero__img {
    position: absolute;
    top: 0px;
    right: 0%;
    z-index: 1; } }

@media (max-width: 980px) {
  .about-page .hero__title {
    order: 0; }
  .about-page .hero__wrapper, .about-page .hero {
    padding-bottom: 0 !important; }
  .about-page .hero.contexthero .hero__img {
    order: 1; }
  .about-page .hero__subtitle {
    order: 2; }
  .about-page .hero.contexthero .hero__img {
    width: 100%;
    height: auto;
    margin-top: 20px;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 1; }
  .about-page .hero__subtitle, .about-page .hero__subtitle *, .about-page .hero__subtitle p {
    font-size: 16px !important;
    line-height: 160% !important; } }

@media (max-width: 980px) {
  .start-story {
    margin-bottom: 30px; }
  .start-story__text *, .start-story__text p {
    font-size: 21px !important;
    text-align: left !important; }
  .story-divider {
    margin-top: 20px;
    align-self: flex-start; }
  .started__wrapper {
    padding-bottom: 60px; } }

@media (max-width: 980px) {
  .about-page .whywe__wrapper {
    padding-top: 40px;
    padding-bottom: 50px; }
  .about-page .whywe__top-title {
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 130%;
    text-align: left; }
  .whywe__block-title {
    max-width: 100%;
    min-width: 100%;
    font-size: 21px;
    text-align: left;
    line-height: 130%;
    margin-top: 30px; }
  .whywe__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    columns: 1;
    column-gap: 0;
    margin-top: 30px; }
  .whywe__list li {
    margin-bottom: 30px; }
  .whywe__list li .number {
    font-size: 35px;
    margin-right: 10px; }
  .whywe__list li .text, .whywe__list li .text *, .whywe__list li .text p {
    font-size: 21px !important; } }

@media (max-width: 1680px) {
  .suggest__decal-1 {
    position: absolute;
    top: 58px;
    left: -99px; } }

@media (max-width: 980px) {
  .suggest__wrapper {
    padding-top: 40px;
    padding-bottom: 80px;
    position: relative; }
  .suggest__wrapper .block-title {
    text-align: left; }
  .what-we-suggest {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 0;
    position: relative;
    margin-top: 40px; }
  .suggest__decal-1 {
    position: absolute;
    top: 38px;
    left: auto;
    right: 0; }
  .ww-suggest__title {
    font-size: 19px; }
  .ww-suggest__description {
    font-size: 16px; }
  .what-we-suggest .read-more {
    font-size: 16px; } }

@media (max-width: 980px) {
  .about-reviews__wrapper {
    padding-top: 40px;
    padding-bottom: 60px;
    position: relative; }
  .abt-reviews {
    margin-top: 40px;
    border-radius: 26px; }
  .abt-reviews .swiper-slide {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 40px;
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 22px; }
  .abt-reviews .swiper-slide .left-side {
    width: 124px;
    height: 124px;
    border-radius: 100%;
    background: 0 0; }
  .abt-reviews .swiper-slide .right-side {
    width: 100%;
    margin-left: 0;
    margin-top: 20px; }
  .abt-reviews .review__title {
    font-size: 19px;
    text-align: center; }
  .abt-reviews .review__description, .abt-reviews .review__description *, .abt-reviews .review__description p {
    font-size: 16px !important; }
  .abt-reviews .review__description {
    max-height: 156px; }
  .abt-reviews .seo-btn span {
    font-size: 16px; }
  .abt-reviews .swiper-button-prev,
  .abt-reviews .swiper-button-next {
    height: 62px; } }

@media (max-width: 980px) {
  .qualy__wrapper {
    padding-top: 70px;
    padding-bottom: 50px;
    position: relative; }
  .qualy__list {
    width: 100%;
    max-height: 150px;
    -webkit-transition: .3s;
    transition: .3s;
    overflow: hidden;
    list-style-type: none;
    padding: 0;
    margin-top: 40px; }
  .qualy__number {
    font-size: 35px; }
  .qualy__text, .qualy__text *, .qualy__text p {
    font-size: 18px !important; } }

.hero.contexthero .hero__img {
  position: absolute;
  top: 20px;
  right: -6%;
  z-index: 1; }

.seo-page .hero.contexthero .hero__img {
  position: absolute;
  top: 105px;
  right: -6%;
  z-index: 1; }

.wwfx.seowwfx .wwfx__top-title {
  width: max-content; }

.seo-page .hero {
  min-height: 780px; }

.seo-page .hero__wrapper {
  padding-bottom: 0; }

.seo-advantages .advantage {
  align-items: flex-start; }

.seo-advantages .advantages__list {
  grid-template-columns: 38% auto auto; }

.seo-page .advantages__list .advantage__content {
  align-self: flex-start; }

.wwg__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.wwg__wrapper .block-title {
  text-align: center; }

.wwg__list {
  width: 1130px;
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 65px; }

.wwg__list li {
  width: 100%;
  height: 410px;
  position: relative;
  background: #F2F2F2;
  box-shadow: -41px 162px 67px rgba(0, 0, 0, 0.01), -23px 91px 56px rgba(0, 0, 0, 0.05), -10px 40px 42px rgba(0, 0, 0, 0.09), -3px 10px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  transition: .3s;
  padding: 30px;
  padding-bottom: 40px; }

.wwg__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; }

.wwg__title,
.wwg__description {
  position: relative;
  z-index: 3; }

.wwg__title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: var(--dark-color);
  transition: .3s; }

.wwg__description {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: .3s; }

.wwg__description *,
.wwg__description p,
.wwg__description {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  color: #FFFFFF !important; }

.wwg__list li:hover .wwg__description {
  max-height: 70%; }

.wwg__list li:hover .wwg__title {
  color: #fff;
  margin-bottom: 10px; }

.wwg__list li::after {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(89, 89, 89, 0.7);
  border-radius: 20px;
  z-index: 2;
  transition: height .3s; }

.wwg__list li:hover::after {
  height: 100%; }

.seowwfx .block-title {
  text-align: center; }

.seowwfx .wwfx__list {
  grid-column-gap: 85px;
  padding-left: 75px; }

.pockets__wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative; }

.pockets__wrapper .block-title strong {
  color: var(--light-color); }

.pockets__header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  margin-top: 20px; }

.pockets__header .left-side {
  width: 58%; }

.pockets__header .right-side {
  width: 32%;
  display: flex;
  align-items: flex-end; }

.pockets__header-title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  color: var(--dark-color); }

.pockets__includes {
  width: 100%;
  list-style-type: disc;
  padding: 0;
  padding-left: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 53px;
  grid-row-gap: 5px;
  margin-top: 30px; }

.pockets__includes li {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  color: var(--dark-color); }

.what-is-it {
  width: 100%;
  position: relative; }

.what-is-it span {
  width: 303px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: var(--light-color);
  text-decoration: underline;
  text-decoration-color: var(--light-color);
  text-decoration-thickness: 1px; }

.what-is-it__content-wrapper {
  width: 435px;
  height: 0;
  transition: height .3s;
  overflow: hidden;
  position: absolute;
  top: 150%;
  left: 0;
  z-index: 9; }

.what-is-it::after {
  width: 100%;
  height: 40px;
  display: block;
  content: '';
  position: absolute;
  top: 56px;
  left: 0; }

.what-is-it__content {
  width: 100%;
  background: #F7F7F7;
  border: 2px solid #CE9967;
  box-shadow: 0px 164px 66px rgba(0, 0, 0, 0.01), 0px 92px 55px rgba(0, 0, 0, 0.01), 0px 41px 41px rgba(0, 0, 0, 0.02), 0px 10px 23px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  padding: 30px;
  padding-right: 35px;
  padding-bottom: 60px; }

.what-is-it:hover .what-is-it__content-wrapper {
  height: 100vh;
  height: max-content; }

.what-is-it__content,
.what-is-it__content *,
.what-is-it__content p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 220% !important;
  color: var(--dark-color) !important; }

.what-is-it__content ul {
  list-style-type: disc;
  padding: 0;
  padding-left: 20px; }

.pockets__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  list-style-type: none;
  padding: 0;
  margin-top: 85px; }

.pockets__list li {
  width: 100%;
  height: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  transition: .3s;
  background: #F7F7F7;
  border: 2px solid #CE9967;
  border-radius: 30px;
  padding-top: 40px;
  padding-bottom: 0; }

.pocket__title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: var(--dark-color); }

.pocket__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--dark-color);
  margin-top: 2px; }

.pocket__body {
  margin-top: 75px; }

.pocket__description {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--dark-color); }

.pocket__footer {
  width: 100%;
  box-sizing: border-box;
  height: 87px;
  border: none;
  padding: 0;
  border-top: 2px solid var(--light-color);
  border-radius: 0px 0px 27px 27px;
  margin-top: auto; }

@media (max-width: 980px) {
  .hero__form button {
    width: 217px; }
  .seo-page .seo-advantages .advantages__list {
    grid-template-columns: repeat(1, 1fr); }
  .seo-page .hero__form button {
    width: 260px; } }

@media (max-width: 980px) {
  .seo-page .advantage__content {
    margin-left: 20px; } }

@media (max-width: 980px) {
  .wwg__wrapper {
    padding-top: 40px;
    padding-bottom: 60px;
    position: relative; }
  .wwg__list {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin-top: 40px; }
  .wwg__list li {
    width: 91.05%;
    height: 365px;
    padding-bottom: 35px;
    padding-right: 20px;
    padding-left: 25px;
    padding-top: 25px;
    margin-left: auto;
    margin-right: auto; }
  .wwg__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%; }
  .wwg__description, .wwg__description *, .wwg__description p {
    font-size: 16px !important; }
  .seo-page .wwfx__top-title {
    width: 280px !important; } }

@media (max-width: 980px) {
  .pockets__wrapper {
    padding-top: 40px;
    padding-bottom: 50px;
    position: relative; }
  .pockets__wrapper .block-title {
    text-align: left; }
  .pockets__header {
    flex-direction: column; }
  .pockets__header-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%; }
  .pockets__header .left-side,
  .pockets__header .right-side {
    width: 100%; }
  .pockets__includes {
    width: 100%;
    list-style-type: disc;
    padding: 0;
    padding-left: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    margin-top: 20px; }
  .pockets__includes li {
    font-size: 16px; }
  .pockets__header .right-side {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 130%;
    margin-top: 50px;
    text-align: center; }
  .pockets__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    list-style-type: none;
    padding: 0;
    margin-top: 40px; }
  .pockets__list li {
    width: 100%;
    height: 260px;
    border-radius: 26px;
    padding-top: 30px;
    padding-bottom: 0; }
  .pocket__title {
    font-weight: 400;
    font-size: 21px;
    line-height: 130%; }
  .pocket__subtitle {
    font-size: 16px; }
  .pocket__body {
    margin-top: 40px; }
  .pocket__footer {
    width: 100%;
    height: 77px;
    font-size: 16px; }
  .what-is-it__content-wrapper {
    width: 100%; }
  .what-is-it__content, .what-is-it__content *, .what-is-it__content p {
    font-size: 16px !important;
    line-height: 140% !important; }
  .what-is-it__content ul li {
    text-align: left !important; } }

.portfolio__wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative; }

.tags {
  margin-top: 40px;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 10px; }

.tag {
  position: relative;
  transition: .3s;
  padding: 10px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 2px solid var(--dark-color);
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: var(--dark-color); }

.tag:hover,
.tag.active {
  background: var(--light-color);
  color: #fff;
  border-color: var(--light-color); }

.portfolio__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  position: relative;
  margin-top: 80px; }

.portoflio__item {
  width: 100%;
  height: 520px;
  padding: 40px;
  padding-bottom: 60px;
  padding-right: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: .3s;
  background: #F7F7F7;
  border-radius: 50px;
  margin-bottom: 20px;
  border-radius: 50px;
  border: 2px solid #F7F7F7;
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.08); }

.portoflio__item:last-child {
  margin-bottom: 0; }

.portoflio__item:hover {
  border-color: var(--light-color); }

.portfolio__left-side {
  width: 43%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%; }

.portfolio__right-side {
  width: 51%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.portfolio__right-side img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.portfolio__item-title *,
.portfolio__item-title p,
.portfolio__item-title {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 36px !important;
  line-height: 130% !important;
  color: var(--dark-color) !important; }

.portfolio__item-description {
  width: 100%;
  max-height: 116px;
  transition: .3s;
  overflow: hidden;
  margin-top: 20px; }

.portfolio__item-description *,
.portfolio__item-description p,
.portfolio__item-description {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.portoflio__item .def-btn {
  display: flex;
  border-width: 2px;
  align-items: center;
  justify-content: center;
  width: 303px;
  margin-top: auto; }

@media (max-width: 980px) {
  .portfolio__wrapper {
    padding-top: 40px;
    padding-bottom: 60px; }
  .portfolio__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    position: relative;
    margin-top: 60px; }
  .portoflio__item {
    width: 100%;
    height: auto;
    flex-direction: column;
    padding-top: 35px;
    padding-left: 30px;
    padding-bottom: 40px;
    padding-right: 19px;
    filter: none;
    transition: .3s;
    border: 1px solid var(--light-color);
    border-radius: 20px;
    transform: translateZ(0);
    box-shadow: none; }
  .portoflio__item:hover {
    box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid var(--light-color) !important; }
  .portfolio__item-title, .portfolio__item-title *, .portfolio__item-title p {
    font-size: 20px !important;
    line-height: 130% !important; }
  .portfolio__left-side {
    width: 100%; }
  .portfolio__item-description {
    max-height: 185px;
    margin-top: 30px;
    margin-bottom: 40px; }
  .portfolio__item-description, .portfolio__item-description *, .portfolio__item-description p {
    font-size: 16px !important; }
  .portimg {
    width: 100%;
    max-height: 180px;
    object-fit: contain;
    margin-top: 30px; }
  .portoflio__item .def-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 68px; } }

.blog__wrapper {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px; }

.blog__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 80px; }

.blog__item {
  width: 100%;
  height: 675px;
  background: #F7F7F7;
  border: 2px solid #CE9967;
  border-radius: 50px; }

.blog__item-header {
  width: 100%;
  height: 278px;
  display: block;
  border-radius: 50px 50px 0px 0px; }

.blog__item-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
  border-radius: 50px 50px 0px 0px; }

.blog__item-body {
  width: 100%;
  height: calc(100% - 278px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px;
  padding-top: 20px; }

.blog__item-neck .tags {
  margin-top: 0; }

.blog__item-neck .tags .tag {
  border: 2px solid #CE9967;
  border-radius: 10px;
  padding: 10px 38px;
  color: var(--light-color); }

.blog__item-neck .tags .tag:hover,
.blog__item-neck .tags .tag.active {
  color: #fff; }

.blog__item-neck {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.blog__item-neck time {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--dark-color); }

.blog__item-description {
  width: 100%;
  margin-top: 20px; }

.blog__item-title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 160%;
  color: var(--dark-color); }

.blog__item-text {
  width: 100%;
  margin-top: 10px; }

.blog__item-text,
.blog__item-text p,
.blog__item-text * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.blog__item-body .read-more {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 160%;
  text-transform: none;
  color: var(--light-color); }

.blog__item-body .read-more span {
  position: relative; }

.blog__item-body .read-more span::after {
  width: 100%;
  height: 1px;
  display: block;
  content: '';
  background: var(--light-color);
  position: absolute;
  bottom: 2px;
  left: 0; }

.single-news {
  position: relative; }

.single-news__banner {
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 40px;
  z-index: 3; }

.single-news__banner-img__wrapper {
  width: 100%;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.single-news__banner-img__wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.single-news__banner-img__wrapper::after {
  width: 100%;
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(90deg, #373634 18.88%, rgba(55, 54, 52, 0) 100%); }

.single-news__wrapper .crumbs ul li, .single-news__wrapper .crumbs ul li a {
  color: #fff !important; }

.single-news__wrapper .crumbs ul li::after {
  background: url(../img/icons/crumb-arroww.svg) no-repeat; }

.single-news__title {
  width: 100%;
  margin-top: 60px;
  position: relative;
  z-index: 3; }

.single-news__title,
.single-news__title *,
.single-news__title p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 60px !important;
  line-height: 130% !important;
  color: #FFFFFF !important; }

.single-news__title strong,
.single-news__title * strong,
.single-news__title p strong {
  font-weight: 800 !important; }

.single-news__info {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
  margin-top: auto; }

.single-news__info .tags {
  margin-top: 0; }

.single-news__info .tags .tag {
  border-color: #fff;
  color: #fff; }

.single-news__info .tags .tag:hover {
  cursor: default;
  background: none; }

.read-time {
  display: flex;
  align-items: center;
  margin-left: 40px; }

.read-time span {
  display: block;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #FFFFFF; }

.news-content {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }

.news__container {
  width: 1340px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-bottom: 80px;
  padding-top: 50px; }

.news-content__sidebar {
  width: 29.3%; }

.article__content {
  width: 100%;
  background: #F7F7F7;
  border-radius: 50px;
  padding: 40px;
  padding-bottom: 60px;
  padding-right: 8px;
  filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.02)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.02)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.01)); }

.article__title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 160%;
  color: var(--dark-color); }

.article__content-list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-top: 20px; }

.article__content-list li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: var(--dark-color);
  transition: .3s;
  cursor: pointer; }

.article__content-list li:hover {
  color: var(--light-color); }

.article__content-list li.active {
  font-weight: 800;
  color: var(--light-color); }

.sidebar__form {
  width: 100%;
  background: #F7F7F7;
  border-radius: 50px;
  position: relative;
  padding: 40px;
  padding-bottom: 50px;
  padding-right: 28px;
  margin-top: 20px;
  filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.02)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.02)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.01)); }

.sidebar__form-title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 160%;
  color: var(--dark-color);
  margin-bottom: 30px; }

.sidebar__form .def-input {
  width: 100%;
  height: 61px;
  border: 1px solid #595959;
  border-radius: 50px;
  margin-bottom: 10px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: var(--dark-color) !important; }

.sidebar__form .def-input::placeholder {
  color: #8D909B !important; }

.sidebar__form textarea.def-input {
  height: 147px;
  resize: none;
  margin-top: 10px;
  border-radius: 20px;
  padding-top: 20px; }

.sidebar__form .def-btn {
  width: 100%;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px; }

.news-content__description {
  width: calc(calc(100% - 29.3%) - 50px); }

.news-block-content {
  width: 100%;
  margin-top: 40px; }

.news-block-content:first-child {
  margin-top: 0; }

.news-block-content,
.news-block-content *,
.news-block-content p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 200% !important;
  color: var(--dark-color); }

.news-block-content .news-block__title,
.news-block-content * .news-block__title,
.news-block-content p .news-block__title {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 40px !important; }

.news-block-content ul,
.news-block-content * ul {
  list-style-type: none;
  padding: 0; }

.news-block-content ul li,
.news-block-content * ul li {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 20px; }

.news-block-content ul li:last-child,
.news-block-content * ul li:last-child {
  margin-bottom: 0; }

.news-block-content ul li::before,
.news-block-content * ul li::before {
  display: block;
  content: '';
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 50%;
  background: #CE9967;
  margin-right: 10px;
  margin-top: 10px; }

.news-block-content img {
  width: 100%;
  max-height: 448px;
  object-fit: contain; }

.article-rating {
  width: 100%;
  position: relative;
  margin-top: 40px; }

.article-rating__title {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 200%;
  color: #000000; }

.article-rating__wrapper {
  width: 100%;
  position: relative;
  display: flex !important;
  align-items: flex-start;
  grid-gap: 20px;
  margin-top: 20px; }

.article__rating-block {
  box-sizing: border-box;
  width: 140px;
  max-height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #CE9967;
  border-radius: 20px;
  transition: .3s;
  cursor: pointer; }

.article__rating-block:hover,
.article__rating-block.active {
  border-color: #FF7C00;
  background: var(--light-color);
  color: #fff !important; }

.article__rating-count {
  display: flex;
  align-items: center; }

.article__rating-count svg {
  margin-right: 5px; }

.article__rating-count .article__rating-number {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #000000; }

.article__rating-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #8D909B;
  margin-top: 5px; }

.article__rating-block.active .article__rating-description {
  font-weight: 800; }

.article__rating-block:hover .article__rating-description,
.article__rating-block.active .article__rating-description,
.article__rating-block:hover .article__rating-number,
.article__rating-block.active .article__rating-number {
  color: #fff; }

.xlike .xlike__link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; }

@media (max-width: 980px) {
  .blog__wrapper {
    position: relative;
    padding-top: 40px;
    padding-bottom: 80px; }
  .blog__wrapper .block-title,
  .portfolio-page .block-title {
    text-align: left; }
  .blog-page .tags,
  .portfolio-page .tags {
    margin-top: 20px;
    display: flex !important;
    flex-wrap: wrap; }
  .blog-page .tag,
  .portfolio-page .tag {
    width: auto;
    min-width: 96px;
    height: auto;
    min-height: 44px;
    padding: 9px 31px;
    border: 2px solid var(--dark-color);
    border-radius: 8px;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    color: var(--dark-color); }
  .blog-page .tag.active,
  .blog-page .tag:hover,
  .portfolio-page .tag.active,
  .portfolio-page .tag:hover {
    color: #fff !important;
    border-color: var(--light-color) !important; }
  .blog__list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin-top: 40px; }
  .blog__item {
    width: 100%;
    height: 665px;
    border-radius: 20px; }
  .blog__item-header {
    width: 100%;
    height: 247px;
    display: block;
    border-radius: 20px 20px 0 0; }
  .blog__item-header img {
    border-radius: 20px 20px 0 0; }
  .blog__item-body {
    height: calc(100% - 247px);
    padding: 30px;
    padding-top: 20px; }
  .blog__item-neck {
    flex-direction: column;
    align-items: flex-start; }
  .blog-page .tags {
    margin-top: 0; }
  .blog__item-neck .tags .tag {
    border: 2px solid #ce9967;
    border-radius: 8px;
    padding: 9px 33px;
    color: var(--light-color); }
  .blog__item-neck time {
    font-size: 14px;
    margin-top: 15px; }
  .blog__item-title {
    font-size: 18px;
    line-height: 140%; }
  .blog__item-text {
    max-height: 115px;
    overflow: hidden; }
  .blog__item-text, .blog__item-text *, .blog__item-text p {
    font-size: 14px !important; }
  .blog__item-body .read-more {
    font-size: 16px;
    margin-top: 30px; }
  #pdopage .blog__item-neck .tag.active, #pdopage .blog__item-neck .tag:hover {
    background: none;
    color: var(--light-color) !important; }
  .blog__item {
    height: auto; }
  .news-page .blog__list .tags {
    grid-auto-flow: row; }
  .news-page .blog__list .tags .tag {
    width: max-content; } }

@media (max-width: 980px) {
  .single-news__banner .container {
    padding-left: 0;
    padding-right: 0; }
  .single-news__banner {
    width: 100%;
    height: 478px;
    padding-bottom: 40px;
    z-index: 3; }
  .single-news__title, .single-news__title *, .single-news__title p {
    font-size: 28px !important; }
  .single-news__banner-img__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .single-news__banner-img__wrapper::after {
    background: linear-gradient(90deg, #373634 0%, rgba(55, 54, 52, 0) 289.33%); }
  .single-news__info {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 20px; }
  .read-time {
    margin-left: 0; }
  .read-time span {
    font-size: 17px; }
  .read-time svg {
    width: 14px;
    height: 11px; }
  .single-news__info .tags {
    margin-top: 20px; }
  .single-news__info .tags .tag {
    border-color: #fff;
    color: #fff; }
  .news__container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    padding-top: 0; }
  .news-content__sidebar {
    width: 100%; }
  .article__content {
    background: #F7F7F7;
    border-radius: 44px;
    padding-bottom: 50px; }
  .article__title {
    font-size: 23px; }
  .article__content-list {
    margin-top: 18px; }
  .article__content-list li {
    font-size: 14px; }
  .news-content__description {
    width: 100%;
    margin-top: 20px; }
  .sidebar__form {
    padding-left: 30px;
    padding-top: 45px;
    padding-bottom: 50px;
    padding-right: 30px;
    background: #F7F7F7;
    border: 1px solid var(--light-color);
    border-radius: 26px; }
  .sidebar__form-title {
    font-size: 24px;
    line-height: 130%; }
  .sidebar__form .def-input {
    width: 100%;
    height: 69px;
    border-radius: 17px; }
  .news-block-content, .news-block-content *, .news-block-content p {
    font-size: 16px !important; }
  .news-block-content .news-block__title,
  .news-block-content .news-block__title *,
  .news-block-content .news-block__title p {
    font-family: var(--font);
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 200% !important;
    color: var(--dark-color) !important; }
  .news-block-content * ul li::before, .news-block-content ul li::before {
    min-width: 9px;
    max-width: 9px;
    min-height: 9px;
    max-height: 9px; }
  .article-rating__title {
    font-size: 24px;
    line-height: 200%; }
  .blog__wrapper {
    position: relative;
    padding-top: 0;
    padding-bottom: 50px; }
  .blog__list-slider .blog__item {
    min-width: 335px; } }

.contacts__wrapper {
  padding-top: 60px !important;
  padding-bottom: 120px !important; }

.contacts__wrapper .block-title {
  font-weight: 400; }

.contacts__splitter {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px; }

.contacts__splitter .left-side {
  width: 705px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 40px;
  grid-column-gap: 40px; }

.contacts__splitter .right-side {
  width: 540px;
  height: 366px;
  border-radius: 30px; }

.contacts__splitter .right-side #map {
  width: 100%;
  height: 100%;
  border-radius: 30px; }

.contacts__column {
  width: 100%; }

.column__title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--dark-color); }

.column__content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  margin-top: 15px; }

.column__content a {
  width: 100%; }

.column__content a,
.column__content a span,
.column__content span {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--dark-color);
  transition: .3s; }

.column__content a span {
  margin-left: 20px; }

.column__content a:hover span {
  color: var(--light-color); }

.column__content ul.footer__socials {
  margin-top: 0; }

.column__content ul.footer__socials li {
  transition: .3s; }

.column__content ul.footer__socials li:hover {
  margin-top: -10px; }

@media (max-width: 980px) {
  .contacts__wrapper {
    padding-top: 40px !important;
    padding-bottom: 60px !important; }
  .contacts__wrapper .block-title {
    text-align: left; }
  .contacts__splitter {
    flex-direction: column;
    align-items: flex-start; }
  .contacts__splitter .left-side {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 0; }
  .contacts__splitter .right-side {
    width: 100%;
    height: 348px;
    border-radius: 26px;
    margin-top: 40px; }
  .column__title {
    font-size: 17px; }
  .column__content a, .column__content a span, .column__content span {
    font-size: 17px; }
  .column__content a svg {
    width: 23px;
    height: 23px; } }

.balagan {
  position: relative;
  min-height: 1182px;
  z-index: 4; }

.balagan-particle {
  position: absolute; }

.balagan__wrapper {
  padding-top: 100px;
  padding-bottom: 205px; }

.balagan__title,
.balagan__title *,
.balagan__title p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 40px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: var(--dark-color); }

.balagan__title strong,
.balagan__title * strong,
.balagan__title p strong {
  font-weight: 800 !important; }

.balagan__subtitle {
  margin-top: 20px; }

.balagan__subtitle,
.balagan__subtitle *,
.balagan__subtitle p {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  text-align: center !important;
  color: var(--dark-color) !important; }

.balagan__subtitle strong,
.balagan__subtitle * strong,
.balagan__subtitle p strong {
  font-weight: 800 !important; }

.balagan_cheses {
  display: none; }

.balagan__list {
  width: 100%;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 95px;
  grid-row-gap: 30px;
  border: 1px solid var(--light-color);
  padding-left: 0;
  padding-left: 40px;
  padding-top: 50px;
  padding-right: 40px;
  padding-bottom: 50px;
  border-radius: 18px; }

.balagan__block {
  width: 100%;
  margin-top: 40px;
  position: relative; }

.balagan__list-title {
  width: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  background: #F7F7F7;
  line-height: 130%;
  text-align: center;
  color: var(--dark-color);
  position: absolute;
  top: -12px;
  padding-left: 10px;
  padding-right: 10px;
  left: 50%;
  transform: translateX(-50%); }

.balagan__list li,
.balagan__list li p,
.balagan__list li * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  color: var(--dark-color); }

.balagan__list li strong,
.balagan__list li p strong,
.balagan__list li * strong {
  font-weight: 500 !important; }

.balagan__list li {
  position: relative;
  padding-left: 16px; }

.balagan__list li::before {
  width: 6px;
  height: 6px;
  display: block;
  content: '';
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  background: var(--light-color); }

.tech-page .hero {
  min-height: 788px; }

.tech-page .hero.contexthero .hero__img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; }

.balagan-particle__1 {
  top: 164px;
  left: 0; }

.balagan-particle__2 {
  top: -44px;
  right: 0; }

.balagan-particle__3 {
  bottom: -30%;
  left: -21%; }

.tech-page .plans__wrapper .block-title {
  text-align: center; }

.tech-page .plans__wrapper .block-title strong {
  font-weight: 800;
  color: var(--dark-color); }

.tech-page .plans__wrapper {
  width: 1720px; }

.tech-page .plans__list {
  grid-template-columns: repeat(4, 1fr); }

@media (max-width: 1680px) {
  .balagan-particle__1 {
    top: 50px;
    left: -59px; }
  .balagan-particle__2 {
    top: 13px;
    right: -191px;
    z-index: 2; }
  .balagan-particle__3 {
    bottom: -59%;
    left: -12%; }
  .tech-page .plans__list {
    grid-template-columns: repeat(3, 1fr); }
  .tech-page .plans__wrapper {
    width: 1280px; } }

@media (max-width: 980px) {
  .tech-page .hero__subtitle, .tech-page .hero__subtitle *, .tech-page .hero__subtitle p {
    font-size: 16px !important; }
  .tech-page .hero__subtitle {
    margin-top: 20px; }
  .tech-page .hero .hero__img {
    width: 235px;
    height: 288px;
    object-fit: contain;
    position: absolute !important;
    right: -50px !important;
    top: auto !important;
    bottom: 40px !important; }
  .tech-page .hero__form button {
    width: 217px; }
  .tech-page .hero__wrapper {
    padding-bottom: 160px !important; }
  .tech-page .advantages.context-advantages .advantages__list .advantage {
    flex-direction: row; }
  .tech-page .advantage__description {
    margin-top: 0;
    margin-left: 20px;
    text-align: left; }
  .tech-page .advantages__list {
    grid-gap: 20px !important;
    margin-top: 40px; }
  .balagan_bg {
    display: none; }
  .balagan__wrapper {
    padding-bottom: 60px;
    padding-top: 0; }
  .balagan__title,
  .balagan__title *,
  .balagan__title p {
    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 130% !important;
    text-align: center !important;
    color: var(--dark-color); }
  .balagan__title strong,
  .balagan__title * strong,
  .balagan__title p strong {
    font-weight: 800 !important; }
  .balagan__subtitle {
    margin-top: 20px; }
  .balagan__subtitle,
  .balagan__subtitle *,
  .balagan__subtitle p {
    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 160% !important;
    text-align: center !important;
    color: var(--dark-color) !important; }
  .balagan__subtitle strong,
  .balagan__subtitle * strong,
  .balagan__subtitle p strong {
    font-weight: 800 !important; }
  .balagan_cheses {
    display: none; }
  .balagan__list {
    width: 100%;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    border: 1px solid var(--light-color);
    padding-left: 0;
    padding-left: 30px;
    padding-top: 50px;
    padding-right: 35px;
    padding-bottom: 50px;
    border-radius: 18px; }
  .balagan__block {
    width: 100%;
    margin-top: 40px;
    position: relative; }
  .balagan__list-title {
    width: 220px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    background: #F7F7F7;
    line-height: 130%;
    text-align: center;
    color: var(--dark-color);
    position: absolute;
    top: -22px;
    padding-left: 10px;
    padding-right: 10px;
    left: 50%;
    transform: translateX(-50%); }
  .balagan__list li,
  .balagan__list li p,
  .balagan__list li * {
    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 140% !important;
    color: var(--dark-color); }
  .balagan__list li strong,
  .balagan__list li p strong,
  .balagan__list li * strong {
    font-weight: 500 !important; }
  .balagan__list li {
    position: relative;
    padding-left: 16px; }
  .balagan__list li::before {
    width: 6px;
    height: 6px;
    display: block;
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    background: var(--light-color); }
  .balagan-particle {
    display: none; }
  .tech-page .hero .hero__form {
    padding-top: 65px; }
  .tech-page .advantage__description {
    margin-left: 0; }
  .tech-page .advantage__content {
    margin-left: 10px; }
  .tech-page .plans__list {
    grid-template-columns: repeat(1, 1fr); }
  .tech-page .plans__wrapper {
    width: 100%; } }

.p404__wrapper {
  padding-top: 180px;
  padding-bottom: 410px;
  position: relative; }

.p404__title {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 130%;
  color: var(--dark-color); }

.p404__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: var(--dark-color);
  margin-top: 40px; }

.p404-bg {
  position: absolute;
  right: -40px;
  bottom: 120px; }

.p404btn {
  width: 340px;
  height: 77px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px; }

@media (max-width: 980px) {
  .p404__wrapper {
    padding-top: 40px;
    padding-bottom: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .p404__title {
    font-size: 24px;
    order: 0; }
  .p404__subtitle {
    font-size: 17px;
    margin-top: 20px;
    order: 1; }
  .p404btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 140%;
    width: 300px;
    height: 68px;
    order: 3;
    margin-left: auto;
    margin-right: auto; }
  .p404-bg {
    width: 247px;
    height: 171px;
    object-fit: contain;
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 35px;
    order: 2; } }

.case__wrapper {
  padding-top: 80px;
  padding-bottom: 120px; }

.case__banner {
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50px; }

.case__banner img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 50px; }

.case__banner::after {
  width: 100%;
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(0deg, rgba(55, 54, 52, 0.9) 62.31%, rgba(55, 54, 52, 0) 100%);
  border-radius: 50px; }

.case__banner-title {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  z-index: 3; }

.case__first-shot {
  max-width: 100%;
  filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.05)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.09)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
  border-radius: 50px;
  margin-top: 180px;
  margin-bottom: 190px; }

.case__content-block {
  width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px; }

.case__content-block,
.case__content-block p,
.case__content-block * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.case__content-block h3,
.case__content-block p h3,
.case__content-block * h3 {
  font-family: var(--font) !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 200% !important;
  text-align: center !important;
  color: var(--dark-color) !important; }

.case__steps {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 236px;
  grid-row-gap: 40px; }

.case__step {
  width: 100%; }

.case__step-title {
  font-family: var(--font) !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 200% !important;
  text-align: left !important;
  color: var(--dark-color) !important; }

.case__step-description,
.case__step-description p,
.case__step-description * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 160% !important;
  color: var(--dark-color) !important; }

.case__pages {
  width: 1080px;
  margin-left: auto;
  margin-right: auto;
  line-height: 0;
  column-count: 2;
  column-gap: 58px;
  position: relative;
  margin-top: 230px;
  margin-bottom: 230px; }

.case__pages img {
  max-width: 510px;
  filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.05)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.09)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
  border-radius: 20px; }

.case__pages img:nth-child(2n+2) {
  margin-top: 80px; }

.case__pages img:nth-child(2n+1) {
  margin-top: 80px; }

.case__pages img:first-child {
  margin-top: 0; }

.case-page .blog__wrapper {
  padding-bottom: 150px; }

.case-page .blog__wrapper .block-title {
  text-align: center; }

.particle__item {
  position: absolute; }

.footer {
  background: #373634;
  position: relative; }

.footer__wrapper {
  display: flex;
  align-items: flex-start;
  padding-top: 45px;
  padding-bottom: 180px;
  position: relative; }

.footer-column {
  padding-top: 35px; }

.footer-column:first-child {
  padding-top: 0; }

.footer-phones, .footer-emails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px; }

.footer-emails {
  margin-top: 36px; }

.footer-phones a,
.footer-emails a {
  display: flex;
  align-items: center; }

.footer-phones a .footer-contacts__icon,
.footer-emails a .footer-contacts__icon {
  width: 33px;
  display: flex;
  align-items: center; }

.footer-phones a .link-content,
.footer-emails a .link-content {
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF;
  transition: .3s; }

.footer-phones a svg,
.footer-emails a svg {
  max-width: 100%;
  transition: .3s; }

.footer-phones a svg *,
.footer-emails a svg * {
  transition: .3s; }

.footer-phones a:hover .footer-contacts__icon svg *,
.footer-emails a:hover .footer-contacts__icon svg * {
  fill: var(--light-color); }

.footer-phones a:hover .link-content,
.footer-emails a:hover .link-content {
  color: var(--light-color); }

.copyrights {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #FFFFFF;
  margin-top: 36px;
  transition: .3s;
  cursor: pointer; }

.copyrights:hover {
  color: var(--light-color); }

.privacy-policy {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-decoration-line: underline;
  text-decoration-color: var(--light-color);
  text-decoration-thickness: 1px;
  color: var(--light-color); }

.footer-column.second-column {
  margin-left: 72px; }

.footer-column__title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF; }

.footer__list {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin-top: 20px; }

.footer__list li {
  margin-bottom: 10px; }

.footer__list li:last-child {
  margin-bottom: 0; }

.footer__list li,
.footer__list li a {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  color: #FFFFFF;
  transition: .3s; }

.footer__list li:hover a {
  color: var(--light-color); }

.footer__list li.active {
  font-weight: 900;
  color: var(--light-color); }

.footer-column.third-column {
  margin-left: 215px; }

.footer-column.last-column {
  margin-left: auto; }

.footer__socials {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin-top: 20px; }

.footer__socials li {
  margin-right: 30px; }

.footer__socials li a svg * {
  transition: .3s; }

.footer__socials li:hover a svg path,
.footer__socials li:hover a svg * {
  fill: var(--light-color) !important; }

@media (max-width: 980px) {
  .footer__wrapper {
    padding-top: 30px;
    padding-bottom: 60px;
    flex-direction: column; }
  .footer-column {
    width: 100%; }
  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center; }
  .columns__connector {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .columns__connector .footer-column {
    margin-left: 0; }
  .columns__connector .footer-column.third-column {
    width: 55%;
    padding-top: 0; }
  .footer-column.second-column {
    width: 95px; }
  .footer-column__title {
    font-size: 16px; }
  .footer__list li, .footer__list li a {
    font-weight: 400;
    font-size: 16px;
    line-height: 180%; }
  .footer-column.full-last {
    padding-top: 30px; }
  .footer-column.full-last .footer-phones {
    margin-top: 0; }
  .footer-emails a .link-content, .footer-phones a .link-content {
    font-size: 17px; }
  .footer-emails a .footer-contacts__icon, .footer-phones a .footer-contacts__icon {
    width: 22px; }
  .footer-emails a .footer-contacts__icon {
    width: 31px; }
  .footer-emails a .link-content {
    margin-left: 13px; }
  .footer-emails {
    margin-top: 30px; }
  .copyrights {
    font-size: 12px;
    line-height: 160%; }
  .privacy-policy {
    font-size: 12px;
    line-height: 160%; } }

.modal-window {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 375px;
  height: auto;
  background: #F7F7F7;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  border: 2px solid var(--light-color); }

.new-modal__window {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none !important;
  width: 1280px;
  height: auto;
  border-radius: 30px;
  padding: 0 !important; }

.new-modal__window-wrapper {
  width: 100%;
  height: 100%;
  background: #F7F7F7;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 30px;
  border: 2px solid #CE9967; }

.new-modal__form {
  width: 100%;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 80px !important;
  padding-right: 80px !important;
  border-radius: 30px; }

.new-modal__input-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 30px; }

.modal-window.fancybox-content {
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 87px; }

.succesess-window.fancybox-content {
  padding: 40px; }

.modal-window .def-btn {
  margin-top: 20px; }

.close-btn, .close-menu {
  border: none;
  background: none;
  outline: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px; }

.close-btn svg, .close-menu svg {
  width: 100%;
  height: 100%; }

.new-modal__window .close-btn {
  top: 30px;
  right: 40px; }

.modal__header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.modal-title,
.modal-title p,
.modal-title * {
  font-family: var(--font) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 20px !important;
  line-height: 130% !important;
  color: var(--dark-color);
  text-align: left !important; }

.modal__form-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px; }

.modal__form-services label {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #595959;
  border-radius: 50px;
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer; }

.modal__form-services input {
  display: none; }

.modal__form-services input:checked + label {
  border-color: var(--light-color);
  background: var(--light-color);
  color: #fff; }

.modal__form-services input:checked + label span {
  color: #fff; }

.modal-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  text-align: center; }

.modal-form label > span {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--dark-color); }

.def-input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 77px;
  border: 1px solid var(--dark-color);
  color: var(--light-color);
  padding-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: var(--dark-color);
  border-radius: 20px;
  transition: .3s; }

.complex__list {
  width: 66.334%;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--dark-color);
  color: var(--light-color);
  padding-left: 30px;
  padding-right: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: var(--dark-color);
  border-radius: 20px;
  transition: .3s;
  position: relative;
  cursor: pointer; }

.complex__list.choosed {
  border: 1px solid #CE9967; }

.complex__list.choosed .complex__list-name {
  color: var(--light-color); }

.complex__list-items-wrapper {
  width: 703px;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 83%;
  left: 8.6%;
  transition: .3s;
  z-index: 99;
  box-shadow: 0px 164px 66px rgba(0, 0, 0, 0.01), 0px 92px 55px rgba(0, 0, 0, 0.01), 0px 41px 41px rgba(0, 0, 0, 0.02), 0px 10px 23px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.01);
  border-radius: 20px; }

.complex__list-items-wrapper.active {
  height: 265px; }

.complex__list-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: .3s;
  background: #F7F7F7;
  border: 1px solid #CE9967;
  border-radius: 20px;
  padding: 30px;
  padding-bottom: 50px; }

.complex__list-item input {
  display: none; }

.complex__list:hover {
  border-color: var(--light-color); }

.complex__list-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer; }

.complex__list-item:last-child {
  margin-bottom: 0; }

.complex__list-item {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #595959; }

.complex__list-item.active,
.complex__list-item:hover {
  color: var(--light-color); }

.complex__list-item.active {
  font-weight: 500; }

.modal-window .def-input {
  height: 46px;
  border-radius: 10px; }

.def-input::placeholder {
  font-size: 16px;
  text-align: left; }

.def-input:active,
.def-input:focus,
.def-input:hover {
  border-color: var(--light-color); }

.modal-form textarea {
  padding-top: 12px;
  height: 170px;
  resize: none; }

.modal__input-combiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px; }

.modal__input-combiner .def-input {
  width: 48%; }

.submit-btn {
  width: 246px;
  height: 50px;
  background: #5284F6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF; }

.modal-form .custom-checkbox {
  flex-direction: row;
  align-items: center; }

.modal-form .custom-checkbox .box svg {
  display: none; }

.modal-form .custom-checkbox input:checked + .box svg {
  display: block; }

.modal-form .custom-checkbox .box {
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
  border: 1px solid var(--bg-color); }

.modal-form .custom-checkbox .checkbox-desc {
  color: var(--bg-color);
  font-family: var(--def-font);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18px;
  text-align: left; }

.modal-form .custom-checkbox .checkbox-desc a {
  color: var(--or);
  font-family: var(--def-font);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-decoration: underline;
  text-decoration-thicknes: 1px;
  text-decoration-color: var(--or); }

.modal-form .def-btn {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center; }

.privacy-modal {
  width: 100%;
  text-align: center;
  margin-top: 30px; }

.privacy-modal,
.privacy-modal p,
.privacy-modal p* {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 140% !important;
  text-align: center !important;
  color: var(--dark-color) !important; }

.privacy-modal strong {
  font-weight: 500 !important;
  color: var(--light-color) !important; }

.jGrowl {
  z-index: 9999999 !important; }

.custom-error, .error {
  border: 1px solid red !important; }

.fancybox-content {
  position: absolute !important;
  padding: 0; }

.error_g-recaptcha-response {
  display: none !important; }

.inner-text {
  width: 100%;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%); }

.modal__input-combiner {
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px; }

.modal__input-combiner .def-input {
  width: 100%;
  height: 55px;
  border-radius: 10px; }

.modal-form .def-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  padding: 0;
  margin-top: 50px;
  border-radius: 10px; }

#inner-window {
  width: 410px;
  height: auto;
  max-height: calc(100% - 40px);
  border-radius: 10px; }

.modal__form-services label {
  border-radius: 10px; }

@media (max-width: 980px) {
  .complex__list-items-wrapper {
    width: 103%;
    top: 58%;
    left: 50%;
    transform: translateX(-50%); }
  .complex__list {
    width: 100%; }
  .simple-form__form.complex-form .swiper-slide .block-subtitle {
    text-align: center; }
  .modal-window {
    width: calc(100%  - 40px);
    height: auto;
    max-height: calc(100% - 40px);
    border-radius: 10px; }
  #inner-window {
    width: calc(100%  - 40px);
    height: auto;
    max-height: calc(100% - 40px);
    border-radius: 10px; }
  .close-btn, .close-menu {
    border: none;
    background: none;
    outline: none;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px; } }

.invoice_of_payment__wrapper {
  min-height: calc(100vh - 748px);
  padding-bottom: 60px; }

.invoice_of_payment__title {
  text-align: center;
  margin-top: 40px;
  font-weight: 700;
  font-size: 70px; }

.invoice-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.half-way {
  width: 49.3% !important;
  max-width: 49.3% !important; }

.w100 {
  width: 100% !important;
  max-width: 100% !important; }

.invoice-form .def-input {
  margin-bottom: 20px;
  border-radius: 4px; }

.invoice-form .def-input::placeholder {
  font-size: 18px; }

.invoice-table__wrapper {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid var(--dark-color);
  padding: 20px;
  border-radius: 4px; }

.invoice-table__title {
  font-family: var(--font);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: var(--dark-color);
  -webkit-transition: .3s;
  transition: .3s;
  margin-bottom: 20px; }

table.invoice-table {
  width: 100% !important;
  background: transparent;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #595959;
  border-style: solid;
  color: #000000; }

table.invoice-table td, table.invoice-table th {
  font-size: 18px;
  border-width: 1px;
  border-color: #595959;
  border-style: solid;
  padding: 15px;
  color: var(--dark-color);
  transition: .3s; }

table.invoice-table tr {
  border: 1px solid var(--dark-color);
  transition: .3s;
  cursor: pointer;
  position: relative; }

.invoice-table__controls {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-radius: 4px; }

.invoice-table__controls .def-btn {
  padding: 15px;
  margin-right: 20px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 4px; }

.invoice-table tr.active,
.invoice-table tr:hover {
  background: var(--light-color);
  color: #fff !important; }

table tr.active *,
.invoice-table tr:hover * {
  color: #fff !important; }

.table-input {
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  border: none;
  background: none;
  border-radius: 0;
  position: relative; }

.invoice-table tr td {
  position: relative; }

.invoice-table tr td:nth-child(1) {
  width: 5%;
  text-align: center; }

.invoice-table tr td:nth-child(3) {
  width: 8%;
  text-align: center; }

.invoice-table tr td:nth-child(4) {
  width: 10%;
  text-align: center; }

.invoice-table tr td:nth-child(5) {
  width: 15%;
  text-align: center; }

.invoice-table tr td:nth-child(6) {
  width: 20%;
  text-align: center; }

.invoice-table tr td:nth-child(2) {
  width: 42%; }

textarea.table-input {
  resize: vertical; }

.deleteThisService {
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: red;
  position: absolute;
  left: -60px;
  top: 50%;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: opacity .3s;
  opacity: 0;
  z-index: 2; }

.invoice-table tr:hover .deleteThisService {
  display: flex;
  transform: translateY(-50%);
  opacity: 1; }

.invoice-controls {
  width: 100%;
  display: flex;
  align-items: center; }

.invoice-controls .def-btn {
  border-radius: 4px;
  margin-right: 20px;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  height: 55px; }

.ready-invoice {
  opacity: 0;
  transition: .3s; }

.ready-invoice.active {
  opacity: 1; }

#invoice-modal__window {
  width: 660px;
  height: auto;
  border: 2px solid #ce9967;
  border-radius: 30px; }

#invoice-modal__window .new-modal__form {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: none;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 108px !important;
  padding-right: 108px !important; }

#invoice-modal__window .new-modal__form .def-btn {
  padding: 15px;
  height: 55px; }

#invoice-modal__window .new-modal__input-wrapper {
  grid-template-columns: repeat(1, 1fr); }

.invoice-table.invoice-table__list tr.active, .invoice-table.invoice-table__list tr:hover {
  background: none !important;
  color: var(--dark-color) !important; }

.invoice-table.invoice-table__list tr td:nth-child(1) {
  width: 25%;
  text-align: center; }

.invoice-table.invoice-table__list tr td:nth-child(2) {
  width: 15%;
  text-align: center; }

.invoice-table.invoice-table__list tr td:nth-child(3) {
  width: 5%;
  text-align: center; }

.invoice-table.invoice-table__list .def-btn {
  padding: 7px 35px;
  height: 55px;
  border-radius: 4px;
  margin-left: 15px; }

.invoice-table.invoice-table__list tr {
  cursor: default; }

.invoice-table.invoice-table__list tr:hover * {
  color: var(--dark-color) !important; }

.invoice-table.invoice-table__list tr .def-btn:hover {
  color: #fff !important; }

@media (max-width: 1680px) {
  .main {
    overflow: hidden; } }

@media (max-width: 980px) {
  .main {
    overflow: hidden; }
  .mbvs {
    display: block !important; }
  .mbvsflex {
    display: flex !important; }
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .mhidden {
    display: none; }
  .crumbs ul li, .crumbs ul li a {
    font-size: 14px; }
  .crumbs {
    padding-top: 20px; }
  .block-title {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 130%;
    text-align: center; }
  .simple-form {
    margin-top: 0; }
  .simple-form__wrapper {
    width: calc(100% - 40px) !important;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    min-height: 280px;
    height: auto;
    border: 2px solid var(--light-color);
    border-radius: 30px;
    position: relative;
    padding: 50px 30px;
    padding-top: 45px;
    padding-bottom: 50px; }
  .simple-form__form {
    flex-direction: column; }
  .simple-form__form input {
    width: 100%;
    max-width: 100%;
    height: 69px;
    margin-bottom: 10px;
    padding-left: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px; }
  .simple-form__form .def-btn {
    width: 100%;
    height: 69px;
    margin-top: 20px;
    padding: 0;
    font-size: 16px; }
  .hidden-menu {
    top: 71px;
    overflow-y: scroll; }
  .hidden-menu__wrapper {
    flex-direction: column;
    padding-top: 30px;
    margin-bottom: 125px; }
  .aside__column {
    width: 100% !important; }
  .aside__menu {
    column-count: 1; }
  .new-modal__window {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none !important;
    width: calc(100% - 40px);
    height: auto;
    max-height: 430px;
    overflow-y: scroll;
    border-radius: 30px;
    padding: 0 !important; }
  .new-modal__window-wrapper {
    border: 2px solid #CE9967;
    padding-top: 40px !important;
    padding-bottom: 50px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    position: relative; }
  .new-modal__form {
    width: 100%;
    padding: 0;
    border: none;
    padding: 0 !important; }
  .new-modal__input-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px; }
  .new-modal__input-wrapper .def-btn {
    margin-top: 20px; }
  .new-modal__window .close-btn {
    width: 10px;
    height: 10px;
    top: 20px;
    right: 20px; }
  .new-modal__window .close-btn svg {
    width: 100%;
    height: 100%; }
  .new-modal__input-wrapper input {
    width: 100%;
    padding-left: 30px;
    height: 70px; }
  .new-modal__input-wrapper .def-btn {
    width: 100%;
    padding: 0;
    height: 70px; }
  .pockets__wrapper .right-side {
    text-align: center;
    margin-top: 50px; }
  .what-is-it span {
    font-size: 21px;
    color: #9A9FA8;
    text-decoration-color: #9A9FA8; }
  .logo-page .work-steps__wrapper {
    padding-bottom: 0; }
  .review__description:not(.active)::after {
    display: block;
    content: "...";
    width: 30px;
    background: #F5F5F5;
    z-index: 9;
    font-size: 16px !important;
    position: absolute;
    bottom: 3px;
    padding-left: 5px;
    right: 0; }
  .story-divider {
    margin-top: 30px;
    align-self: center; }
  .main {
    margin-top: 71px; } }
