



@media (max-width: 1680px) {

    
    
	.suggest__decal-1 {

	    position: absolute;
	    top: 58px;
	    left: -99px;
	    
	}






    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.suggest__wrapper {

	    padding-top: 40px;
	    padding-bottom: 80px;
	    position: relative;

	}


	.suggest__wrapper .block-title{

		text-align: left;

	}


	.what-we-suggest {

	    width: 100%;
	    margin-left: auto;
	    margin-right: auto;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-row-gap: 30px;
	    grid-column-gap: 0;
	    position: relative;
	    margin-top: 40px;

	}



	.suggest__decal-1 {

	    position: absolute;
	    top: 38px;
	    left: auto;
	    right: 0;

	}


	.ww-suggest__title{

		font-size: 19px;

	}



	.ww-suggest__description{

		font-size: 16px;

	}



	.what-we-suggest .read-more{

		font-size: 16px;

	}








































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






