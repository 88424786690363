@font-face {
    font-family: 'Mplus1p';
    src: url('../fonts/Mplus1p-Light.eot');
    src: local('Mplus1p-Light'), local('Mplus1p-Light'),
        url('../fonts/Mplus1p-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mplus1p-Light.woff') format('woff'),
        url('../fonts/Mplus1p-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Mplus1p';
    src: url('../fonts/Mplus1p-Regular.eot');
    src: local('Mplus1p-Regular'), local('Mplus1p-Regular'),
        url('../fonts/Mplus1p-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mplus1p-Regular.woff') format('woff'),
        url('../fonts/Mplus1p-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mplus1p';
    src: url('../fonts/Mplus1p-Medium.eot');
    src: local('Mplus1p-Medium'), local('Mplus1p-Medium'),
        url('../fonts/Mplus1p-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mplus1p-Medium.woff') format('woff'),
        url('../fonts/Mplus1p-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Mplus1p';
    src: url('../fonts/Mplus1p-Bold.eot');
    src: local('Mplus1p-Bold'), local('Mplus1p-Bold'),
        url('../fonts/Mplus1p-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mplus1p-Bold.woff') format('woff'),
        url('../fonts/Mplus1p-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Mplus1p';
    src: url('../fonts/Mplus1p-ExtraBold.eot');
    src: local('Mplus1p-ExtraBold'), local('Mplus1p-ExtraBold'),
        url('../fonts/Mplus1p-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mplus1p-ExtraBold.woff') format('woff'),
        url('../fonts/Mplus1p-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Spectral';
    src: url('../fonts/Spectral-Medium.eot');
    src: local('Spectral-Medium'), local('Spectral-Medium'),
        url('../fonts/Spectral-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Spectral-Medium.woff') format('woff'),
        url('../fonts/Spectral-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}















