




.pockets__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.pockets__wrapper .block-title strong{

	color: var(--light-color)

}



.pockets__header{

	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	margin-top: 20px;

}



.pockets__header .left-side{

	width: 58%;

}

.pockets__header .right-side{

	width: 32%;
	display: flex;
	align-items: flex-end;

}



.pockets__header-title{

	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 130%;
	color: var(--dark-color);

}


.pockets__includes{

	width: 100%;
	list-style-type: disc;
	padding: 0;
	padding-left: 20px;
	display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 53px;
    grid-row-gap: 5px;
	margin-top: 30px;

}


.pockets__includes li{

	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 140%;
	color: var(--dark-color);

}



.what-is-it{

	width: 100%;
	position: relative;

}


.what-is-it span{

	width: 303px;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 130%;
	color: var(--light-color);
	text-decoration: underline;
	text-decoration-color: var(--light-color);
	text-decoration-thickness: 1px;

}

.what-is-it__content-wrapper{

	width: 435px;
	height: 0;
	transition: height .3s;
	overflow: hidden;
	position: absolute;
	top: 150%;
	left: 0;
	z-index: 9;

}

.what-is-it::after{

	width: 100%;
	height: 40px;
	display: block;
	content: '';
	position: absolute;
	top: 56px;
	left: 0;

}

.what-is-it__content{

	width: 100%;
	background: #F7F7F7;
	border: 2px solid #CE9967;
	box-shadow: 0px 164px 66px rgba(0, 0, 0, 0.01), 0px 92px 55px rgba(0, 0, 0, 0.01), 0px 41px 41px rgba(0, 0, 0, 0.02), 0px 10px 23px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.01);
	border-radius: 20px;	
	padding: 30px;
	padding-right: 35px;
	padding-bottom: 60px;

}




.what-is-it:hover .what-is-it__content-wrapper{

	height: 100vh;
	height: max-content;

}



.what-is-it__content ,
.what-is-it__content *,
.what-is-it__content p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 220% !important;
	color: var(--dark-color) !important;

}



.what-is-it__content ul{

	list-style-type: disc;
	padding: 0;
	padding-left: 20px;

}




.pockets__list{

	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	list-style-type: none;
	padding: 0;
	margin-top: 85px;

}


.pockets__list li{

	width: 100%;
	height: 335px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	transition: .3s;
	background: #F7F7F7;
	border: 2px solid #CE9967;
	border-radius: 30px;
	padding-top: 40px;
	padding-bottom: 0;

}


.pocket__title{

	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: var(--dark-color);

}

.pocket__subtitle{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: var(--dark-color);
	margin-top: 2px;

}


.pocket__body{

	margin-top: 75px;

}



.pocket__description{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: var(--dark-color);

}



.pocket__footer{

	width: 100%;
	box-sizing: border-box;
	height: 87px;
	border: none;
	padding: 0;
	border-top: 2px solid var(--light-color);
	border-radius: 0px 0px 27px 27px;
	margin-top: auto;

}































