.modal-window{

	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 375px;
	height: auto;
	background: #F7F7F7;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	border-radius: 30px;
	border: 2px solid var(--light-color);

}


.new-modal__window{

	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: none !important;
	width: 1280px;
	height: auto;
	border-radius: 30px;
	padding: 0 !important;	

}



.new-modal__window-wrapper{

	width: 100%;
	height: 100%;
	background: #F7F7F7;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 30px;	
	border: 2px solid #CE9967;

}

.new-modal__form{

	width: 100%;
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	padding-left: 80px !important;
	padding-right: 80px !important;
	border-radius: 30px;	

}



.new-modal__input-wrapper{

	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	margin-top: 30px;

}


.modal-window.fancybox-content{

    padding: 20px;
    padding-top: 40px;
    padding-bottom: 87px;

}




.succesess-window.fancybox-content{

    padding: 40px;

}






.modal-window .def-btn{

	margin-top: 20px;

}



.close-btn, .close-menu{

	border:none;
	background: none;
	outline: none;
	width: 25px;
	height: 25px;
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: 20px;


}


.close-btn svg, .close-menu svg{

	width: 100%;
	height: 100%;

}


.new-modal__window .close-btn{

	top: 30px;
	right: 40px;

}


.modal__header{

	width: 100%;
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

}



.modal-title,
.modal-title p,
.modal-title *{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 20px !important;
	line-height: 130% !important;
	color: var(--dark-color);
	text-align: left !important;

}



.modal__form-services{

	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;

}

.modal__form-services label{

	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #595959;
	border-radius: 50px;	
	padding: 10px 20px;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;

}

.modal__form-services input{

	display: none;

}


.modal__form-services input:checked + label{

	border-color: var(--light-color);
	background: var(--light-color); 
	color: #fff;

}


.modal__form-services input:checked + label span{

	color: #fff;

}




.modal-form {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    text-align: center;
    

}


.modal-form label > span{

	display: block;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: var(--dark-color);

}



.def-input{

	border:none;
	background:none;
	outline: none;
	width: 100%;
	height: 77px;
	border: 1px solid var(--dark-color);
	color: var(--light-color);
	padding-left: 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: var(--dark-color);
	border-radius: 20px;	
	transition: .3s;
		
}


.complex__list{

	width: 66.334%;
	height: 77px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid var(--dark-color);
	color: var(--light-color);
	padding-left: 30px;
	padding-right: 30px;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: var(--dark-color);
	border-radius: 20px;	
	transition: .3s;
	position: relative;
	cursor: pointer;

}

.complex__list.choosed{

	border: 1px solid #CE9967;

}

.complex__list.choosed .complex__list-name{

	color: var(--light-color);

}

.complex__list-items-wrapper{


	width: 703px;
	height: 0;	
	overflow: hidden;
	position: absolute;
	top: 83%;
	left: 8.6%;
	transition: .3s;
	z-index: 99;
	box-shadow: 0px 164px 66px rgba(0, 0, 0, 0.01), 0px 92px 55px rgba(0, 0, 0, 0.01), 0px 41px 41px rgba(0, 0, 0, 0.02), 0px 10px 23px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.01);
	border-radius: 20px;

}


.complex__list-items-wrapper.active{

	height: 265px;

}



.complex__list-items{

	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: .3s;
	background: #F7F7F7;
	border: 1px solid #CE9967;
	border-radius: 20px;
	padding: 30px;
	padding-bottom: 50px;

}


.complex__list-item input{

	display: none;

}


.complex__list:hover{

	border-color: var(--light-color);

}



.complex__list-item{

	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	cursor: pointer;

}


.complex__list-item:last-child{

	margin-bottom: 0;

}


.complex__list-item{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	color: #595959;

}


.complex__list-item.active,
.complex__list-item:hover{

	color: var(--light-color);

}
.complex__list-item.active{

	font-weight: 500;

}


.modal-window .def-input{

	height: 46px;
	border-radius: 10px;	

}

.def-input::placeholder{

	font-size: 16px;
	text-align: left;

}


.def-input:active,
.def-input:focus,
.def-input:hover{

	border-color: var(--light-color);

}


.modal-form textarea{

	padding-top: 12px;
	height: 170px;
	resize: none;

}


.modal__input-combiner{

	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;

}

.modal__input-combiner .def-input{

	width: 48%;

}



.submit-btn{

	width: 246px;
	height: 50px;
	background: #5284F6;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	color: #FFFFFF;



}

.modal-form .custom-checkbox{

	flex-direction: row;
	align-items: center;

}


.modal-form .custom-checkbox .box svg{

	display: none;

}



.modal-form .custom-checkbox input:checked + .box svg{

	display: block;

}


.modal-form .custom-checkbox .box{

	min-width: 14px;
	max-width: 14px;
	min-height: 14px;
	max-height: 14px;
	border: 1px solid var(--bg-color);	

} 


.modal-form .custom-checkbox .checkbox-desc{

	color: var(--bg-color);
	font-family: var(--def-font);
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: normal;
	line-height: 18px;
	text-align: left;
}


.modal-form .custom-checkbox .checkbox-desc a{

	color: var(--or);
	font-family: var(--def-font);
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-align: left;
	text-decoration: underline;
	text-decoration-thicknes: 1px;
	text-decoration-color: var(--or);  

}



.modal-form .def-btn{

	width: 100%;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	text-align: center;

}


.privacy-modal{

	width: 100%;
	text-align: center;
	margin-top: 30px;

}


.privacy-modal,
.privacy-modal p,
.privacy-modal p*{

	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 12px !important;
	line-height: 140% !important;
	text-align: center !important;
	color: var(--dark-color) !important;

}

.privacy-modal strong{

	font-weight: 500 !important;
	color: var(--light-color) !important;

}


.jGrowl {

    z-index: 9999999 !important;

}


.custom-error,.error{

    border:1px solid red !important;
}




.fancybox-content{

	position: absolute !important;
	padding: 0;

}


.error_g-recaptcha-response{

    display: none !important;

}



.inner-text{

	width: 100%;
	position: absolute;
	bottom: 12px;
	left: 50%;
	transform: translateX(-50%);

}



.modal__input-combiner{

	flex-direction: column;
	align-items: flex-start;
	grid-gap: 16px;

}

.modal__input-combiner .def-input{

	width: 100%;
	height: 55px;
	border-radius: 10px;

}

.modal-form .def-btn{

	display: flex;
	align-items: center;
	justify-content: center;
	height: 55px;
	padding: 0;
	margin-top: 50px;
	border-radius: 10px;

}


#inner-window{

	width: 410px;
	height: auto;
	max-height: calc(100% - 40px);
	border-radius: 10px;

}


.modal__form-services label{

	border-radius: 10px;

}































































































