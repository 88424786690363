
@media (max-width: 1680px) {


	.particle__1 {

	    top: 190px;
	    left: -172px;

	}

	.particle__2 {

	    bottom: 10px;
	    right: -105px;

	}

	.weu__img {

	    position: absolute;
	    top: 80px;
	    right: -130px;
	    
	}


































}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {


	.whatwedo__wrapper {

	    position: relative;
	    padding-top: 20px;
	    padding-bottom: 40px;

	}



	.whatwedo .particle__1{

		display: none;

	}



	.whatwedo__title, .whatwedo__title *, .whatwedo__title p{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 300 !important;
		font-size: 24px !important;
		line-height: 130% !important;
		text-align: center !important;

	}



	.whatwedo__subtitle {

	    font-style: normal;
	    font-weight: 500;
	    font-size: 18px;
	    line-height: 130%;
	    text-align: center;
	    color: var(--light-color);
	    margin-top: 10px;

	}


	.whatwedo__list {

	    margin-top: 40px;

	}


	.whatwedo__list li{

		padding-bottom: 25px;

	}


	.whatwedo__right-side,
	.whatwedo__left-side {

	    width: auto;

	}

	.whatwedo__right-side, .whatwedo__right-side *, .whatwedo__right-side p,
	.whatwedo__left-side, .whatwedo__left-side *, .whatwedo__left-side p {

	    font-size: 16px!important;

	}


	.whatwedo__right-side{

		margin-left: 44px;

	}


	.whatwedo__right-side::before {

	    min-width: 6px;
	    max-width: 6px;
	    min-height: 6px;
	    max-height: 6px;
	    margin-right: 9px;

	}




	.whatwedo .particle__2{

		width: 126px;
		height: 148px;
		object-fit: contain;
		left: -40px;
		bottom: 0;

	}

	.whatwedo__wrapper{

		padding-bottom: 100px;

	}



	.whatwedo{

		margin-bottom: 20px;

	}




	.home-suggest__wrapper {

	    position: relative;
	    padding-top: 30px;
	    padding-bottom: 50px;

	}


	.home-suggest__list {

	    width: 100%;
	    margin-left: auto;
	    margin-right: auto;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 10px;
	    margin-top: 40px;
	    padding: 0;

	}


	.home-suggest__list>li{

		background: #F2F2F2;
		box-shadow: -36.4516px 144.028px 59.5672px rgba(0, 0, 0, 0.01), -20.4484px 80.9047px 49.7875px rgba(0, 0, 0, 0.05), -8.89062px 35.5625px 37.3406px rgba(0, 0, 0, 0.09), -2.66719px 8.89062px 20.4484px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 17px;
		padding-top: 30px;
		padding-right: 22px;
		padding-bottom: 40px;
		padding-left: 30px;
		align-items: center;

	}


	.home-suggest__list>li:nth-child(1) .left-side{

		width: 44px;
		height: 38px;

	}
	.home-suggest__list>li:nth-child(2) .left-side{

		width: 36px;
		height: 38px;

	}


	.home-suggest__list>li svg{

		width: 100%;
		height: 100%;

	}



	.home-suggest__title{

		font-size: 19px;

	}


	.home-suggest__list>li .right-side ul li a{

		font-size: 16px;

	}






























































































































































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






