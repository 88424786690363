



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.mp-container{

		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 50px;
		padding-bottom: 50px;

	}



	.m-p__top-title{

		font-size: 18px;

	}


	.m-p__title,
	.m-p__title *,
	.m-p__title p{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 300 !important;
		font-size: 24px !important;
		line-height: 130% !important;
		color: var(--dark-color) !important;

	}






	.m-portfolio__slider .swiper-slide img{

		max-width: 100%;
		max-height: 300px;
		object-fit: contain;
		margin-top: 30px;
		margin-bottom: 40px;

	}



	.m-p__description *, .m-p__description p{

		font-size: 16px !important;

	}



	.m-portfolio__slider-wrapper .def-btn{

		width: 220px;
		height: 68px;
		font-size: 16px;
		margin-top: 40px;

	}



	.context-page .m-portfolio__slider, .main-page .m-portfolio__slider{

		padding-bottom: 0 !important;

	}



	.go-to-portfolio{

		width: auto;
		margin-left: 0;
		margin-top: 85px;

	}



	.go-to-portfolio span{

		font-size: 17px;

	}


	.go-to-portfolio svg {

	    margin-left: 105px;
	    width: 40px;

	}



	.swiper-pagination.m-portfolio-pagintaion {

		max-width: calc(100% - 20%) !important;
		width: 100% !important;
	    bottom: 115px!important;
	    left: 6%;
	    transform: translateX(6%)!important;

	}



	.swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet-active{

		width: 60px;

	}

	.swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet{

		width: 60px;
		height: 4px;

	}

	.main-page .simple-form.complex-form-block{

		margin-top: 50px;
		margin-bottom: 50px;

	}



	.simple-form.complex-form-block .simple-form__wrapper{

		height: auto;
		padding-bottom: 90px;

	}


	.simple-form__form.complex-form{

		height: auto;

	}



	.simple-form__form.complex-form .swiper-slide{

		height: auto;

	}



	.simple-form__form.complex-form .swiper-slide .block-subtitle{

		font-size: 17px;
		text-align: left;

	}

	.complex-input__wrapper{

		margin-top: 40px;
		flex-direction: column;

	}



	.swiper-pagination.complex-form-pagintaion {

	    display: flex;
	    align-items: center;
	    justify-content: center;
	    padding-left: 0;
	    bottom: 10% !important;

	}


	.swiper-pagination.complex-form-pagintaion .swiper-pagination-bullet{

		width: 44px;

	}



	.portfolio__actions{

		margin-top: 40px;
		flex-direction: column;
		align-items: flex-start;

	}



	.swiper-pagination.m-portfolio-pagintaion{

		transform: translateX(0%)!important;

	}



	.go-to-portfolio{

		margin-top: 40px;

	}




	.m-portfolio__wrapper .left-side{

		min-width: 100% !important;

	}



	.portfolio__actions {

	    width: 100%;

	}





























































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






