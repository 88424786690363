



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.complex__list-items-wrapper{

		width: 103%;
		top: 58%;
		left: 50%;
		transform: translateX(-50%);

	}

	.complex__list {

	    width: 100%;

	}


	.simple-form__form.complex-form .swiper-slide .block-subtitle{

		text-align: center;

	}


	.modal-window{

		width: calc(100%  - 40px);
		height: auto;
		max-height: calc(100% - 40px);
		border-radius: 10px;

	}



	#inner-window{

		width: calc(100%  - 40px);
		height: auto;
		max-height: calc(100% - 40px);
		border-radius: 10px;

	}



	.close-btn, .close-menu{

		border:none;
		background: none;
		outline: none;
		width: 25px;
		height: 25px;
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 10px;


	}
































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






