



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {



	.hero__title{

		padding-top: 0;

	}


	.hero__img2{

		position: absolute;
		right: -110px;
		bottom: 0;

	}



	.hero__form button{

		width: 217px;
		height: 69px;
		padding-left: 0;
		padding-right: 0;
		font-size: 16px;

	}

	.dev-page .hero__img {

	    right: -29%;
	    top: 45%;
	    
	}


	.dev-page .advantages__list .advantage:first-child {

	    width: 100%;
	    
	}



































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






