


.weu{

	position: relative;
	min-height: 784px;

}



.weu__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.weu__description{

	position: relative;
	margin-top: 45px;

}


.weu__description,
.weu__description *,
.weu__description p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 24px !important;
	line-height: 130% !important;
	color: var(--dark-color);

}

.weu__description strong,
.weu__description * strong,
.weu__description p strong{

	font-weight: 500 !important;

}




.weu__img{

	position: absolute;
	top: 80px;
	right: 0;

}




































































































































