



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.hero__form button {

	    width: 217px;

	}




	.seo-page .seo-advantages .advantages__list {

		grid-template-columns: repeat(1,1fr);

	}



	.seo-page .hero__form button{

		width: 260px;

	}




















































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






