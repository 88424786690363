



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.blog__wrapper {

	    position: relative;
	    padding-top: 40px;
	    padding-bottom: 80px;

	}



	.blog__wrapper .block-title,
	.portfolio-page .block-title{

		text-align: left;

	}

	.blog-page .tags ,
	.portfolio-page .tags {

	    margin-top: 20px;
	    display: flex !important;
	    flex-wrap: wrap;

	}


	.blog-page .tag,
	.portfolio-page .tag {

		width: auto;
		min-width: 96px;
		height: auto;
		min-height: 44px;
		padding: 9px 31px;
	    border: 2px solid var(--dark-color);
	    border-radius: 8px;
	    font-style: normal;
	    font-size: 16px;
	    text-align: center;
	    color: var(--dark-color);

	}



	.blog-page .tag.active,
	.blog-page .tag:hover,
	.portfolio-page .tag.active,
	.portfolio-page .tag:hover{

		color: #fff !important;
		border-color: var(--light-color) !important;

	}

	.blog__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 10px;
	    margin-top: 40px;

	}


	.blog__item {

	    width: 100%;
	    height: 665px;
	    border-radius: 20px;

	}

	.blog__item-header {

	    width: 100%;
	    height: 247px;
	    display: block;
	    border-radius: 20px 20px 0 0;

	}



	.blog__item-header img{

	    border-radius: 20px 20px 0 0;

	}


	.blog__item-body{

		height: calc(100% - 247px);
		padding: 30px;
		padding-top: 20px;

	}


	.blog__item-neck{

		flex-direction: column;
		align-items: flex-start;

	}


	.blog-page .tags{

		margin-top: 0;

	}



	.blog__item-neck .tags .tag {

	    border: 2px solid #ce9967;
	    border-radius: 8px;
	    padding: 9px 33px;
	    color: var(--light-color);

	}


	.blog__item-neck time{

		font-size: 14px;
		margin-top: 15px;

	}



	.blog__item-title{

		font-size: 18px;
		line-height: 140%;

	}


	.blog__item-text{

		max-height: 115px;
		overflow: hidden;

	}

	.blog__item-text, .blog__item-text *, .blog__item-text p{

		font-size: 14px!important;

	}



	.blog__item-body .read-more{

		font-size: 16px;
		margin-top: 30px;

	}


	#pdopage .blog__item-neck .tag.active, #pdopage .blog__item-neck .tag:hover{

		background: none;
		color: var(--light-color) !important;

	}


	.blog__item{

		height: auto;

	}



	.news-page .blog__list .tags{

		grid-auto-flow: row;

	}


	.news-page .blog__list .tags .tag{

		width: max-content;

	}























































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






