



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {




	.seoblock__wrapper{

		padding-top: 0;
		padding-bottom: 50px;

	}


	.news-content .news-block-content h1,
	.seoblock__text h1{

		font-size: 24px !important;

	}

	
	.news-content .news-block-content h2,
	.seoblock__text h2{

		font-size: 22px !important;

	}
	.news-content .news-block-content h3,
	.seoblock__text h3{

		font-size: 20px !important;

	}
	.seoblock__text *, .seoblock__text p{

		font-size: 16px !important;

	}



	.seoblock__text{

		max-height:164px;

	}




	.seo-btn{

		margin-top: 20px;

	}


	.seo-btn svg{

		width: 14px;
		height: 14px;

	}

	.seo-btn span{

		font-size: 16px;

	}




































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






