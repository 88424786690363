



@media (max-width: 1680px) {

    
 .balagan-particle__1 {
    top: 50px;
    left: -59px;
  }

 .balagan-particle__2 {
    top: 13px;
    right: -191px;
    z-index: 2;
  }


 .balagan-particle__3 {
    bottom: -59%;
    left: -12%;
  }


	.tech-page .plans__list {

	    grid-template-columns: repeat(3,1fr);

	}



	.tech-page .plans__wrapper {

	    width: 1280px;

	}






























































































































































    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {




	.tech-page .hero__subtitle,.tech-page  .hero__subtitle *,.tech-page  .hero__subtitle p{

		font-size: 16px !important;

	}



	.tech-page .hero__subtitle{

		margin-top: 20px;

	}



	.tech-page .hero .hero__img{

		width: 235px;
		height: 288px;
		object-fit: contain;
		position: absolute !important;
	    right: -50px !important;
	    top: auto!important;
	    bottom: 40px !important;

	}




	.tech-page .hero__form button {

	    width: 217px;

	}


	.tech-page .hero__wrapper {

	    padding-bottom: 160px !important;

	}


	.tech-page .advantages.context-advantages .advantages__list .advantage {

	    flex-direction: row;

	}


	.tech-page .advantage__description{

		margin-top: 0;
		margin-left: 20px;
		text-align: left;

	}



	.tech-page .advantages__list {

	    grid-gap: 20px !important;
	    margin-top: 40px;

	}


	.balagan_bg{

		display: none;

	}


	.balagan__wrapper{

		padding-bottom: 60px;
		padding-top: 0;

	}

	.balagan__title,
	.balagan__title *,
	.balagan__title p{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 24px !important;
		line-height: 130% !important;
		text-align: center !important;
		color: var(--dark-color);

	}




	.balagan__title strong,
	.balagan__title * strong,
	.balagan__title p strong{

		font-weight: 800 !important;

	}



	.balagan__subtitle{

		margin-top: 20px;

	}



	.balagan__subtitle,
	.balagan__subtitle *,
	.balagan__subtitle p{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 160% !important;
		text-align: center !important;
		color: var(--dark-color) !important;

	}


	.balagan__subtitle strong,
	.balagan__subtitle * strong,
	.balagan__subtitle p strong{

		font-weight: 800 !important;

	}


	.balagan_cheses{

		display: none;

	}


	.balagan__list{

		width: 100%;
		list-style-type: none;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 20px;
		border: 1px solid var(--light-color);
		padding-left: 0;
		padding-left: 30px;
		padding-top: 50px;
		padding-right: 35px;
		padding-bottom: 50px;
		border-radius: 18px;

	}




	.balagan__block{

		width: 100%;
		margin-top: 40px;
		position: relative;

	}



	.balagan__list-title{

		width: 220px;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		background: #F7F7F7;
		line-height: 130%;
		text-align: center;
		color: var(--dark-color);
		position: absolute;
		top: -22px;
		padding-left: 10px;
		padding-right: 10px;
		left: 50%;
		transform: translateX(-50%);

	}



	.balagan__list li,
	.balagan__list li p,
	.balagan__list li *{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 140% !important;
		color: var(--dark-color);

	}



	.balagan__list li strong,
	.balagan__list li p strong,
	.balagan__list li * strong{

		font-weight: 500 !important;

	}



	.balagan__list li{

		position: relative;
		padding-left: 16px;

	}


	.balagan__list li::before{

		width: 6px;
		height: 6px;
		display: block;
		content: '';
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: -10px;
		transform: translateY(-50%);
		background: var(--light-color);

	}

	.balagan-particle{

		display: none;

	}



	.tech-page .hero .hero__form{

		padding-top: 65px;

	}



	.tech-page .advantage__description{

		margin-left: 0;

	}

	.tech-page .advantage__content{

		margin-left: 10px;

	}


	.tech-page .plans__list{

		grid-template-columns: repeat(1,1fr);

	}



	.tech-page .plans__wrapper{

		width: 100%;

	}







































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






