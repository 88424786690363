


.header{

	background: linear-gradient(180deg, #EAEAEB -162.5%, #F8F8F8 100%);
	box-shadow: -3px 10px 23px rgba(0, 0, 0, 0.03);
	// position: fixed;
	// top: 0;
	// left: 50%;
	// transform: translateX(-50%);
	z-index: 3;
	width: 100%;
	max-width: 1920px;
	z-index:100;
	position: relative;

}



.header__wrapper{

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;

}



.header__menu{

	position: relative;

}


.header__menu ul{

	display: flex;
	align-items: center;
	list-style-type: none;
	padding: 0;

}


.header__menu ul li{

	margin-right: 55px;

}



.header__menu ul li:last-child{

	margin-right: 0;

}



.header__menu ul li,
.header__menu ul li a{

	display: block;
	font-family: var(--font);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--dark-color);
	transition: .3s;

}





.header__menu ul li:hover a,
.header__menu ul li.active{

	color: var(--light-color);

}



.header__contacts{

	display: flex;
	align-items: center;

}


.quick_contacts{

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

}


.quick_contacts a{

	font-family: var(--font);
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	color: var(--dark-color);

}


.quick_contacts button{

	text-align: right;

}


.socials{

	list-style-type: none;
	padding: 0;
	display: flex;
	align-items: center;
	margin-left: 25px;

}



.socials li{

	margin-right: 15px;

}



.socials li:last-child{

	margin-right: 0;

}



.socials li a svg *{

	transition: .3s;

}



.socials li:hover a svg path,
.socials li:hover a svg *{

	fill: var(--light-color) !important;

}











































































































































