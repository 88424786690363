



.started__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}



.started__wrapper .block-title{

	text-align: center;

}



.started__stories{

	width: 100%;
	max-height: 663px;
	transition: .3s;
	overflow: hidden;
	margin-top: 60px;
	position: relative;

}


.start-story{

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;

}


.start-story__text{

	width: 100%;
	position: relative;
	transition: .3s;

}


.start-story__text *,
.start-story__text p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 24px !important;
	line-height: 130% !important;
	text-align: center !important;
	color: var(--dark-color) !important; 

}


.start-story__text * strong,
.start-story__text strong,
.start-story__text p strong{

	font-weight: 500 !important;

}



.story-divider{

	margin-top: 40px;

}



.started__stories:not(.active) .hiddable{

	background: linear-gradient(180deg, #595959 0%, rgba(89, 89, 89, 0) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

}




















































































































