
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {


	.context-page .advantages.context-advantages .advantages__list .advantage{

		flex-direction: row;

	}


	.context-page .advantages.context-advantages .advantage__content{

		margin-left: 20px;

	}

	.context-page .advantages.context-advantages .advantage__description{

		margin-top: 0;

	}


	.context-page .advantages.context-advantages .advantage__description *, .context-page .advantages.context-advantages .advantage__description p{

		text-align: left !important;

	}



	.context-page .advantages.context-advantages .advantages__list {

	    grid-gap: 20px;

	}




	.context-page .advantages .advantages__list {

	    grid-gap: 20px;
	    width: 100%;

	}


	.context-page .advantages .advantages__list .advantage{

		flex-direction: row;
		align-items: flex-start;

	}


	.context-page .advantages .advantages__list .advantage:nth-child(3n+2){

		margin-left: 0;

	}



	.context-page .advantages .advantage__content {

	    text-align: left;
	    margin-left: 0;
	    align-self: center;
	    padding-left: 20px;

	}


	.context-page .advantages .advantages__list .advantage{

		margin-bottom: 0;

	}


	.context-page .advantages .advantages__list .advantage:last-child{

		margin-bottom: 0;

	}














































































































































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






