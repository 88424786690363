

.blog__wrapper{

	position: relative;
	padding-top: 80px;
	padding-bottom: 80px;

}


.blog__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	margin-top: 80px;

}



.blog__item{

	width: 100%;
	height: 675px;
	background: #F7F7F7;
	border: 2px solid #CE9967;
	border-radius: 50px;	

}



.blog__item-header{

	width: 100%;
	height: 278px;
	display: block;
	border-radius: 50px 50px 0px 0px;

}



.blog__item-header img{

	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50px;	
	border-radius: 50px 50px 0px 0px;

}


.blog__item-body{

	width: 100%;
	height: calc(100% - 278px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 60px;
	padding-top: 20px;

}


.blog__item-neck .tags{

	margin-top: 0;

}

.blog__item-neck .tags .tag{

	border: 2px solid #CE9967;
	border-radius: 10px;
	padding: 10px 38px;
	color: var(--light-color);

}


.blog__item-neck .tags .tag:hover,
.blog__item-neck .tags .tag.active{

	color: #fff;

}


.blog__item-neck{

	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

}


.blog__item-neck time{

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 160%;
	color: var(--dark-color);

}



.blog__item-description{

	width: 100%;
	margin-top: 20px;

}



.blog__item-title{

	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 160%;
	color: var(--dark-color);

}


.blog__item-text{

	width: 100%;
	margin-top: 10px;

}


.blog__item-text,
.blog__item-text p,
.blog__item-text *{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}



.blog__item-body .read-more{

	font-style: normal;
	font-weight: 800;
	font-size: 20px;
	line-height: 160%;
	text-transform: none;
	color: var(--light-color);

}



.blog__item-body .read-more span{

	position: relative;

}



.blog__item-body .read-more span::after{

	width: 100%;
	height: 1px;
	display: block;
	content: '';
	background: var(--light-color);
	position: absolute;
	bottom: 2px;
	left: 0;

}







































































































































































































