


.single-news{

	position: relative;

}



.single-news__banner{

	width: 100%;
	height: 540px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: 40px;
	z-index: 3;

}





.single-news__banner-img__wrapper{

	width: 100%;
	height: 540px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

}

.single-news__banner-img__wrapper img{

	width: 100%;
	height: 100%;
	object-fit: cover;

}


.single-news__banner-img__wrapper::after{

	width: 100%;
	height: 100%;
	display: block;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background: linear-gradient(90deg, #373634 18.88%, rgba(55, 54, 52, 0) 100%);

}


.single-news__wrapper .crumbs ul li,.single-news__wrapper  .crumbs ul li a{

	color: #fff !important;

}


.single-news__wrapper .crumbs ul li::after {

    background: url(../img/icons/crumb-arroww.svg) no-repeat;

}

.single-news__title{

	width: 100%;
	margin-top: 60px;
	position: relative;
	z-index: 3;

}

.single-news__title,
.single-news__title *,
.single-news__title p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 60px !important;
	line-height: 130% !important;
	color: #FFFFFF !important;

}


.single-news__title strong,
.single-news__title * strong,
.single-news__title p strong{

	font-weight: 800 !important;

}


.single-news__info{

	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 3;
	margin-top: auto;

}



.single-news__info .tags{

	margin-top: 0;

}

.single-news__info .tags .tag{

	border-color: #fff;
	color: #fff;

}



.single-news__info .tags .tag:hover{

	cursor: default;
	background: none;

}



.read-time{

	display: flex;
	align-items: center;
	margin-left: 40px;

}


.read-time span{

	display: block;
	margin-left: 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 160%;
	color: #FFFFFF;

}




.news-content{

	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

}


.news__container{

	width: 1340px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	padding-left: 30px;
	padding-bottom: 80px;
	padding-top: 50px;

}



.news-content__sidebar{

	width: 29.3%;

}


.article__content{

	width: 100%;
	background: #F7F7F7;
	border-radius: 50px;	
	padding: 40px;
	padding-bottom: 60px;
	padding-right: 8px;
	filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.02)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.02)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.01));

}


.article__title{

	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 160%;
	color: var(--dark-color);

}



.article__content-list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	margin-top: 20px;

}


.article__content-list li{

	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 200%;
	color: var(--dark-color);
	transition: .3s;
	cursor: pointer;

}



.article__content-list li:hover{

	color: var(--light-color);

}


.article__content-list li.active{

	font-weight: 800;
	color: var(--light-color);

}



.sidebar__form{

	width: 100%;
	background: #F7F7F7;
	border-radius: 50px;	
	position: relative;
	padding: 40px;
	padding-bottom: 50px;
	padding-right: 28px;
	margin-top: 20px;
	filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.02)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.02)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.01));

}



.sidebar__form-title{

	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 160%;
	color: var(--dark-color);
	margin-bottom: 30px;

}




.sidebar__form .def-input{

	width: 100%;
	height: 61px;
	border: 1px solid #595959;
	border-radius: 50px;
	margin-bottom: 10px;	
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	color: var(--dark-color) !important;

}


.sidebar__form .def-input::placeholder{

	color: #8D909B !important;


}

.sidebar__form textarea.def-input{

	height: 147px;
	resize: none;
	margin-top: 10px;
	border-radius: 20px;
	padding-top: 20px;

}


.sidebar__form .def-btn{

	width: 100%;
	height: 61px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: none;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	margin-top: 20px;

}



.news-content__description{

	width: calc(calc(100% - 29.3%) - 50px);

}



.news-block-content{

	width: 100%;
	margin-top: 40px;

}

.news-block-content:first-child{

	margin-top: 0;

}


.news-block-content,
.news-block-content *,
.news-block-content p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 200% !important;
	color: var(--dark-color);

}


.news-block-content .news-block__title,
.news-block-content * .news-block__title,
.news-block-content p .news-block__title{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 40px !important;

}



.news-block-content ul,
.news-block-content * ul{

	list-style-type: none;
	padding: 0;

}




.news-block-content ul li,
.news-block-content * ul li{

	display: flex;
	align-items: flex-start;
	position: relative;
	margin-bottom: 20px;

}

.news-block-content ul li:last-child,
.news-block-content * ul li:last-child{

	margin-bottom: 0;

}



.news-block-content ul li::before,
.news-block-content * ul li::before{

	display: block;
	content: '';
	min-width: 10px;
	max-width: 10px;
	min-height: 10px;
	max-height: 10px;
	border-radius: 50%;
	background: #CE9967;
	margin-right: 10px;
	margin-top: 10px;


}



.news-block-content img{

	width: 100%;
	max-height: 448px;
	object-fit: contain;

}




.article-rating{

	width: 100%;
	position: relative;
	margin-top: 40px;

}



.article-rating__title{

	font-style: normal;
	font-weight: 300;
	font-size: 40px;
	line-height: 200%;
	color: #000000;

}


.article-rating__wrapper{

	width: 100%;
	position: relative;
	display: flex !important;
	align-items: flex-start;
	grid-gap: 20px;
	margin-top: 20px;

}


.article__rating-block{

	box-sizing: border-box;
	width: 140px;
	max-height: 100px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 2px solid #CE9967;
	border-radius: 20px;
	transition: .3s;
	cursor: pointer;

}


.article__rating-block:hover,
.article__rating-block.active{

	border-color: #FF7C00;
	background: var(--light-color);
	color: #fff !important;

}


.article__rating-count{

	display: flex;
	align-items: center;

}

.article__rating-count svg{

	margin-right: 5px;

}

.article__rating-count .article__rating-number{

	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 200%;
	text-align: center;
	color: #000000;

}


.article__rating-description{

	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 200%;
	text-align: center;
	color: #8D909B;
	margin-top: 5px;

}



.article__rating-block.active .article__rating-description{

	font-weight: 800;

}

.article__rating-block:hover .article__rating-description,
.article__rating-block.active .article__rating-description,
.article__rating-block:hover .article__rating-number,
.article__rating-block.active .article__rating-number{

	color: #fff;

}




.xlike .xlike__link{

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

}


















































































































