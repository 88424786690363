



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.advantages__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 20px;
	    margin-top: 40px;

	}


	.advantage__description *, .advantage__description p{

		font-size: 16px!important;

	}



	.advantage__icon svg{

		width: 70px;
		height: 70px;

	}



	.advantages__wrapper{

		padding-bottom: 60px;

	}




	.advantage__title{

		font-size: 24px;
		line-height: 100%;

	}



	.advantage__icon{

		width: 70px;
	    height: 70px;

	}























































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






