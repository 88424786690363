	
	.balagan{

		position: relative;
		min-height: 1182px;
		z-index: 4;

	}

	.balagan-particle{

		position: absolute;

	}

	.balagan__wrapper{

		padding-top: 100px;
		padding-bottom: 205px;

	}

	.balagan__title,
	.balagan__title *,
	.balagan__title p{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 40px !important;
		line-height: 130% !important;
		text-align: center !important;
		color: var(--dark-color);

	}




	.balagan__title strong,
	.balagan__title * strong,
	.balagan__title p strong{

		font-weight: 800 !important;

	}



	.balagan__subtitle{

		margin-top: 20px;

	}



	.balagan__subtitle,
	.balagan__subtitle *,
	.balagan__subtitle p{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 18px !important;
		line-height: 160% !important;
		text-align: center !important;
		color: var(--dark-color) !important;

	}


	.balagan__subtitle strong,
	.balagan__subtitle * strong,
	.balagan__subtitle p strong{

		font-weight: 800 !important;

	}


	.balagan_cheses{

		display: none;

	}


	.balagan__list{

		width: 100%;
		list-style-type: none;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 95px;
		grid-row-gap: 30px;
		border: 1px solid var(--light-color);
		padding-left: 0;
		padding-left: 40px;
		padding-top: 50px;
		padding-right: 40px;
		padding-bottom: 50px;
		border-radius: 18px;

	}




	.balagan__block{

		width: 100%;
		margin-top: 40px;
		position: relative;

	}



	.balagan__list-title{

		width: auto;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		background: #F7F7F7;
		line-height: 130%;
		text-align: center;
		color: var(--dark-color);
		position: absolute;
		top: -12px;
		padding-left: 10px;
		padding-right: 10px;
		left: 50%;
		transform: translateX(-50%);

	}



	.balagan__list li,
	.balagan__list li p,
	.balagan__list li *{

		font-family: var(--font) !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: 18px !important;
		line-height: 140% !important;
		color: var(--dark-color);

	}



	.balagan__list li strong,
	.balagan__list li p strong,
	.balagan__list li * strong{

		font-weight: 500 !important;

	}



	.balagan__list li{

		position: relative;
		padding-left: 16px;

	}


	.balagan__list li::before{

		width: 6px;
		height: 6px;
		display: block;
		content: '';
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: -10px;
		transform: translateY(-50%);
		background: var(--light-color);

	}









	.tech-page .hero{

		min-height: 788px;

	}


	.tech-page .hero.contexthero .hero__img {

	    position: absolute;
	    top: 0;
	    right: 0;
	    z-index: 1;

	}



	.balagan-particle__1{

		top: 164px;
		left: 0;

	}


	.balagan-particle__2{

		top: -44px;
		right: 0;

	}


	.balagan-particle__3{

		bottom: -30%;
		left: -21%;

	}


	.tech-page .plans__wrapper .block-title{

		text-align: center;

	}


	.tech-page .plans__wrapper .block-title strong{

		font-weight: 800;
		color: var(--dark-color);

	}





	.tech-page .plans__wrapper{

		width: 1720px;

	}


	.tech-page .plans__list{

		grid-template-columns: repeat(4,1fr);

	}









































































































































