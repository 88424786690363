


.home-suggest__wrapper{

	position: relative;
	padding-top: 80px;
	padding-bottom: 120px;

}




.home-suggest__wrapper .block-title{

	text-align: center;

}



.home-suggest__list{

	width: 1085px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	margin-top: 65px;

}



.home-suggest__list > li{

	width: 100%;
	display: flex;
	align-items: flex-start;
	box-shadow: -41px 162px 67px rgba(0, 0, 0, 0.01), -23px 91px 56px rgba(0, 0, 0, 0.05), -10px 40px 42px rgba(0, 0, 0, 0.09), -3px 10px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
	border-radius: 20px;	
	padding-left: 70px;
	padding-bottom: 70px;
	padding-right: 25px;
	padding-top: 50px;

}

.home-suggest__list > li .left-side{

	max-width: 105px;

}


.home-suggest__list > li .left-side svg{

	max-width: 105px;

}



.home-suggest__list > li .right-side{

	margin-left: 20px;

}



.home-suggest__title{

	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 130%;
	color: var(--dark-color);

}


.home-suggest__list > li .right-side ul{

	list-style-type: none;
	padding: 0;
	margin-top: 10px;

}



.home-suggest__list > li .right-side ul li{

	display: flex;
	align-items: center;
	padding-left: 5px;
	position: relative;

}



.home-suggest__list > li .right-side ul li::before{

	min-width: 3px;
	max-width: 3px;
	min-height: 3px;
	max-height: 3px;
	display: block;
	content: '';
	border-radius: 50%;
	background: var(--dark-color);
	margin-right: 10px;
	transition: .3s;

}

.home-suggest__list > li .right-side ul li a{

	width: 100%;
	display: block;
	position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
	color: var(--dark-color);
	transition: .3s;

}

.home-suggest__list > li .right-side ul li a span{

	position: relative;

}

.home-suggest__list > li .right-side ul li a span::before{

	width: 100%;
	height: 1px;
	display: block;
	content: '';
	position: absolute;
	bottom: 0;
	left: 0px;
	background: var(--dark-color);

}



.home-suggest__list > li .right-side ul li:hover a{

	color: var(--light-color)

}


.home-suggest__list > li .right-side ul li:hover::before{

	background: var(--light-color);

}
























































































































