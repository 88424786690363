



.wwg__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.wwg__wrapper .block-title{

	text-align: center;

}



.wwg__list{

	width: 1130px;
	margin-left: auto;
	margin-right: auto;
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	margin-top: 65px;

}


.wwg__list li{

	width: 100%;
	height: 410px;
	position: relative;
	background: #F2F2F2;
	box-shadow: -41px 162px 67px rgba(0, 0, 0, 0.01), -23px 91px 56px rgba(0, 0, 0, 0.05), -10px 40px 42px rgba(0, 0, 0, 0.09), -3px 10px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
	border-radius: 20px;	
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	overflow: hidden;
	transition: .3s;
	padding: 30px;
	padding-bottom: 40px;

}




.wwg__icon{

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

}


.wwg__title,
.wwg__description{

	position: relative;
	z-index: 3;

}


.wwg__title{

	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 130%;
	color: var(--dark-color);
	transition: .3s;

}

.wwg__description{

	width: 100%;
	overflow: hidden;
	max-height: 0;
	transition: .3s;

}



.wwg__description *,
.wwg__description p,
.wwg__description{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 140% !important;
	color: #FFFFFF !important;

}






.wwg__list li:hover .wwg__description{

	max-height: 70%;

}

.wwg__list li:hover .wwg__title{

	color: #fff;
	margin-bottom: 10px;

}


.wwg__list li::after{

	display: block;
	content: '';
	width: 100%;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(89, 89, 89, 0.7);
	border-radius: 20px;	
	z-index: 2;
	transition: height .3s;

}





.wwg__list li:hover::after{

	height: 100%;

}




.seowwfx .block-title{

	text-align: center;

}

.seowwfx .wwfx__list{

	grid-column-gap: 85px;
	padding-left: 75px;

}




























