


.hero{

	height: auto;

}


.dev-page .hero{

	height: calc(100vh - 105px);

}


.hero__wrapper{

	padding-top: 50px;
	padding-bottom: 120px;
	position: relative;

}



.hero__title{

	max-width: 700px;
	max-height: 360px;
	padding-top: 100px;

}


.hero__title *,
.hero__title p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 40px !important;
	line-height: 130% !important;
	color: var(--dark-color) !important;

}


.hero__title * span,
.hero__title p span{

	font-weight: 800 !important;

}


.hero__form{

	max-width: 900px;
	display: flex;
	align-items: center;
	padding-top: 80px;
	position: relative;
	z-index: 2;

}


.hero__form input{

	max-width: 303px;
	margin-right: 10px;

}


.hero__form button{

	width: 260px;
	height: 77px;
	background: var(--dark-color);
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
	transition: .3s;

}



.hero__form button:hover{

	background: var(--light-color);

}



.hero__img{

	position: absolute;
	top: 60px;
	right: 0;
	z-index: 1;

}


.dev-page .hero__img{

	right: -14%;

}


.dev-page .advantages__list .advantage:first-child{

	width: 445px;

}


































































































