
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {


	.about-reviews__wrapper {

	    padding-top: 40px;
	    padding-bottom: 60px;
	    position: relative;

	}


	.abt-reviews {

	    margin-top: 40px;
	    border-radius: 26px;

	}


	.abt-reviews .swiper-slide {

	    width: 100%;
	    height: 100%;
	    flex-direction: column;
	    align-items: center;
	    position: relative;
	    padding-top: 40px;
	    padding-bottom: 100px;
	    padding-left: 30px;
	    padding-right: 22px;

	}


	.abt-reviews .swiper-slide .left-side {

	    width: 124px;
	    height: 124px;
	    border-radius: 100%;
	    background: 0 0;

	}

	.abt-reviews .swiper-slide .right-side {

	    width: 100%;
	    margin-left: 0;
	    margin-top: 20px;

	}


	.abt-reviews .review__title{

		font-size: 19px;
		text-align: center;

	}


	.abt-reviews .review__description, .abt-reviews .review__description *, .abt-reviews .review__description p{

		font-size: 16px !important;

	}



	.abt-reviews .review__description{

		max-height: 156px;

	}


	.abt-reviews .seo-btn span{

		font-size: 16px;

	}


	.abt-reviews .swiper-button-prev,
	.abt-reviews .swiper-button-next{

		height: 62px;

	}






































































































































}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






