



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.rq-checker__wrapper{

		flex-direction: column-reverse;
		align-items: flex-start;
		padding-top: 140px;
		padding-bottom: 60px;

	}


	.rq-checker__content{

		width: 100%;
		margin-left: 0;

	}


	.rq-checker__title *, .rq-checker__title p{

		font-size: 24px !important;

	}


	.rq-checker__subtitle,
	.rq-checker__subtitle *,
	.rq-checker__subtitle p{

		font-family: var(--font) !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 160% !important;

	}



	.rq-checker__poster{

		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		filter: drop-shadow(0px 145.806px 58.6781px rgba(0, 0, 0, 0.01)) drop-shadow(0px 81.7938px 48.8984px rgba(0, 0, 0, 0.05)) drop-shadow(0px 36.4516px 36.4516px rgba(0, 0, 0, 0.09)) drop-shadow(0px 8.89062px 20.4484px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
		border-radius: 8px;
		margin-top: 40px;

	}


	.rq-checker__poster img{

		width: 80%;
		height: 318px;
		object-fit: contain;

	}



	.checker-btn{

		width: 270px;
		height: 68px;
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		margin-top: 40px;

	}




















































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






