

.wwfx{

	position: relative;

}


.wwfx__wrapper{

	padding-top: 70px;
	padding-bottom: 210px;
	position: relative;

}


.wwfx__body{

	width: 100%;
	margin-top: 70px;
	position: relative;
	border: 1px solid var(--light-color);
	border-radius: 20px;
	padding-top: 59px;
	padding-bottom: 120px;
	padding-left: 40px;
	padding-right: 40px;

}




.wwfx__top-title{

	background: #F5F5F5;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
	color: var(--dark-color);
	position: absolute;
	top: -17px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	padding-left: 20px;
	padding-right: 20px;

}



.wwfx__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 45px;
	grid-column-gap: 105px;

}

.wwfx__list li{

	height: fit-content;
	height: min-content;
	position: relative;

}

.wwfx__list li,
.wwfx__list li *,
.wwfx__list li p{

	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}

.wwfx__list li * strong,
.wwfx__list li strong,
.wwfx__list li p strong{

	font-weight: 500 !important;

}



.wwfx__list li::before{

	width: 6px;
	height: 6px;
	display: block;
	content: '';
	position: absolute;
	top: 50%;
	left: -20px;
	transform: translateY(-50%);
	background: var(--light-color);
	border-radius: 100%;
	margin-right: 10px;

}





.wwfx__chess-desk{

	top: 0;
	right: 0;

}



.wwfx__chess-small{

	top: 200px;
	left: 0;

}



.wwfx__chess{

	left: -215px;
	bottom: 0;

}













































































































































































