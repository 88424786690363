


.case__wrapper{

    padding-top: 80px;
    padding-bottom: 120px;

}



.case__banner{

    width: 100%;
    height: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50px;

}



.case__banner img{

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 50px;

}



.case__banner::after{

    width: 100%;
    height: 100%;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(0deg, rgba(55, 54, 52, 0.9) 62.31%, rgba(55, 54, 52, 0) 100%);    
    border-radius: 50px;

}




.case__banner-title{

    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    z-index: 3;

}




.case__first-shot{

    max-width: 100%;
    filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.05)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.09)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
    border-radius: 50px;    
    margin-top: 180px;
    margin-bottom: 190px;

}







.case__content-block{

    width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

}







.case__content-block,
.case__content-block p,
.case__content-block *{

    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 160% !important;
    color: var(--dark-color) !important;

}



.case__content-block h3,
.case__content-block p h3,
.case__content-block * h3{

    font-family: var(--font) !important;
    font-weight: 300 !important;
    font-size: 40px !important;
    line-height: 200% !important;
    text-align: center !important;
    color: var(--dark-color) !important;

}



.case__steps{

    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 236px;
    grid-row-gap: 40px;

}



.case__step{

    width: 100%;

}



.case__step-title{

    font-family: var(--font) !important;
    font-weight: 300 !important;
    font-size: 40px !important;
    line-height: 200% !important;
    text-align: left !important;
    color: var(--dark-color) !important;

}

.case__step-description,
.case__step-description p,
.case__step-description *{

    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 160% !important;
    color: var(--dark-color) !important;

}



.case__pages{

    width: 1080px;
    margin-left: auto;
    margin-right: auto;
    line-height: 0;
    column-count: 2;
    column-gap: 58px;
    position: relative;
    margin-top: 230px;
    margin-bottom: 230px;

}


.case__pages img{

    max-width: 510px;
    filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.05)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.09)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
    border-radius: 20px;

}

.case__pages img:nth-child(2n+2){

    margin-top: 80px;

}

.case__pages img:nth-child(2n+1){

    margin-top: 80px;

}


.case__pages img:first-child{

    margin-top: 0;

}




.case-page .blog__wrapper{

    padding-bottom: 150px;

}


.case-page .blog__wrapper .block-title{

    text-align: center;

}



.particle__item{

    position: absolute;

}





















































































