
.simple-form.complex-form-block .simple-form__wrapper{

	height: auto;

}

.simple-form__form.complex-form{

	width: 100%;
	overflow: hidden;
	height: auto;
	margin-top: 0;

}

.simple-form__form.complex-form .swiper-slide.last-slide{

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 50px;

}


.simple-form__form.complex-form .swiper-slide{

	width: 100%;
	height: 100%;
	position: relative;
	transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

}



.simple-form__form.complex-form .swiper-slide .block-title{

	text-align: center;

}

.simple-form__form.complex-form .swiper-slide .block-subtitle{

	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 160%;
	text-align: center;
	color: #222730;
	margin-top: 10px;

}


.complex-input__wrapper{

	width: 100%;
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;

}

.complex-input__wrapper input,
.complex-input__wrapper button{

	width: 340px;
	height: 76px;

}



.swiper-pagination.complex-form-pagintaion{

    bottom: 40px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;

}



.swiper-pagination.complex-form-pagintaion .swiper-pagination-bullet{

	width: 50px;
	height: 2px;
	border-radius: 0;
	background-color: #D9D9D9;
	opacity: 1;

}




.swiper-pagination.complex-form-pagintaion .swiper-pagination-bullet-active{

	background-color: var(--light-color);
	height: 4px;
	opacity: 1;

}


































