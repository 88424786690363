


.contacts__wrapper{

    padding-top: 60px !important;
    padding-bottom: 120px !important;

}


.contacts__wrapper .block-title{

    font-weight: 400;

}



.contacts__splitter{

    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;

}



.contacts__splitter .left-side{

    width: 705px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px; 
    grid-column-gap: 40px; 

}

.contacts__splitter .right-side{

    width: 540px;
    height: 366px;
    border-radius: 30px;    

}


.contacts__splitter .right-side #map{

    width: 100%;
    height: 100%;
    border-radius: 30px;    

}



.contacts__column{

    width: 100%;


}


.column__title{

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: var(--dark-color);

}



.column__content{

    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin-top: 15px;

}


.column__content a{

    width: 100%;

}

.column__content a,
.column__content a span,
.column__content span{

    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: var(--dark-color);
    transition: .3s;

}




.column__content a span{

    margin-left: 20px;

}




.column__content a:hover span{

    color: var(--light-color);

}


.column__content ul.footer__socials{

    margin-top: 0;

}


.column__content ul.footer__socials li{

    transition: .3s;

}

.column__content ul.footer__socials li:hover{

    margin-top: -10px;

}


























































































































