


.hero.contexthero .hero__img{

    position: absolute;
    top: 20px;
    right: -6%;
    z-index: 1;

}


.context-page .hero{

    height: 788px;

}






















































































































































































































































































