
@media (max-width: 1680px) {

}


@media (max-width: 1368px){

}


@media (max-width: 1160px) {

}


@media (max-width: 980px) {

	.seo-page .advantage__content{

		margin-left: 20px;

	}

}


@media (max-width: 480px) {

}


@media (max-width: 330px) {

}






