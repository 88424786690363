






.m-portfolio__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;
	overflow: hidden;

}






.m-portfolio__slider-wrapper{

	width: 100%;
	height: auto;
	position: relative;

}

.m-portfolio__slider{

	width: 100%;
	height: auto;
	position: relative;

}



.m-portfolio__slider .swiper-slide{

	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

}



.m-p__top-title{

	font-size: 32px;
	font-weight: 300;
	line-height: 38px;
	letter-spacing: 0em;
	text-align: left;
	color: var(--dark-color);


}


.m-p__title{

	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	line-height: 130%;
	color: var(--dark-color);
	margin-top: 10px;

}



.m-p__description{

	width: 100%;
	margin-top: 20px;

}



.m-p__description *,
.m-p__description p{

	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}


.m-portfolio__slider-wrapper .def-btn{

	width: 303px;
	height: 77px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 50px;

}






.swiper-pagination.m-portfolio-pagintaion{

	display: flex;
	align-items: flex-end;
    bottom: 50px !important;
    left: 0 !important;
    max-width: 50% !important;
    transform:translateX(0) !important;

}



.swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet{

	width: 100px;
	height: 2px;
	border-radius: 0;
	background-color: #D9D9D9;
	opacity: 1;

}




.swiper-pagination.m-portfolio-pagintaion .swiper-pagination-bullet-active{

	background-color: var(--light-color);
	height: 4px;
	opacity: 1;

}



.go-to-portfolio{

	width: 310px;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: 0;

}


.go-to-portfolio span{

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 160%;
	text-decoration-line: underline;
	text-decoration-color: #616161;
	text-decoration-thickness: 1px;
	color: #616161;
	transition: .3s;

}



.go-to-portfolio svg{

	margin-left: 33px;

}



.go-to-portfolio:hover span{

	color: var(--light-color);
	text-decoration-color: var(--light-color);

}

.go-to-portfolio svg *{

	transition: .3s;

}


.go-to-portfolio:hover svg *{

	fill: var(--light-color);

}



.portfolio__actions{

	width: 100%;
	margin-top: 130px;
	display: flex;
	align-items: center;
	justify-content: space-between;

}



.portfolio__actions .swiper-pagination.m-portfolio-pagintaion{

	bottom: 0 !important;
	position: relative !important;

}




.context-page .m-portfolio__slider, .main-page .m-portfolio__slider{

	padding-bottom: 0 !important;

}



.m-portfolio__wrapper .left-side{

	min-width: 620px !important;

}

.m-portfolio__wrapper .right-side{

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}




.dev-page .m-portfolio__wrapper .right-side {

    padding-top: 0;

}


.dev-page .m-portfolio__wrapper .left-side {

    padding-top: 20px;
    
}




















































