



.context-page .block-title{

	text-align: center;

}


.context-page .site-types__list {

    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    margin-top: 80px;

}


.context-page .site-type__img img{

    filter: grayscale(100);
    transition: .3s;
	border-radius: 50px 50px 0px 0px;    

}


.context-page .site-type__img {

    width: 100%;
    height: 343px;
    border-radius: 50px;
    filter: grayscale(0);
    transition: .3s;
	border-bottom: 2px solid var(--light-color);
	border-radius: 50px 50px 0px 0px;    

}




.context-page .site-type__item:hover img{

    filter: grayscale(0);

}

.context-page .site-type__item{

	height: 703px;

}








.context-page .site-types.wwd .site-types__list {

    grid-template-columns: repeat(2,1fr);
    grid-gap: 20px;
    margin-top: 50px;

}


.context-page .site-types.wwd .site-type__img img{

    filter: grayscale(100);
    transition: .3s;
	border-radius: 50px 50px 0px 0px;    

}


.context-page .site-types.wwd .site-type__img {

    width: 100%;
    height: 278px;
    filter: grayscale(0);
    transition: .3s;
	border-radius: 50px 50px 0px 0px;    

}




.context-page .site-types.wwd .site-type__item:hover img{

    filter: grayscale(0);

}

.context-page .site-types.wwd .site-type__item{

	height: 1015px;

}



.context-page .site-type__title{

	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 160%;
	color: var(--dark-color);

}






.context-page .site-types.wwd .site-type__subtitle{

	margin-top: 20px;

}




.read-more{

	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	text-transform: uppercase;
	color: var(--light-color);
	margin-top: 50px;

}





















































































































