


.hero.contexthero .hero__img{

    position: absolute;
    top: 20px;
    right: -6%;
    z-index: 1;

}




.seo-page .hero.contexthero .hero__img{

    position: absolute;
    top: 105px;
    right: -6%;
    z-index: 1;

}




.wwfx.seowwfx .wwfx__top-title{

    width: max-content;

}




.seo-page .hero{

    min-height: 780px;

}





.seo-page .hero__wrapper{

    padding-bottom: 0;

}















































































































































































































































































