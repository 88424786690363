.crumbs{

	width: 100%;
	display: flex;
	align-items: center;
	z-index: 99;
	padding-top: 40px;
	position: relative;

}


.crumbs ul{

	display: flex;
	align-items: center;
	list-style:none;
	z-index: 99;
	flex-wrap: wrap;
	padding: 0;

}

.crumbs ul li{

	width: auto;
	height: auto;
	display: flex;
	align-items: center;

}

.crumbs ul li::after{

	display: block;
	content: "";
	width: 21px;
	height: 8px;
	margin: 0 20px;
	background: url('../img/icons/crumb-arrow.svg')no-repeat;

}



.crumbs ul li:last-child::after{

	display: none;

}


.crumbs ul li.active::after{

	display: none;

}




.crumbs ul li, .crumbs ul li a{

	font-family: var(--font);
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 160%;
	color: var(--dark-color);

}





.crumbs ul li.active, .crumbs ul li.active a{


	font-style: normal;
	font-weight: 500;
	
}


