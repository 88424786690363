
.about-page .hero{

    height: auto;
    padding-bottom: 170px;

}



.hero.contexthero .hero__img{

    position: absolute;
    top: 20px;
    right: -6%;
    z-index: 1;

}



.hero__subtitle{

    width: 100%;
    display: block;
    margin-top: 20px;

}



.hero__subtitle,
.hero__subtitle *,
.hero__subtitle p{

    font-family: var(--font) !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 130% !important;
    color: var(--dark-color) !important;

}


.hero__subtitle strong,
.hero__subtitle * strong,
.hero__subtitle p strong{

    font-weight: 500 !important;

}


















































































































































































































































































