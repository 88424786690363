



.about-reviews__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.about-reviews__wrapper .block-title{

	text-align: center;

}




.abt-reviews{

	width: 100%;
	position: relative;
	min-height: 372px;
	transition: .3s;
	margin-top: 80px;
	overflow: hidden;
	border: 2px solid #CE9967;
	box-shadow: 0px 164px 66px rgba(222, 222, 222, 0.01), 0px 92px 55px rgba(222, 222, 222, 0.05), 0px 41px 41px rgba(222, 222, 222, 0.09), 0px 10px 23px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1);
	border-radius: 30px;	

}



.abt-reviews .swiper-slide{

	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	position: relative;
	padding-top: 80px;
	padding-bottom: 105px;
	padding-left: 60px;
	padding-right: 115px;

}


.abt-reviews .swiper-slide .left-side{

	width: 187px;
	height: 187px;
	border-radius: 100%;
	background: none;

}


.abt-reviews .swiper-slide .left-side img{

	width: 100%;
	height: 100%;
	object-fit: cover;
	background: none;
	border-radius: 100%;

}



.abt-reviews .swiper-slide .right-side{

	width: calc(calc(100% - 187px) - 58px);
	margin-left: 58px;

}



.abt-reviews .review__title{

	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 160%;
	color: var(--dark-color);

}


.abt-reviews .review__description{

	width: 100%;
	max-height: 87px;
	overflow: hidden;
	transition: .3s;
	position: relative;
	margin-top: 10px;

}


.abt-reviews .review__description,
.abt-reviews .review__description *,
.abt-reviews .review__description p{

	font-family: var(--font) !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 160% !important;
	color: var(--dark-color) !important;

}


.abt-reviews .review__description strong,
.abt-reviews .review__description * strong,
.abt-reviews .review__description p strong{

	font-weight: 500 !important;

}


.abt-reviews .seo-btn{

	margin-left: 0;
	margin-top: 30px;

}


.abt-reviews .seo-btn span{

	position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
	color: var(--light-color);

}


.abt-reviews .seo-btn span::after{

	width: 100%;
	height: 1px;
	display: block;
	content: '';
	background: var(--light-color);
	position: absolute;
	bottom: -2px;
	left: 0;

}







.abt-reviews .swiper-button-next, .abt-reviews .swiper-button-prev {

    position: relative;
    top: 0;
    margin-top: 0;
    left: 0;
    right: 0;

}


.abt-reviews .swiper-button-prev{

	box-sizing: border-box;
	width: 100px;
	height: 70px;
	background: #F7F7F7;
	border: 2px solid #CE9967;
	border-radius: 0px 0px 30px 0px;
	transform: rotate(-180deg);
	border-top: 0;
	transition: .3s;

}



.abt-reviews .swiper-button-next{

	width: 100px;
	height: 70px;
	border-radius: 0px 0px 30px 0px;
	border-top: 0;
	border: 2px solid #CE9967;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
	transition: .3s;

}






.abt-reviews .swiper-button-prev svg *,
.abt-reviews .swiper-button-next svg *{

	fill: var(--light-color);

}



.abt-reviews .swiper-button-prev svg *,
.abt-reviews .swiper-button-next svg *{

	transition: .3s;

}



.abt-reviews .swiper-button-prev:hover svg *,
.abt-reviews .swiper-button-next:hover svg *{

	fill: #fff;

}

.abt-reviews .swiper-button-prev:hover,
.abt-reviews .swiper-button-next:hover{

	background: var(--light-color);

}



.abt-reviews .swiper-button-prev svg{

	transform: scale(-1);

}


















































































