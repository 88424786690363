



@media (max-width: 1680px) {

    
	.big-container {

	    width: 1280px;

	} 

	.context-page .m-portfolio__slider .swiper-slide .left-side{

		padding-left: 30px;

	}



    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.site-types__wrapper{

		width: 100%;

	}



	.site-types__top-title{

		font-size: 24px;
		line-height: 130%;

	}



	.site-types__top-title span::after{

		width: 100%;

	}


	.site-types__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 20px;
	    margin-top: 40px;

	}





	.site-type__header svg{

		display: block;

	}




	.site-type__item{

		height: auto;
		transition: .3s;
		border: none;

	}


	.site-types__wrapper{

		width: calc(100% - 40px);
		margin-left: auto;
		margin-right: auto;

	}



	.site-type__img{

		display: none;

	}


	.site-type__content{

		height: auto;
		transition: .3s;
		padding: 0;

	}




	.site-type__header{

		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

	}



	.site-type__title{

		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		text-transform: uppercase;

	}


	.site-type__item{

		padding-bottom: 20px;
		border-bottom: none;
		border-radius: 0;
		position: relative;

	}

	.site-type__item::after{

		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		background: #C7C7C7;
		bottom: 0;
		left: 0;

	}

	.site-type__content-wrapper{

		overflow: hidden;
		max-height: 0;

	}


	.site-type-content-self{

		padding-top: 20px;

	}

	.site-type__header svg{

		transition: .3s;

	}



	.site-type__header.active svg{

		transform: rotate(90deg);

	}


	.site-type__subtitle{

		font-weight: 400;
		font-size: 16px;
		line-height: 160%;

	}



	.site-type__list li{

		font-size: 16px;
		line-height: 160%;

	}



	.site-type__item .def-btn{

		width: 100%;
		height: 61px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		margin-top: 40px;

	}









































































































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






