



.plans__wrapper{

	padding-top: 80px;
	padding-bottom: 120px;
	position: relative;

}


.plans__wrapper .block-title strong{

	color: var(--light-color);

}



.plans__list{

	width: 100%;
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	margin-top: 80px;

}



.plan{

	width: 100%;
	height: 1095px;
	border: 2px solid #CE9967;
	border-radius: 40px;	
	filter: drop-shadow(0px 164px 66px rgba(0, 0, 0, 0.01)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.01)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.02)) drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.02)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.01));	

}


.plan__header{

	width: 100%;
	height: 98px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #616161;
	border: 2px solid #616161;
	border-radius: 37px 37px 0px 0px;	
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 140%;
	text-transform: uppercase;
	color: #FFFFFF;

}


.plan__body{

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: calc(100% - 98px);
	padding-top: 30px;
	padding-left: 35px;
	padding-right: 24px;
	padding-bottom: 50px;

}



.plan__content{

	width: 100%;
	max-height: 90%;
	overflow: hidden;

}


.plan__content *,
.plan__content p{

	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 140% !important;
	color: var(--dark-color) !important;

}



.plan__content * strong,
.plan__content strong,
.plan__content p strong{

	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 140% !important;
	color: #222730 !important;

}



.plan__body .def-btn{

	margin-top: auto;

}




.plan__content ul{

	width: 100%;
	list-style-type: none;
	padding: 0;

}

.plan__content ul li{

	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 20px;

}



.plan__content ul li:last-child{

	margin-bottom: 0;

}



.plan__content ul li::before{

	min-width: 11px;
	max-width: 11px;
	min-height: 9px;
	max-height: 9px;
	display: block;
	content: '';
	background: url('../img/icons/mark2.svg')no-repeat;
	margin-right: 20px;

}


.site-type__item{

	transition: .3s;

}
.site-type__item:hover{

	margin-top: -20px;

}



.tech-page .plan{

	min-height: 870px;
	max-height: 870px;

}



.tech-page .plan__content ul li::before{

	min-width: 11px;
	max-width: 11px;
	min-height: 9px;
	max-height: 9px;
	display: block;
	content: '';
	background: url('../img/icons/mark4.svg')no-repeat;
	margin-right: 20px;

}






.plan__header svg{

	transition: .3s;

}

.plan:hover .plan__header svg,
.plan__header.active svg{

	transform: rotate(90deg);

}














