


.context-page .advantages .advantages__list{

	grid-gap: 60px;

}

.context-page .advantages .advantages__list {

    grid-gap: 0;
    width: 1170px;
    margin-left: auto;
    margin-right: auto;

}

.context-page .advantages .advantages__list .advantage:nth-child(3n+2){

	margin-left: 60px;

}



.context-page .advantages .advantages__list .advantage{

	display: flex;
	flex-direction: column;
	align-items: center;

}



.context-page .advantages .advantage__content{

	align-self: center;
	text-align: center;
	margin-left: 0;

}





























