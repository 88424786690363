





.footer{

	background: #373634;
	position: relative;

}


.footer__wrapper{

	display: flex;
	align-items: flex-start;
	padding-top: 45px;
	padding-bottom: 180px;
	position: relative;

}


.footer-column{

	padding-top: 35px;

}

.footer-column:first-child{

	padding-top: 0;

}




.footer-phones, .footer-emails{

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 60px;

}


.footer-emails{

	margin-top: 36px;

}




.footer-phones a,
.footer-emails a{

	display: flex;
	align-items: center;

}




.footer-phones a .footer-contacts__icon,
.footer-emails a .footer-contacts__icon{

	width: 33px;
	display: flex;
	align-items: center;

}


.footer-phones a .link-content,
.footer-emails a .link-content{

	margin-left: 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #FFFFFF;
	transition: .3s;

}




.footer-phones a svg,
.footer-emails a svg{

	max-width: 100%;
	transition: .3s;

}

.footer-phones a svg *,
.footer-emails a svg *{

	transition: .3s;

}




.footer-phones a:hover .footer-contacts__icon svg *,
.footer-emails a:hover .footer-contacts__icon svg *{

	fill: var(--light-color);

}


.footer-phones a:hover .link-content,
.footer-emails a:hover .link-content{

	color: var(--light-color);

}



.copyrights{

	display: block;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #FFFFFF;
	margin-top: 36px;
	transition: .3s;
	cursor: pointer;

}



.copyrights:hover{

	color: var(--light-color);

}


.privacy-policy{

	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	text-decoration-line: underline;
	text-decoration-color: var(--light-color);
	text-decoration-thickness: 1px;
	color: var(--light-color);

}




.footer-column.second-column{

	margin-left: 72px;

}



.footer-column__title{

	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 130%;
	color: #FFFFFF;

}


.footer__list{

	position: relative;
	list-style-type: none;
	padding: 0;
	margin-top: 20px;

}

.footer__list li{

	margin-bottom: 10px;

}


.footer__list li:last-child{

	margin-bottom: 0;

}


.footer__list li,
.footer__list li a{

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 180%;
	color: #FFFFFF;
	transition: .3s;

}


.footer__list li:hover a{

	color: var(--light-color);

}

.footer__list li.active{

	font-weight: 900;
	color: var(--light-color);

}



.footer-column.third-column{

	margin-left: 215px;

}



.footer-column.last-column{

	margin-left: auto;

}


.footer__socials{

	display: flex;
	align-items: center;
	list-style-type: none;
	padding: 0;
	margin-top: 20px;

}


.footer__socials li{

	margin-right: 30px;

}


.footer__socials li a svg *{

	transition: .3s;

}


.footer__socials li:hover a svg path,
.footer__socials li:hover a svg *{

	fill: var(--light-color) !important;

}















































































