

.logo-page .hero{

	min-height: 788px;

}


.hero__subtitle{

	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 130%;
	color: var(--dark-color);

}



.herologo .hero__title{

	margin-top: 10px;
	padding-top: 0;
	overflow: visible;

}

.herologo .hero__subtitle{

	padding-top: 90px;

}



.hero__title strong{

	font-style: normal;
	position: relative;
	font-weight: 800;
	font-size: 40px;
	line-height: 130%;

}


.hero__title strong span{

	position: relative;

}

.hero__title strong span::after{

	width: 100%;
	height: 2px;
	display: block;
	content: '';
	position: absolute;
	left: 0;
	bottom: 0px;
	background: var(--light-color);

}





.herologo .hero__img {

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    
}





























































































































































































































































































