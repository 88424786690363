



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.plans__wrapper{

		padding-top: 0;
		padding-bottom: 40px;

	}


	.context-page .plans__wrapper .block-title{

		position: relative;
		padding-bottom: 10px;

	}


	.context-page .plans__wrapper .block-title::after{

		width: 272px;
		height: 2px;
		display: block;
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background: var(--light-color);

	}



	.plans__list {

	    width: 100%;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 20px;
	    margin-top: 40px;

	}


	.plan{

		height: auto;
		border: none;
		padding: 0;

	}


	.plan__body{

		height: auto;
		padding: 0;

	}


	.plan__header {

	    width: 100%;
	    height: auto;
		display: flex;
	    align-items: center;
	    justify-content: space-between;	    
	    background: none;
	    border: none;
	    border-radius: 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: var(--dark-color);
		text-align: left;


	}


	.plan__body{

		width: 100%;
		max-height: 0;
		overflow: hidden;
		transition: .3s;

	}

	.plan__content{

		height: auto;
		overflow: visible;
		margin-top: 20px;

	}


	.plan{

		padding-bottom: 20px;
		border-bottom: 1px solid #C7C7C7;
		border-radius: 0;

	}



	.plan__content *, .plan__content p {

		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 140% !important;

	}



	.plan__content * strong, .plan__content p strong, .plan__content strong {

	    font-size: 16px!important;
	    line-height: 140%!important;

	}


	.plan__body .def-btn{

		width: 100%;
		height: 61px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		margin-top: 40px;
		margin-bottom: 10px;

	}





.tech-page .plan{

	min-height: auto;
	max-height: auto;

}



	.plan{

		border-bottom: none;
		border-radius: 0;
		position: relative;

	}

	.plan::after{

		display: block;
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		background: #C7C7C7;
		bottom: 3px;
		left: 0;
		transform: translate3d(0, 0, 0);

	}






















































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






