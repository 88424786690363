



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.connect::after{

		display: none;

	}

	.connect__wrapper{

		padding-top: 30px;

	}



	.connect__list {

	    width: 100%;
	    position: relative;
	    list-style-type: none;
	    padding: 0;
	    display: grid;
	    grid-template-columns: repeat(1,1fr);
	    grid-row-gap: 20px;
	    grid-column-gap: 0;
	    margin-top: 20px;

	}

	.connect__title svg{

		display: block;
		transition: .3s;

	}


	.connect__title{

		width: 100%;
		display: flex;
		align-items: center;

	}


	.connect__list > li{

		padding-bottom: 20px;
		border-bottom: 1px solid #C7C7C7;

	}



	.connect__title .number{

		font-size: 30px;
		line-height: 140%;

	}


	.connect__title .text{

		font-size: 18px;
		line-height: 25px;
		margin-left: 10px;

	}



	.connect__title svg{

		margin-left: auto;

	}


	.connect__content-wrapper{

		width: 100%;
		overflow: hidden;
		max-height: 0;
		transition: .3s;

	}


	.connect__content-itself{

		width: 100%;
		padding-top: 15px;

	}



	.connect__content li{

		font-weight: 400;
		font-size: 16px;
		line-height: 140%;

	}





	.connect__title.active svg{

		transform: rotate(90deg);

	}

	.connect__wrapper{

		padding-bottom: 0;

	}



	.connect__wrapper::after{

		display: none;

	}

































































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






