



@media (max-width: 1680px) {

    
    







    

}


@media (max-width: 1368px){








}


@media (max-width: 1160px) {

	





}





@media (max-width: 980px) {


	.hero{

		height: auto !important;
		min-height: auto !important;

	}


	.hero__wrapper{

		padding-top: 40px;
		padding-bottom: 160px;

	}


	.main-page .hero__title{

		max-width: 100%;
		padding-top: 0;

	}

	.hero__title *, .hero__title p{

		font-size: 24px !important;

	}



	.hero__form{

		padding-top: 60px;

	}



	.hero__form input{

		display: none;

	}

	.hero-form-btn{

		width: 218px;
		height: 68px;
		padding-left: 0;
		padding-right: 0;
		font-size: 16px;
		line-height: 140%;

	}



	.main-page .hero__img{

		width: 170px;
		height: 290px;
		top: 35%;
	    right: -9%;
		object-fit: contain;
		bottom: 0;

	}











































































































}

@media (max-width: 480px) {
























}





@media (max-width: 330px) {








}






